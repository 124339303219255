<template>
  <section
    class="ranking-table-header w-full text-texts-standard-additional flex items-center justify-between text-30"
    :class="[rankingType, { 'no-rewards': hideRewards }]"
  >
    <p class="pos">
      {{ $t('rankingsGroup.pos') }}
    </p>
    <p
      class="name"
      :class="{
        'is-club': isClub,
        'is-club-leaderboard': isClubLeaderboardTable,
      }"
    >
      {{ isClubLeaderboardTable ? $t('club.club') : $t('common.name') }}
    </p>
    <p v-if="!isClubActivityTable && !isClubLeaderboardTable" class="level">
      {{ $t('common.level') }}
    </p>
    <p v-if="!isClubActivityTable && !isClubLeaderboardTable" class="club">
      {{ $t('rankingsGroup.club') }}
    </p>
    <p v-if="isClubLeaderboardTable" class="points">
      {{ $t('club.members') }}
    </p>
    <p v-if="!isRecordsTable || isClubLeaderboardTable" class="points">
      {{ $t('rankingsGroup.points') }}
    </p>
    <p v-if="isDisciplinesTable && !hideRewards" class="rewards">
      {{ $t('common.reward') }}
    </p>
    <p v-if="isRecordsTable" class="season">
      {{ $t('rankingsGroup.season') }}
    </p>
    <p v-if="isRecordsTable" class="result">
      {{ $t('common.power') }}
    </p>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { RankingType } from '@/interfaces/RankingsInterfaces'

export default defineComponent({
  name: 'RankingTableHead',
  props: {
    rankingType: {
      type: String,
      default: RankingType.Global,
      validator: (value: RankingType): boolean => {
        return [
          RankingType.Global,
          RankingType.Disciplines,
          RankingType.Records,
          RankingType.ClubActivity,
          RankingType.ClubLeaderboard,
        ].includes(value)
      },
    },
    isClub: {
      type: Boolean,
      default: false,
    },
    hideRewards: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isDisciplinesTable(): boolean {
      return this.rankingType === RankingType.Disciplines
    },
    isRecordsTable(): boolean {
      return this.rankingType === RankingType.Records
    },
    isClubActivityTable(): boolean {
      return this.rankingType === RankingType.ClubActivity
    },
    isClubLeaderboardTable(): boolean {
      return this.rankingType === RankingType.ClubLeaderboard
    },
  },
})
</script>

<style lang="scss" scoped>
.ranking-table-header {
  height: 2.9375rem;
  @if $isWsm {
    background: #09172a;
  }
  @if $isSsm {
    background: #292d30;
  }

  .pos {
    width: 6.4375rem;
  }

  .name {
    width: 29rem;
  }

  .level {
    width: 8.125rem;
  }

  .club {
    width: 20.75rem;
  }

  .points,
  .season,
  .result {
    width: 16.9375rem;
  }

  .rewards {
    width: 30rem;
    margin-left: 1rem;
  }

  .is-club {
    width: 42.5rem;
    text-align: left;
  }

  .is-club-leaderboard {
    width: 35.5rem;
    text-align: left;
  }

  &.disciplines,
  &.records {
    .name {
      width: 34rem;
    }

    .level {
      width: 4.875rem;
    }

    .club {
      width: 34rem;
    }

    .points {
      width: 5.1875rem;
    }
  }
}
</style>
