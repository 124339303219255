<template>
  <footer
    v-if="playerData && Object.keys(playerData).length > 0"
    class="player-position flex items-center text-32"
    :class="{
      'justify-between': !isRecordsTable,
      'text-texts-standard-default': $isSsm,
      'text-texts-standard-dark': $isWsm,
    }"
  >
    <p
      class="player-position-position font-bold flexing h-full text-36"
      :class="{ 'text-texts-standard-default': $isWsm, 'text-texts-standard-dark': $isSsm }"
    >
      {{ playerData.position }}
    </p>
    <div
      class="player-position-player flex items-center h-full"
      :class="[
        { records: isRecordsTable, disciplines: isDisciplinesTable, 'no-rewards': hideRewards },
      ]"
      :style="isClubActivityTable ? { 'flex-grow': 2 } : {}"
    >
      <p
        class="country font-bold text-24 uppercase"
        :class="{
          'text-texts-standard-default': $isSsm,
          'text-texts-standard-dark': $isWsm,
        }"
      >
        {{ playerData.country }}
      </p>
      <div
        class="flag"
        :style="{
          backgroundImage: 'url(' + pathImages + 'flags/102x68/' + playerData.country + '.avif)',
        }"
      />
      <p class="name table-standard-row-username text-32">
        {{ playerData.username }}
      </p>
      <span v-if="playerData.event_badges?.length" class="badge-wrapper flexing">
        <event-badge :current-badges="playerData.event_badges ?? null" class="ml-2" />
      </span>
    </div>
    <p
      v-if="!isClubActivityTable"
      class="player-position-level h-full flexing name"
      :class="[rankingType, { 'no-rewards': hideRewards }]"
    >
      {{ playerData.LEVEL ?? playerData.level }}
    </p>
    <p
      v-if="!isClubActivityTable"
      class="player-position-club h-full flexing text-texts-standard-dark"
      :class="[rankingType, { 'no-rewards': hideRewards }]"
    >
      <app-club-name
        :club-id="playerData.club_id"
        :name="playerData.club"
        :club-logo-id="playerData.club_logo_id"
        :clubs-background-id="playerData.club_background_logo_id"
        :dark-text="true"
      />
    </p>
    <div
      v-if="!isRecordsTable && !isClubActivityTable"
      class="player-position-points h-full flexing"
      :class="rankingType"
    >
      <p>{{ playerData.RANKING_POINTS }}</p>
      <app-main-icon icon-name="ranking_points" :icon-size="48" />
    </div>
    <div
      v-if="isDisciplinesTable && playerData.rewards"
      class="player-position-rewards font-bold flexing"
    >
      <div
        v-for="(rewardObject, index) in playerData.rewards"
        :key="index"
        class="player-position-rewards-item flexing"
      >
        <p>{{ rewardObject.value ?? '' }}</p>
        <app-main-icon
          :icon-size="48"
          :icon-name="
            rewardObject.rarity
              ? rewardObject.type + capitalize(rewardObject.rarity)
              : rewardObject.type
          "
        />
      </div>
    </div>
    <p
      v-if="isRecordsTable"
      class="player-position-season h-full flexing text-texts-standard-important font-bold"
      :class="rankingType"
    >
      {{ playerData.season }}
    </p>
    <p v-if="isRecordsTable" class="player-position-result h-full flexing" :class="rankingType">
      {{ formatResult(playerData.result, playerData.discipline_id) }}
    </p>
    <div
      v-if="isClubActivityTable"
      class="player-position-points h-full flexing"
      :class="rankingType"
    >
      <p>{{ playerData.points }}</p>
      <app-main-icon icon-name="ranking_points" :icon-size="48" />
    </div>
  </footer>
</template>

<script lang="ts">
import AppClubName from '@/components/GlobalComponents/AppClubName.vue'
import EventBadge from '@/components/GlobalComponents/EventBadge.vue'
import { pathImages } from '@/globalVariables'
import { capitalize, formatResult } from '@/helpers'
import { defineComponent } from 'vue'

import { RankingType } from '@/interfaces/RankingsInterfaces'
import type { PropType } from 'vue'
import type { RankingUser } from '@/interfaces/RankingsInterfaces'
import type { Nullable } from '@/interfaces/utils'
import type Reward from '@/interfaces/Reward'

interface ComponentData {
  pathImages: typeof pathImages
}

export default defineComponent({
  name: 'RankingPlayer',
  components: {
    AppClubName,
    EventBadge,
  },
  props: {
    playerData: {
      type: Object as PropType<Nullable<RankingUser>>,
      default: () => null,
    },
    rankingType: {
      type: String,
      default: RankingType.Global,
      validator: (value: RankingType): boolean => {
        return [
          RankingType.Global,
          RankingType.Disciplines,
          RankingType.Records,
          RankingType.ClubActivity,
        ].includes(value)
      },
    },
    hideRewards: {
      type: Boolean,
      default: false,
    },
  },
  data(): ComponentData {
    return {
      pathImages,
    }
  },
  computed: {
    isDisciplinesTable(): boolean {
      return this.rankingType === RankingType.Disciplines
    },
    isRecordsTable(): boolean {
      return this.rankingType === RankingType.Records
    },
    isClubActivityTable(): boolean {
      return this.rankingType === RankingType.ClubActivity
    },
    setIconNames(reward: Reward): string {
      return reward.rarity ? `${reward.type}-${reward.rarity}` : reward.type
    },
  },
  methods: {
    capitalize,
    formatResult,
  },
})
</script>

<style lang="scss" scoped>
.player-position {
  width: 100%;
  height: 4rem;
  @if $isWsm {
    background: #d9ecff;
    border: 0.063rem solid #6c9ec4;
  }
  @if $isSsm {
    background: #cd4a8b;
    border: 0.125rem solid #c85493;
  }

  &-position {
    width: 6.4375rem;
    position: relative;
    clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 0% 100%);
    @if $isWsm {
      background: #102636;
    }
    @if $isSsm {
      background: #fff;
    }
  }

  &-player {
    padding-left: 1.0625rem;
    width: 29rem;

    &.disciplines {
      width: 36rem;

      &.no-rewards {
        padding-left: 3.0625rem;
      }
    }

    &.records {
      width: 35rem;
    }

    .flag {
      width: 3.0625rem;
      min-width: 3.0625rem;
      height: 2rem;
      background: url($path-images + 'flags/70x49/FLAG_SVK.avif') center no-repeat;
      background-size: contain;
      margin-right: 1.0625rem;
      margin-left: 0.5625rem;
    }
  }

  &-level {
    &.disciplines {
      width: 4.875rem;

      &.no-rewards {
        padding-left: 1rem;
      }
    }

    &.records {
      width: 2.875rem;
      margin-left: 0.7rem;
    }

    width: 8.125rem;
  }

  &-club {
    &.disciplines {
      width: 34rem;

      &.no-rewards {
        padding-left: 1rem;
      }
    }

    &.records {
      width: 37rem;
      margin-left: 1.5rem;
    }

    width: 20.75rem;
  }

  &-points,
  &-season,
  &-result {
    &.disciplines {
      width: 8rem;
    }

    width: 16.9375rem;
  }

  &-season {
    &.records {
      width: 15rem;
    }
  }

  &-result {
    &.records {
      width: 16rem;
    }
  }

  &-rewards {
    width: 30rem;
    margin-left: 1rem;

    &-item {
      margin-right: 1.25rem;
    }
  }
}
</style>
