import { createRouter, createWebHistory } from 'vue-router'
import type { RouteRecordRaw, RouteLocationNormalized, RouteLocationRaw } from 'vue-router'

import {
  MECHANIC_PERSONAL_TRAINER,
  MECHANIC_DAILY_QUESTS,
  MECHANIC_BENEFIT,
  MECHANIC_EQUIPMENT,
  MECHANIC_CAREER,
  MECHANIC_STAR_OF_THE_DAY,
  MECHANIC_CLUB,
  MECHANIC_EVENT,
  MECHANIC_BOARD_GAMES_EVENT,
  EQUIPMENT_ROUTE_NAME,
  EQUIPMENT_WEB_ROUTE_NAME,
  EQUIPMENT_DETAIL_ROUTE_NAME,
  EQUIPMENT_DETAIL_WEB_ROUTE_NAME,
  LOGIN_VIEW_ROUTE_NAME,
  ADMIN_TOKEN_EXTRACTOR_ROUTE_NAME,
  MAINTENANCE_ROUTE_NAME,
} from '@/globalVariables'

import { eventRouterWeb, eventRouterMobile } from './eventRoutes'
import { premiumRoutesWeb, premiumRoutesMobile } from './premiumRoutes'
import { authRouteGuard } from './authRouteGuard'
import { mechanicRouteGuard } from './mechanicRouteGuard'
import { eventRouteGuardPromise } from './eventRouteGuardPromise'

import type { RouteRecordCustom } from './RouteInterface'

import { SCENES_KEYS } from '@/map-phaser-new/constants'
import { getRouteWebName, isMobile } from '@/plugins'

// FLUTTER OBRAZOVKY
import FlutterTestView from '@/views/FlutterTestView.vue'

// ADMIN
import AdminTokenExtractor from '@/views/AdminTokenExtractor.vue'

// LAYOUT OBRAZOVKY
import SetLogin from '@/views/SetLogin.vue'
import LayoutView from '@/views/LayoutView.vue'
import TutorialView from '@/views/TutorialView.vue'
import HeaderComponent from '@/components/Header/HeaderComponent.vue'
import FooterComponent from '@/components/Layout/FooterComponent.vue'

// LOGIN OBRAZOVKY
import LoginView from '@/views/login/LoginView.vue'
import ResetPassword from '@/views/login/ResetPassword.vue'

// MAINTENANCE
import MaintenanceView from '@/views/GameInMaintenance.vue'

// TRENING OBRAZOVKY
import TrainingTrainer from '@/views/training/TrainingTrainer.vue'
import TrainingTrainerDetail from '@/views/training/TrainingTrainerDetail.vue'
import TrainingBuildings from '@/views/training/TrainingBuildings.vue'
import TrainingDisciplines from '@/views/training/TrainingDisciplines.vue'
import TrainingDisciplineDetail from '@/views/training/TrainingDisciplineDetail.vue'
import TrainingTutorial from '@/views/training/TrainingTutorial.vue'

// ARENA OBRAZOVKY
import ArenaView from '@/views/arena/ArenaView.vue'
import ArenaHistory from '@/views/arena/ArenaHistory.vue'
import ArenaReport from '@/views/arena/ArenaReport.vue'
import ArenaHistoryReport from '@/views/arena/ArenaHistoryReport.vue'
import ArenaBuilding from '@/views/arena/ArenaBuilding.vue'

// RANKINGS
import RankingDisciplines from '@/views/rankings/RankingDisciplines.vue'
import RankingDisciplinesCountry from '@/views/rankings/RankingDisciplinesCountry.vue'
import RankingGlobal from '@/views/rankings/RankingGlobal.vue'
import RankingRecords from '@/views/rankings/RankingRecords.vue'
import RankingGlobalCountry from '@/views/rankings/RankingGlobalCountry.vue'
import RankingGlobalClubs from '@/views/rankings/RankingGlobalClubs.vue'
import RankingRecordsCountry from '@/views/rankings/RankingRecordsCountry.vue'

// KLUBY OBRAZOVKY
import ClubsMembers from '@/views/clubs/ClubsMembers.vue'
import ClubsActivity from '@/views/clubs/ClubsActivity.vue'
import ClubsChat from '@/views/clubs/ClubsChat.vue'
import ClubsPostMessage from '@/views/clubs/ClubsPostMessage.vue'
import ClubsApplication from '@/views/clubs/ClubsApplication.vue'
import ClubsNotice from '@/views/clubs/ClubsNotice.vue'
import ClubsCreateClub from '@/views/clubs/ClubsCreateClub.vue'
import ClubsEditClub from '@/views/clubs/ClubsEditClub.vue'
import ClubsAvailable from '@/views/clubs/ClubsAvailable.vue'
import ClubsAvailablePlayers from '@/views/clubs/ClubsAvailablePlayers.vue'
import ClubsInvitations from '@/views/clubs/ClubsInvitations.vue'
import ClubsParking from '@/views/clubs/ClubsParking.vue'
import ClubsFitness from '@/views/clubs/ClubsFitness.vue'
import ClubsRelax from '@/views/clubs/ClubsRelax.vue'
import ClubsWellness from '@/views/clubs/ClubsWellness.vue'
import ClubsSwimming from '@/views/clubs/ClubsSwimming.vue'
import ClubsRestaurant from '@/views/clubs/ClubsRestaurant.vue'
import ClubsMedical from '@/views/clubs/ClubsMedical.vue'
import ClubsHotel from '@/views/clubs/ClubsHotel.vue'
import ClubsMedia from '@/views/clubs/ClubsMedia.vue'
import ClubsDirectorate from '@/views/clubs/ClubsDirectorate.vue'
import ClubsBuildings from '@/views/clubs/ClubsBuildings.vue'
import ClubsEmployees from '@/views/clubs/ClubsEmployees.vue'
import ClubsHouse from '@/views/clubs/ClubsHouse.vue'
import ClubsStadium from '@/views/clubs/ClubsStadium.vue'
import ClubsProfile from '@/views/clubs/ClubsProfile.vue'

// Clubs Championship
import ClubsChampionshipCompetitions from '@/views/clubs/ChampionshipCompetitions.vue'
import ClubsChampionshipTable from '@/views/clubs/ChampionshipTable.vue'
import ClubsChampionshipHistory from '@/views/clubs/ChampionshipHistory.vue'
import ClubsChampionshipReport from '@/views/clubs/ChampionshipReport.vue'

// KARIERA OBRAZOVKY
import TasksCareer from '@/views/tasks/TasksCareer.vue'
import CareerReport from '@/views/career/CareerReport.vue'
import DailyTask from '@/views/dailyTask/DailyTask.vue'
import CareerListOfUnlocks from '@/views/career/CareerListOfUnlocks.vue'

// PREMIUM OBRAZOVKY
import PremiumGems from '@/views/premium/PremiumGems.vue'

/**
 * zakomentovane na zaklade tiketu https://powerplaystudio.atlassian.net/browse/WSM-7648,
 * komponenty zatial nemazeme aby sme sa v pripade potreby vedeli k funkcionalite rychlo vratit
 **/
// import PremiumAds from '@/views/premium/PremiumAds.vue'
import PremiumOffers from '@/views/premium/PremiumOffers.vue'
import PremiumWeeklyDeals from '@/views/premium/PremiumWeeklyDeals.vue'
import PremiumWildCard from '@/views/premium/PremiumWildCard.vue'
import PremiumEnergyDrink from '@/views/premium/PremiumEnergyDrink.vue'
import CareerDetail from '@/views/career/CareerDetail.vue'

// GROUP PARSER
import GroupUserParser from '@/views/GroupUserParser.vue'

// GRAND PRIZE OBRAZOVKY
import GrandPrizeProgress from '@/views/grand-prize/GrandPrizeProgress.vue'
import GrandPrizeWarehouse from '@/views/grand-prize/GrandPrizeWarehouse.vue'
import GrandPrizeMain from '@/views/grand-prize/GrandPrizeMain.vue'
import GrandPrizeHistory from '@/views/grand-prize/GrandPrizeHistory.vue'

// EQUIPMENT
import Equipment from '@/views/equipment/Equipment.vue'
import EquipmentDetail from '@/components/Equipment/EquipmentDetail.vue'

// BENEFITS
import BenefitsBuildings from '@/views/benefits/Buildings.vue'
import BenefitsInventory from '@/views/benefits/Inventory.vue'

// INFO VIEWS
import Contact from '@/views/info/Contact.vue'
import Instructions from '@/views/info/Instructions.vue'
import Rules from '@/views/info/Rules.vue'
import BugReport from '@/views/info/BugReport.vue'
import ChooseDiscipline from '@/views/chooseDiscipline/ChooseDiscipline.vue'
import StarofthedayDisciplines from '@/views/staroftheday/StarofthedayDisciplines.vue'
import StarofthedayDisciplineDetail from '@/views/staroftheday/StarofthedayDisciplineDetail.vue'

import StarofthedayStandings from '@/views/staroftheday/StarofthedayStandings.vue'
import StarofthedayAthlete from '@/views/staroftheday/StarofthedayAthlete.vue'
import Settings from '@/views/settings/Settings.vue'
import Audio from '@/views/settings/Audio.vue'
import Account from '@/views/settings/Account.vue'
import CrossAccountLinking from '@/views/settings/CrossAccountLinking.vue'
import DailyBonus from '@/views/dailyBonus/DailyBonus.vue'

// PROFILE
import CreateProfile from '@/views/profile/CreateProfile.vue'
import ProfileOverview from '@/views/profile/ProfileOverview.vue'
import ProfileAvatars from '@/views/profile/ProfileAvatars.vue'
import ProfileBackgrounds from '@/views/profile/ProfileBackgrounds.vue'

// LAB
import LabDisciplines from '@/views/lab/LabDisciplines.vue'
import LabEconomics from '@/views/lab/LabEconomics.vue'
import LabCamps from '@/views/lab/LabCamps.vue'

import Reports from '@/views/reports/Reports.vue'

// NEWS
import News from '@/views/news/News.vue'
import NewsMessage from '@/views/news/NewsMessage.vue'

// MAIL
import MailMessages from '@/views/mail/MailMessages.vue'
import MailNew from '@/views/mail/MailNew.vue'
import MailDetail from '@/views/mail/MailDetail.vue'
import MailBlocked from '@/views/mail/MailBlocked.vue'

// SEASONS
import Seasons from '@/views/seasons/Seasons.vue'
import SeasonsEnd from '@/views/seasons/SeasonsEnd.vue'

import GdprView from '@/views/gdpr/GdprView.vue'

import AccountVerificator from '@/views/login/AccountVerificator.vue'

// BOARD GAME EVENT
import BoardGamesEventView from '@/views/BoardGamesEventView.vue'
import { canOpenBoardGamesEvent } from '@/helpers'

type PropsCustom = Record<string, string | string[]>

const routes: RouteRecordCustom[] = [
  {
    path: '/',
    name: 'LayoutView',
    beforeEnter: (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
    ): boolean | RouteLocationRaw => {
      if (
        to.name === 'LayoutView' &&
        from.name === 'ClubsMap' &&
        to.query.redirectFrom !== from.name
      ) {
        to.query.redirectFrom = from.name
        return true
      }

      return authRouteGuard()
    },
    meta: {
      activeScene: SCENES_KEYS.MainMapScene,
    },
    children: [
      {
        path: 'gdpr-web',
        component: GdprView,
        name: 'GdprViewWeb',
      },
      {
        path: 'training/disciplines-web/:task?',
        component: TrainingDisciplines,
        name: 'TrainingDisciplinesWeb',
      },
      {
        path: 'training/trainer-web/:task?',
        component: TrainingTrainer,
        name: 'TrainingTrainerWeb',
        meta: { requiresMechanic: MECHANIC_PERSONAL_TRAINER },
      },
      {
        path: 'training/buildings-web/:task?',
        component: TrainingBuildings,
        name: 'TrainingBuildingsWeb',
      },
      {
        path: 'training/tutorial-web/:task?',
        component: TrainingTutorial,
        name: 'TrainingTutorialWeb',
      },
      {
        path: 'training/trainer-web/detail-web/:id?/:task?',
        name: 'TrainingTrainerDetailWeb',
        component: TrainingTrainerDetail,
        meta: { requiresMechanic: MECHANIC_PERSONAL_TRAINER },
      },
      {
        path: 'training/discipline-web/detail-web/:id?/:task?',
        name: 'TrainingDisciplineDetailWeb',
        component: TrainingDisciplineDetail,
      },
      {
        path: 'arena/main-web/:id?/:task?',
        component: ArenaView,
        name: 'ArenaViewWeb',
      },
      {
        path: 'arena/history-web/:task?',
        component: ArenaHistory,
        name: 'ArenaHistoryWeb',
      },
      {
        path: 'arena/building-web/:task?',
        component: ArenaBuilding,
        name: 'ArenaBuildingWeb',
      },
      {
        path: 'tasks/career-web/:task?',
        component: TasksCareer,
        name: 'TasksCareerWeb',
        meta: { requiresMechanic: MECHANIC_CAREER },
      },
      {
        path: 'career/detail-web/:id?/:task?/:careerId?',
        name: 'CareerDetailWeb',
        component: CareerDetail,
        meta: { requiresMechanic: MECHANIC_CAREER },
      },
      {
        path: 'career/list-of-unlocks-web/:task?',
        name: 'CareerListOfUnlocksWeb',
        component: CareerListOfUnlocks,
        meta: { requiresMechanic: MECHANIC_CAREER },
      },
      {
        path: 'career/report-web/:task?',
        component: CareerReport,
        name: 'CareerReportWeb',
        meta: { requiresMechanic: MECHANIC_CAREER },
      },
      {
        path: 'grand-prize/progress-web/:task?',
        name: 'GrandPrizeProgressWeb',
        component: GrandPrizeProgress,
      },
      {
        path: 'grand-prize/warehouse-web/:task?',
        component: GrandPrizeWarehouse,
        name: 'GrandPrizeWarehouseWeb',
      },
      {
        path: 'grand-prize/main-web/:id?/:task?',
        component: GrandPrizeMain,
        name: 'GrandPrizeMainWeb',
        meta: {
          showCookies: true,
        },
      },
      {
        path: 'grand-prize/history-web/:task?',
        component: GrandPrizeHistory,
        name: 'GrandPrizeHistoryWeb',
      },
      {
        path: 'equipment-web/:id?/:task?',
        component: Equipment,
        name: EQUIPMENT_WEB_ROUTE_NAME,
        meta: { requiresMechanic: MECHANIC_EQUIPMENT },
      },
      {
        path: 'equipment-web/detail-web/:id?/:task?',
        component: EquipmentDetail,
        name: EQUIPMENT_DETAIL_WEB_ROUTE_NAME,
        props: (route: RouteLocationNormalized): PropsCustom => ({
          id: route.params.id,
          isOffer: route.query.offer,
        }),
        meta: { requiresMechanic: MECHANIC_EQUIPMENT },
      },
      {
        path: 'benefits/buildings-web/:task?',
        component: BenefitsBuildings,
        name: 'BenefitsBuildingsWeb',
        meta: { requiresMechanic: MECHANIC_BENEFIT },
      },
      {
        path: 'benefits/inventory-web/:task?',
        component: BenefitsInventory,
        name: 'BenefitsInventoryWeb',
        meta: { requiresMechanic: MECHANIC_BENEFIT },
      },
      {
        path: 'choose-discipline-web/:task?',
        component: ChooseDiscipline,
        name: 'ChooseDisciplineWeb',
      },
      {
        path: 'arena/report-web/:task?',
        component: ArenaReport,
        name: 'ArenaReportWeb',
        meta: {
          showCookies: true,
        },
      },
      {
        path: 'arena/history-report-web/:task?',
        component: ArenaHistoryReport,
        name: 'ArenaHistoryReportWeb',
      },
      {
        path: 'info/contact-web/:task?',
        component: Contact,
        name: 'ContactWeb',
      },
      {
        path: 'info/instructions-web/:task?',
        component: Instructions,
        name: 'InstructionsWeb',
      },
      {
        path: 'info/rules-web/:task?',
        component: Rules,
        name: 'RulesWeb',
      },
      {
        path: 'staroftheday/disciplines-web/:task?',
        component: StarofthedayDisciplines,
        name: 'StarofthedayDisciplinesWeb',
        meta: { requiresMechanic: MECHANIC_STAR_OF_THE_DAY },
      },
      {
        path: 'staroftheday/disciplines-web/detail-web/:id?/:task?',
        component: StarofthedayDisciplineDetail,
        name: 'StarofthedayDisciplineDetailWeb',
        meta: { requiresMechanic: MECHANIC_STAR_OF_THE_DAY },
      },
      {
        path: 'staroftheday/standings-web/:task?',
        component: StarofthedayStandings,
        name: 'StarofthedayStandingsWeb',
        meta: { requiresMechanic: MECHANIC_STAR_OF_THE_DAY },
      },
      {
        path: 'staroftheday/athlete-web/:task?',
        component: StarofthedayAthlete,
        name: 'StarofthedayAthleteWeb',
        meta: { requiresMechanic: MECHANIC_STAR_OF_THE_DAY },
      },
      {
        path: 'settings/settings-web/:task?',
        component: Settings,
        name: 'SettingsWeb',
      },
      {
        path: 'settings/audio-web/:task?',
        component: Audio,
        name: 'AudioWeb',
      },
      {
        path: 'settings/account-web/:task?',
        component: Account,
        name: 'AccountWeb',
      },
      {
        path: 'settings/cross-account-linking-web/:task?',
        component: CrossAccountLinking,
        name: 'CrossAccountLinkingWeb',
      },
      {
        path: 'info/bug-report-web/:task?',
        component: BugReport,
        name: 'BugReportWeb',
      },
      {
        path: 'dailyBonus-web/:task?',
        component: DailyBonus,
        name: 'DailyBonusWeb',
      },
      {
        path: 'profile/create-web/:task?',
        component: CreateProfile,
        name: 'CreateProfileWeb',
      },
      {
        path: 'profile/overview-web/:id?/:task?/:user?',
        component: ProfileOverview,
        name: 'ProfileOverviewWeb',
      },
      {
        path: 'profile/avatars-web/:task?',
        component: ProfileAvatars,
        name: 'ProfileAvatarsWeb',
      },
      {
        path: 'profile/backgrounds-web/:task?',
        component: ProfileBackgrounds,
        name: 'ProfileBackgroundsWeb',
      },
      {
        path: 'rankings/disciplines-web/:task?',
        component: RankingDisciplines,
        name: 'RankingDisciplinesWeb',
      },
      {
        path: 'rankings/disciplines/country-web/:task?',
        component: RankingDisciplinesCountry,
        name: 'RankingDisciplinesCountryWeb',
      },
      {
        path: 'rankings/records-web/:task?',
        component: RankingRecords,
        name: 'RankingRecordsWeb',
      },
      {
        path: 'rankings/records/country-web/:task?',
        component: RankingRecordsCountry,
        name: 'RankingRecordsCountryWeb',
      },
      {
        path: 'rankings/global-web/:task?',
        component: RankingGlobal,
        name: 'RankingGlobalWeb',
      },
      {
        path: 'rankings/global/country-web/:task?',
        component: RankingGlobalCountry,
        name: 'RankingGlobalCountryWeb',
      },
      {
        path: 'rankings/global/clubs-web/:task?',
        component: RankingGlobalClubs,
        name: 'RankingGlobalClubsWeb',
      },
      {
        path: 'dailyTask-web/:task?/:id?',
        component: DailyTask,
        name: 'DailyTaskWeb',
        meta: { requiresMechanic: MECHANIC_DAILY_QUESTS },
      },
      {
        path: 'premium/gems-web/:task?',
        component: PremiumGems,
        name: 'PremiumGemsWeb',
      },
      // {
      //   path: 'premium/ads-web',
      //   component: PremiumAds,
      //   name: 'PremiumAdsWeb',
      // },
      {
        path: 'premium/offers-web/:task?',
        component: PremiumOffers,
        name: 'PremiumOffersWeb',
      },
      {
        path: 'premium/weekly-deals-web/:task?',
        component: PremiumWeeklyDeals,
        name: 'PremiumWeeklyDealsWeb',
        meta: { requiresMechanic: MECHANIC_BENEFIT, requiresAuth: true },
      },
      {
        path: 'premium/wild-card-web/:task?',
        component: PremiumWildCard,
        name: 'PremiumWildCardWeb',
      },
      {
        path: 'premium/energy-drink-web/:task?',
        component: PremiumEnergyDrink,
        name: 'PremiumEnergyDrinkWeb',
      },
      {
        path: 'premium/purchased-web/:pack_id?/:res?/:task?',
        component: PremiumGems,
        name: 'PremiumPurchasedWeb',
      },
      {
        path: 'lab/disciplines-web/:id?/:task?',
        component: LabDisciplines,
        name: 'LabDisciplinesWeb',
      },
      {
        path: 'lab/economics-web/:task?',
        component: LabEconomics,
        name: 'LabEconomicsWeb',
      },
      {
        path: 'lab/camps-web/:task?',
        component: LabCamps,
        name: 'LabCampsWeb',
      },
      {
        path: 'reports-web/:task?',
        component: Reports,
        name: 'ReportsWeb',
      },
      {
        path: 'news-web/:task?',
        component: News,
        name: 'NewsWeb',
      },
      {
        path: 'news/message-web/:news_id?/:task?',
        component: NewsMessage,
        name: 'NewsMessageWeb',
      },
      {
        path: 'flutter-test-web/:task?',
        component: FlutterTestView,
        name: 'FlutterTestWeb',
      },
      {
        path: 'mail/received-web/:task?',
        component: MailMessages,
        name: 'MailReceivedWeb',
      },
      {
        path: 'mail/sent-web/:task?',
        component: MailMessages,
        name: 'MailSentWeb',
      },
      {
        path: '/mail/new-web/:recipient?/:task?',
        component: MailNew,
        name: 'MailNewWeb',
      },
      {
        path: 'mail/detail-web/:id?/:task?',
        component: MailDetail,
        name: 'MailDetailWeb',
      },
      {
        path: 'mail/blocked-web/:task?',
        component: MailBlocked,
        name: 'MailBlockedWeb',
      },
      // Clubs
      {
        path: '/clubs/chat-web/:task?',
        component: ClubsChat,
        name: 'ClubsChatWeb',
        meta: {
          requiresMechanic: MECHANIC_CLUB,
          requiresAuth: true,
          activeScene: SCENES_KEYS.MainMapScene,
        },
      },
      {
        path: '/clubs/activity-web/:task?',
        component: ClubsActivity,
        name: 'ClubsActivityWeb',
        meta: {
          requiresMechanic: MECHANIC_CLUB,
          requiresAuth: true,
          activeScene: SCENES_KEYS.ClubsMapScene,
        },
      },
      {
        path: '/clubs/members-web/:task?',
        component: ClubsMembers,
        name: 'ClubsMembersWeb',
        meta: {
          requiresMechanic: MECHANIC_CLUB,
          requiresAuth: true,
          activeScene: SCENES_KEYS.ClubsMapScene,
        },
      },
      {
        path: '/clubs/post-message-web/:task?',
        component: ClubsPostMessage,
        name: 'ClubsPostMessageWeb',
        meta: {
          requiresMechanic: MECHANIC_CLUB,
          requiresAuth: true,
          activeScene: SCENES_KEYS.ClubsMapScene,
        },
      },
      {
        path: '/clubs/application-web/:task?',
        component: ClubsApplication,
        name: 'ClubsApplicationWeb',
        meta: {
          requiresMechanic: MECHANIC_CLUB,
          requiresAuth: true,
          activeScene: SCENES_KEYS.ClubsMapScene,
        },
      },
      {
        path: '/clubs/notice-web/:task?',
        component: ClubsNotice,
        name: 'ClubsNoticeWeb',
        meta: {
          requiresMechanic: MECHANIC_CLUB,
          requiresAuth: true,
          activeScene: SCENES_KEYS.ClubsMapScene,
        },
      },
      {
        path: '/clubs/club-web/create/:task?',
        component: ClubsCreateClub,
        name: 'ClubsCreateClubWeb',
        meta: {
          requiresMechanic: MECHANIC_CLUB,
          requiresAuth: true,
          activeScene: SCENES_KEYS.MainMapScene,
        },
      },
      {
        path: '/clubs/club-web/edit/:task?',
        component: ClubsEditClub,
        name: 'ClubsEditClubWeb',
        meta: {
          requiresMechanic: MECHANIC_CLUB,
          requiresAuth: true,
          activeScene: SCENES_KEYS.ClubsMapScene,
        },
      },
      {
        path: 'clubs/available-web/:task?',
        component: ClubsAvailable,
        name: 'ClubsAvailableWeb',
        meta: {
          requiresMechanic: MECHANIC_CLUB,
          requiresAuth: true,
          activeScene: SCENES_KEYS.MainMapScene,
        },
      },
      {
        path: '/clubs/available-players-web/:task?',
        component: ClubsAvailablePlayers,
        name: 'ClubsAvailablePlayersWeb',
        meta: {
          requiresMechanic: MECHANIC_CLUB,
          requiresAuth: true,
          activeScene: SCENES_KEYS.MainMapScene,
        },
      },
      {
        path: '/clubs/invitations-web/:task?',
        component: ClubsInvitations,
        name: 'ClubsInvitationsWeb',
        meta: {
          requiresMechanic: MECHANIC_CLUB,
          requiresAuth: true,
          activeScene: SCENES_KEYS.MainMapScene,
        },
      },
      {
        path: '/clubs/parking-web/:task?',
        component: ClubsParking,
        name: 'ClubsParkingWeb',
        meta: {
          requiresMechanic: MECHANIC_CLUB,
          requiresAuth: true,
          activeScene: SCENES_KEYS.ClubsMapScene,
          isClubBuildingRoute: true,
        },
      },
      {
        path: '/clubs/fitness-web/:task?',
        component: ClubsFitness,
        name: 'ClubsFitnessWeb',
        meta: {
          requiresMechanic: MECHANIC_CLUB,
          requiresAuth: true,
          activeScene: SCENES_KEYS.ClubsMapScene,
          isClubBuildingRoute: true,
        },
      },
      {
        path: '/clubs/relax-web/:task?',
        component: ClubsRelax,
        name: 'ClubsRelaxWeb',
        meta: {
          requiresMechanic: MECHANIC_CLUB,
          requiresAuth: true,
          activeScene: SCENES_KEYS.ClubsMapScene,
          isClubBuildingRoute: true,
        },
      },
      {
        path: '/clubs/wellness-web/:task?',
        component: ClubsWellness,
        name: 'ClubsWellnessWeb',
        meta: {
          requiresMechanic: MECHANIC_CLUB,
          requiresAuth: true,
          activeScene: SCENES_KEYS.ClubsMapScene,
          isClubBuildingRoute: true,
        },
      },
      {
        path: '/clubs/swimming-web/:task?',
        component: ClubsSwimming,
        name: 'ClubsSwimmingWeb',
        meta: {
          requiresMechanic: MECHANIC_CLUB,
          requiresAuth: true,
          activeScene: SCENES_KEYS.ClubsMapScene,
          isClubBuildingRoute: true,
        },
      },
      {
        path: '/clubs/restaurant-web/:task?',
        component: ClubsRestaurant,
        name: 'ClubsRestaurantWeb',
        meta: {
          requiresMechanic: MECHANIC_CLUB,
          requiresAuth: true,
          activeScene: SCENES_KEYS.ClubsMapScene,
          isClubBuildingRoute: true,
        },
      },
      {
        path: '/clubs/medical-web/:task?',
        component: ClubsMedical,
        name: 'ClubsMedicalWeb',
        meta: {
          requiresMechanic: MECHANIC_CLUB,
          requiresAuth: true,
          activeScene: SCENES_KEYS.ClubsMapScene,
          isClubBuildingRoute: true,
        },
      },
      {
        path: '/clubs/hotel-web/:task?',
        component: ClubsHotel,
        name: 'ClubsHotelWeb',
        meta: {
          requiresMechanic: MECHANIC_CLUB,
          requiresAuth: true,
          activeScene: SCENES_KEYS.ClubsMapScene,
          isClubBuildingRoute: true,
        },
      },
      {
        path: '/clubs/media-web/:task?',
        component: ClubsMedia,
        name: 'ClubsMediaWeb',
        meta: {
          requiresMechanic: MECHANIC_CLUB,
          requiresAuth: true,
          activeScene: SCENES_KEYS.ClubsMapScene,
          isClubBuildingRoute: true,
        },
      },
      {
        path: '/clubs/directorate-web/:task?',
        component: ClubsDirectorate,
        name: 'ClubsDirectorateWeb',
        meta: {
          requiresMechanic: MECHANIC_CLUB,
          requiresAuth: true,
          activeScene: SCENES_KEYS.ClubsMapScene,
          isClubBuildingRoute: true,
        },
      },
      {
        path: '/clubs/buildings-web/:task?',
        component: ClubsBuildings,
        name: 'ClubsBuildingsWeb',
        meta: {
          requiresMechanic: MECHANIC_CLUB,
          requiresAuth: true,
          activeScene: SCENES_KEYS.ClubsMapScene,
        },
      },
      {
        path: '/clubs/employees-web/:task?',
        component: ClubsEmployees,
        name: 'ClubsEmployeesWeb',
        meta: {
          requiresMechanic: MECHANIC_CLUB,
          requiresAuth: true,
          activeScene: SCENES_KEYS.ClubsMapScene,
        },
      },
      {
        path: '/clubs/clubhouse-web/:task?',
        component: ClubsHouse,
        name: 'ClubsHouseWeb',
        meta: {
          requiresMechanic: MECHANIC_CLUB,
          requiresAuth: true,
          activeScene: SCENES_KEYS.ClubsMapScene,
        },
      },
      {
        path: '/clubs/stadium-web/:task?',
        component: ClubsStadium,
        name: 'ClubsStadiumWeb',
        meta: {
          requiresMechanic: MECHANIC_CLUB,
          requiresAuth: true,
          activeScene: SCENES_KEYS.ClubsMapScene,
        },
      },
      {
        path: '/clubs/profile-web/:clubProfileId?/:task?',
        component: ClubsProfile,
        name: 'ClubsProfileWeb',
        meta: {
          requiresMechanic: MECHANIC_CLUB,
          requiresAuth: true,
          activeScene: SCENES_KEYS.MainMapScene,
        },
      },
      {
        path: '/clubs/championship-competitions-web/:task?',
        component: ClubsChampionshipCompetitions,
        name: 'ClubsChampionshipCompetitionsWeb',
        meta: {
          requiresMechanic: MECHANIC_CLUB,
          requiresAuth: true,
          activeScene: SCENES_KEYS.MainMapScene,
        },
      },
      {
        path: '/clubs/championship-table-web/:task?',
        component: ClubsChampionshipTable,
        name: 'ClubsChampionshipTableWeb',
        meta: {
          requiresMechanic: MECHANIC_CLUB,
          requiresAuth: true,
          activeScene: SCENES_KEYS.MainMapScene,
        },
      },
      {
        path: '/clubs/championship-history-web/:task?',
        component: ClubsChampionshipHistory,
        name: 'ClubsChampionshipHistoryWeb',
        meta: {
          requiresMechanic: MECHANIC_CLUB,
          requiresAuth: true,
          activeScene: SCENES_KEYS.MainMapScene,
        },
      },
      {
        path: '/clubs/championship-report-web/:task?',
        component: ClubsChampionshipReport,
        name: 'ClubsChampionshipReportWeb',
        meta: {
          requiresMechanic: MECHANIC_CLUB,
          requiresAuth: true,
          activeScene: SCENES_KEYS.MainMapScene,
        },
      },
      {
        path: '/seasons-web/:type/:task?',
        component: Seasons,
        name: 'SeasonsWeb',
      },
      {
        path: '/seasons-end-web',
        component: SeasonsEnd,
        name: 'SeasonsEndWeb',
      },
      {
        path: '/board-games-event-web',
        name: 'BoardGamesEventViewWeb',
        component: BoardGamesEventView,
        beforeEnter: (): void => {
          if (isMobile() || !canOpenBoardGamesEvent()) {
            router.push('/')
            return
          }
        },
        meta: {
          requiresAuth: true,
          requiresMechanic: MECHANIC_BOARD_GAMES_EVENT,
          activeScene: SCENES_KEYS.MainMapScene,
          hideWebWindowHeaderComponent: true,
        },
      },
      ...eventRouterWeb,
      ...premiumRoutesWeb,
    ],
    components: {
      default: LayoutView,
      header: HeaderComponent,
      footer: FooterComponent,
    },
  },
  {
    path: '/gdpr',
    name: 'GdprView',
    components: {
      default: GdprView,
    },
    meta: { requiresAuth: true, activeScene: SCENES_KEYS.MainMapScene },
  },
  {
    path: '/tutorial',
    name: 'TutorialView',
    meta: { requiresAuth: true, activeScene: SCENES_KEYS.MainMapScene },
    children: [
      {
        path: 'layout/:task?',
        component: LayoutView,
        name: 'LayoutViewTutorial',
        meta: { hideWebWindowComponent: true },
      },
      {
        path: 'layout/:task?',
        component: LayoutView,
        name: 'LayoutViewTutorialWeb',
        meta: { hideWebWindowComponent: true },
      },
      {
        path: 'clubs/:clubId?/:task?',
        component: LayoutView,
        name: 'ClubsMapTutorial',
        meta: { activeScene: SCENES_KEYS.ClubsMapScene, hideWebWindowComponent: true },
      },
      {
        path: 'clubs/:clubId?/:task?',
        component: LayoutView,
        name: 'ClubsMapTutorialWeb',
        meta: { activeScene: SCENES_KEYS.ClubsMapScene, hideWebWindowComponent: true },
      },
      {
        path: 'clubs/clubhouse/:task?',
        component: ClubsHouse,
        name: 'ClubsHouseTutorial',
        meta: { activeScene: SCENES_KEYS.ClubsMapScene },
      },
      {
        path: 'clubs/clubhouse-web/:task?',
        component: ClubsHouse,
        name: 'ClubsHouseWebTutorial',
        meta: { activeScene: SCENES_KEYS.ClubsMapScene },
      },
      {
        path: 'clubs/available/:task?',
        component: ClubsAvailable,
        name: 'ClubsAvailableTutorial',
        meta: { activeScene: SCENES_KEYS.MainMapScene },
      },
      {
        path: 'clubs/available-web/:task?',
        component: ClubsAvailable,
        name: 'ClubsAvailableWebTutorial',
        meta: { activeScene: SCENES_KEYS.MainMapScene },
      },
      {
        path: 'career/detail/:id?/:task?/:careerId?',
        component: CareerDetail,
        name: 'CareerDetailTutorial',
      },
      {
        path: 'career/detail-web/:id?/:task?/:careerId?',
        component: CareerDetail,
        name: 'CareerDetailWebTutorial',
      },
      {
        path: 'choose-discipline/:task?',
        component: ChooseDiscipline,
        name: 'ChooseDisciplineTutorial',
      },
      {
        path: 'choose-discipline-web/:task?',
        component: ChooseDiscipline,
        name: 'ChooseDisciplineTutorialWeb',
      },
      {
        path: 'training/disciplines/:task?',
        component: TrainingDisciplines,
        name: 'TrainingDisciplinesTutorial',
      },
      {
        path: 'training/disciplines-web/:task?',
        component: TrainingDisciplines,
        name: 'TrainingDisciplinesWebTutorial',
      },
      {
        path: 'training/discipline/detail/:id?/:task?',
        component: TrainingDisciplineDetail,
        name: 'TrainingDisciplineDetailTutorial',
      },
      {
        path: 'training/discipline/detail-web/:id?/:task?',
        component: TrainingDisciplineDetail,
        name: 'TrainingDisciplineDetailTutorialWeb',
      },
      {
        path: 'arena/main/:id?/:task?',
        component: ArenaView,
        name: 'ArenaViewTutorial',
      },
      {
        path: 'arena/main-web/:id?/:task?',
        component: ArenaView,
        name: 'ArenaViewWebTutorial',
      },
      {
        path: 'arena/report/:task?',
        component: ArenaReport,
        name: 'ArenaReportTutorial',
      },
      {
        path: 'arena/report-web/:task?',
        component: ArenaReport,
        name: 'ArenaReportTutorialWeb',
      },
      {
        path: 'equipment/:id?/:task?',
        component: Equipment,
        name: 'EquipmentTutorial',
      },
      {
        path: 'equipment-web/:id?/:task?',
        component: Equipment,
        name: 'EquipmentWebTutorial',
      },
      {
        path: 'equipment/detail/:id?/:task?',
        component: EquipmentDetail,
        name: 'EquipmentDetailTutorial',
        props: (route: RouteLocationNormalized): PropsCustom => ({
          id: route.params.id,
          isOffer: route.query.offer,
        }),
      },
      {
        path: 'equipment-web/detail-web/:id?/:task?',
        component: EquipmentDetail,
        name: 'EquipmentDetailTutorialWeb',
        props: (route: RouteLocationNormalized): PropsCustom => ({
          id: route.params.id,
          isOffer: route.query.offer,
        }),
      },
      {
        path: 'grand-prize/warehouse/:task?',
        component: GrandPrizeWarehouse,
        name: 'GrandPrizeWarehouseTutorial',
      },
      {
        path: 'grand-prize/warehouse-web/:task?',
        component: GrandPrizeWarehouse,
        name: 'GrandPrizeWarehouseWebTutorial',
      },
      {
        path: 'grand-prize/main/:id?/:task?',
        component: GrandPrizeMain,
        name: 'GrandPrizeMainTutorial',
      },
      {
        path: 'grand-prize/main-web/:id?/:task?',
        component: GrandPrizeMain,
        name: 'GrandPrizeMainTutorialWeb',
      },
      {
        path: 'profile/create/:task?',
        component: CreateProfile,
        name: 'CreateProfileTutorial',
      },
      {
        path: 'profile/create-web/:task?',
        component: CreateProfile,
        name: 'CreateProfileTutorialWeb',
      },
    ],
    components: {
      default: TutorialView,
      header: HeaderComponent,
      footer: FooterComponent,
    },
  },
  {
    path: '/set-login/:tokenId?/:reg?/:isFlutter?/:isMultiWebView?/:task?',
    name: 'SetLogin',
    component: SetLogin,
  },
  {
    path: '/admin-token-extractor/:tokenId?',
    name: ADMIN_TOKEN_EXTRACTOR_ROUTE_NAME,
    component: AdminTokenExtractor,
  },
  {
    path: '/login/:task?',
    name: LOGIN_VIEW_ROUTE_NAME,
    component: LoginView,
    props: (route: RouteLocationNormalized): PropsCustom => ({
      state: route.query.state,
      code: route.query.code,
      scope: route.query.scope,
      authuser: route.query.authuser,
      prompt: route.query.prompt,
    }),
  },
  {
    path: '/maintenance/:task?',
    name: MAINTENANCE_ROUTE_NAME,
    component: MaintenanceView,
    meta: { activeScene: SCENES_KEYS.MainMapScene },
  },
  {
    path: '/clubs/:clubId?/:task?',
    name: 'ClubsMap',
    components: {
      default: LayoutView,
      header: HeaderComponent,
      footer: FooterComponent,
    },
    meta: {
      requiresMechanic: MECHANIC_CLUB,
      requiresAuth: true,
      activeScene: SCENES_KEYS.ClubsMapScene,
      hideWebWindowComponent: true,
    },
  },
  {
    path: '/training/trainer/:task?',
    name: 'TrainingTrainer',
    components: {
      default: TrainingTrainer,
      header: HeaderComponent,
    },
    meta: {
      requiresMechanic: MECHANIC_PERSONAL_TRAINER,
      requiresAuth: true,
      activeScene: SCENES_KEYS.MainMapScene,
    },
  },
  {
    path: '/training/trainer/detail/:id?/:task?',
    name: 'TrainingTrainerDetail',
    components: {
      default: TrainingTrainerDetail,
      header: HeaderComponent,
    },
    meta: {
      requiresMechanic: MECHANIC_PERSONAL_TRAINER,
      requiresAuth: true,
      activeScene: SCENES_KEYS.MainMapScene,
    },
  },

  {
    path: '/training/disciplines/:task?',
    name: 'TrainingDisciplines',
    components: {
      default: TrainingDisciplines,
      header: HeaderComponent,
    },
    meta: { requiresAuth: true, activeScene: SCENES_KEYS.MainMapScene },
  },
  {
    path: '/training/discipline/detail/:id?/:task?',
    name: 'TrainingDisciplineDetail',
    components: {
      default: TrainingDisciplineDetail,
      header: HeaderComponent,
    },
    meta: { requiresAuth: true, activeScene: SCENES_KEYS.MainMapScene },
  },
  {
    path: '/training/buildings/:task?',
    name: 'TrainingBuildings',
    components: {
      default: TrainingBuildings,
      header: HeaderComponent,
    },
    meta: { requiresAuth: true, activeScene: SCENES_KEYS.MainMapScene },
  },
  {
    path: '/training/tutorial/:task?',
    name: 'TrainingTutorial',
    components: {
      default: TrainingTutorial,
      header: HeaderComponent,
    },
    meta: { requiresAuth: true, activeScene: SCENES_KEYS.MainMapScene },
  },
  {
    path: '/arena/main/:id?/:task?',
    name: 'ArenaView',
    components: {
      default: ArenaView,
      header: HeaderComponent,
    },
    meta: { requiresAuth: true, activeScene: SCENES_KEYS.MainMapScene },
  },
  {
    path: '/arena/history/:task?',
    name: 'ArenaHistory',
    components: {
      default: ArenaHistory,
      header: HeaderComponent,
    },
    meta: { requiresAuth: true, activeScene: SCENES_KEYS.MainMapScene },
  },

  {
    path: '/arena/building/:task?',
    name: 'ArenaBuilding',
    components: {
      default: ArenaBuilding,
      header: HeaderComponent,
    },
    meta: { requiresAuth: true, activeScene: SCENES_KEYS.MainMapScene },
  },
  {
    path: '/arena/report/:task?',
    name: 'ArenaReport',
    components: {
      default: ArenaReport,
    },
    meta: { requiresAuth: true, activeScene: SCENES_KEYS.MainMapScene },
  },
  {
    path: '/arena/history-report/:task?',
    name: 'ArenaHistoryReport',
    components: {
      default: ArenaHistoryReport,
      header: HeaderComponent,
    },
    meta: { requiresAuth: true, activeScene: SCENES_KEYS.MainMapScene },
  },
  {
    path: '/premium/gems/:task?',
    name: 'PremiumGems',
    components: {
      default: PremiumGems,
      header: HeaderComponent,
    },
    meta: { requiresAuth: true, activeScene: SCENES_KEYS.MainMapScene },
  },
  // {
  //   path: '/premium/ads',
  //   name: 'PremiumAds',
  //   components: {
  //     default: PremiumAds,
  //     header: HeaderComponent,
  //   },
  //   meta: { requiresAuth: true },
  // },
  {
    path: '/premium/offers/:task?',
    name: 'PremiumOffers',
    components: {
      default: PremiumOffers,
      header: HeaderComponent,
    },
    meta: { requiresAuth: true, activeScene: SCENES_KEYS.MainMapScene },
  },
  {
    path: '/premium/weekly-deals/:task?',
    name: 'PremiumWeeklyDeals',
    components: {
      default: PremiumWeeklyDeals,
      header: HeaderComponent,
    },
    meta: {
      requiresMechanic: MECHANIC_BENEFIT,
      requiresAuth: true,
      activeScene: SCENES_KEYS.MainMapScene,
    },
  },
  {
    path: '/premium/wild-card/:task?',
    name: 'PremiumWildCard',
    components: {
      default: PremiumWildCard,
      header: HeaderComponent,
    },
    meta: { requiresAuth: true, activeScene: SCENES_KEYS.MainMapScene },
  },
  {
    path: '/premium/energy-drink/:task?',
    name: 'PremiumEnergyDrink',
    components: {
      default: PremiumEnergyDrink,
      header: HeaderComponent,
    },
    meta: { requiresAuth: true, activeScene: SCENES_KEYS.MainMapScene },
  },
  {
    path: '/premium/purchased/:pack_id?/:res?/:task?',
    name: 'PremiumPurchased',
    components: {
      default: PremiumGems,
      header: HeaderComponent,
    },
    meta: { requiresAuth: true, activeScene: SCENES_KEYS.MainMapScene },
  },
  {
    path: '/career/detail/:id?/:task?/:careerId?',
    name: 'CareerDetail',
    components: {
      header: HeaderComponent,
      default: CareerDetail,
    },
    meta: {
      requiresMechanic: MECHANIC_CAREER,
      requiresAuth: true,
      activeScene: SCENES_KEYS.MainMapScene,
    },
  },
  {
    path: '/career/list-of-unlocks/:task?',
    name: 'CareerListOfUnlocks',
    components: {
      header: HeaderComponent,
      default: CareerListOfUnlocks,
    },
    meta: {
      requiresMechanic: MECHANIC_CAREER,
      requiresAuth: true,
      activeScene: SCENES_KEYS.MainMapScene,
    },
  },
  {
    path: '/reset-form/:task?',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: { activeScene: SCENES_KEYS.MainMapScene },
  },
  {
    path: '/account-verificator',
    name: 'AccountVerificator',
    component: AccountVerificator,
  },
  {
    path: '/rankings/disciplines/:task?',
    name: 'RankingDisciplines',
    components: {
      default: RankingDisciplines,
      header: HeaderComponent,
    },
    meta: { requiresAuth: true, activeScene: SCENES_KEYS.MainMapScene },
  },
  {
    path: '/rankings/disciplines/country/:task?',
    name: 'RankingDisciplinesCountry',
    components: {
      default: RankingDisciplinesCountry,
      header: HeaderComponent,
    },
    meta: { requiresAuth: true, activeScene: SCENES_KEYS.MainMapScene },
  },
  {
    path: '/rankings/records/:task?',
    name: 'RankingRecords',
    components: {
      default: RankingRecords,
      header: HeaderComponent,
    },
    meta: { requiresAuth: true, activeScene: SCENES_KEYS.MainMapScene },
  },
  {
    path: '/rankings/records/country/:task?',
    name: 'RankingRecordsCountry',
    components: {
      default: RankingRecordsCountry,
      header: HeaderComponent,
    },
    meta: { requiresAuth: true, activeScene: SCENES_KEYS.MainMapScene },
  },
  {
    path: '/rankings/global/:task?',
    name: 'RankingGlobal',
    components: {
      default: RankingGlobal,
      header: HeaderComponent,
    },
    meta: { requiresAuth: true, activeScene: SCENES_KEYS.MainMapScene },
  },
  {
    path: '/rankings/global/country/:task?',
    name: 'RankingGlobalCountry',
    components: {
      default: RankingGlobalCountry,
      header: HeaderComponent,
    },
    meta: { requiresAuth: true, activeScene: SCENES_KEYS.MainMapScene },
  },
  {
    path: '/rankings/global/clubs/:task?',
    name: 'RankingGlobalClubs',
    components: {
      default: RankingGlobalClubs,
      header: HeaderComponent,
    },
    meta: { requiresAuth: true, activeScene: SCENES_KEYS.MainMapScene },
  },
  // clubs
  {
    path: '/clubs/members/:task?',
    name: 'ClubsMembers',
    components: {
      default: ClubsMembers,
      header: HeaderComponent,
    },
    meta: {
      requiresMechanic: MECHANIC_CLUB,
      requiresAuth: true,
      activeScene: SCENES_KEYS.ClubsMapScene,
    },
  },
  {
    path: '/clubs/activity/:task?',
    name: 'ClubsActivity',
    components: {
      default: ClubsActivity,
      header: HeaderComponent,
    },
    meta: {
      requiresMechanic: MECHANIC_CLUB,
      requiresAuth: true,
      activeScene: SCENES_KEYS.ClubsMapScene,
    },
  },
  {
    path: '/clubs/post-message/:task?',
    name: 'ClubsPostMessage',
    components: {
      default: ClubsPostMessage,
      header: HeaderComponent,
    },
    meta: {
      requiresMechanic: MECHANIC_CLUB,
      requiresAuth: true,
      activeScene: SCENES_KEYS.ClubsMapScene,
    },
  },
  {
    path: '/clubs/application/:task?',
    name: 'ClubsApplication',
    components: {
      default: ClubsApplication,
      header: HeaderComponent,
    },
    meta: {
      requiresMechanic: MECHANIC_CLUB,
      requiresAuth: true,
      activeScene: SCENES_KEYS.ClubsMapScene,
    },
  },
  {
    path: '/clubs/notice/:task?',
    name: 'ClubsNotice',
    components: {
      default: ClubsNotice,
      header: HeaderComponent,
    },
    meta: {
      requiresMechanic: MECHANIC_CLUB,
      requiresAuth: true,
      activeScene: SCENES_KEYS.ClubsMapScene,
    },
  },
  {
    path: '/clubs/chat/:task?',
    name: 'ClubsChat',
    components: {
      header: HeaderComponent,
      default: ClubsChat,
    },
    meta: {
      requiresMechanic: MECHANIC_CLUB,
      requiresAuth: true,
      activeScene: SCENES_KEYS.MainMapScene,
    },
  },
  {
    path: '/clubs/club/create/:task?',
    name: 'ClubsCreateClub',
    components: {
      header: HeaderComponent,
      default: ClubsCreateClub,
    },
    meta: {
      requiresMechanic: MECHANIC_CLUB,
      requiresAuth: true,
      activeScene: SCENES_KEYS.MainMapScene,
    },
  },
  {
    path: '/clubs/club/edit/:task?',
    name: 'ClubsEditClub',
    components: {
      header: HeaderComponent,
      default: ClubsEditClub,
    },
    meta: {
      requiresMechanic: MECHANIC_CLUB,
      requiresAuth: true,
      activeScene: SCENES_KEYS.ClubsMapScene,
    },
  },
  {
    path: '/clubs/available/:task?',
    name: 'ClubsAvailable',
    components: {
      header: HeaderComponent,
      default: ClubsAvailable,
    },
    meta: {
      requiresMechanic: MECHANIC_CLUB,
      requiresAuth: true,
      activeScene: SCENES_KEYS.MainMapScene,
    },
  },
  {
    path: '/clubs/available-players/:task?',
    name: 'ClubsAvailablePlayers',
    components: {
      header: HeaderComponent,
      default: ClubsAvailablePlayers,
    },
    meta: {
      requiresMechanic: MECHANIC_CLUB,
      requiresAuth: true,
      activeScene: SCENES_KEYS.MainMapScene,
    },
  },
  {
    path: '/clubs/invitations/:task?',
    name: 'ClubsInvitations',
    components: {
      header: HeaderComponent,
      default: ClubsInvitations,
    },
    meta: {
      requiresMechanic: MECHANIC_CLUB,
      requiresAuth: true,
      activeScene: SCENES_KEYS.MainMapScene,
    },
  },
  {
    path: '/clubs/parking/:task?',
    name: 'ClubsParking',
    components: {
      header: HeaderComponent,
      default: ClubsParking,
    },
    meta: {
      requiresMechanic: MECHANIC_CLUB,
      requiresAuth: true,
      activeScene: SCENES_KEYS.ClubsMapScene,
      isClubBuildingRoute: true,
    },
  },
  {
    path: '/clubs/fitness/:task?',
    name: 'ClubsFitness',
    components: {
      header: HeaderComponent,
      default: ClubsFitness,
    },
    meta: {
      requiresMechanic: MECHANIC_CLUB,
      requiresAuth: true,
      activeScene: SCENES_KEYS.ClubsMapScene,
      isClubBuildingRoute: true,
    },
  },
  {
    path: '/clubs/relax/:task?',
    name: 'ClubsRelax',
    components: {
      header: HeaderComponent,
      default: ClubsRelax,
    },
    meta: {
      requiresMechanic: MECHANIC_CLUB,
      requiresAuth: true,
      activeScene: SCENES_KEYS.ClubsMapScene,
      isClubBuildingRoute: true,
    },
  },
  {
    path: '/clubs/wellness/:task?',
    name: 'ClubsWellness',
    components: {
      header: HeaderComponent,
      default: ClubsWellness,
    },
    meta: {
      requiresMechanic: MECHANIC_CLUB,
      requiresAuth: true,
      activeScene: SCENES_KEYS.ClubsMapScene,
      isClubBuildingRoute: true,
    },
  },
  {
    path: '/clubs/swimming/:task?',
    name: 'ClubsSwimming',
    components: {
      header: HeaderComponent,
      default: ClubsSwimming,
    },
    meta: {
      requiresMechanic: MECHANIC_CLUB,
      requiresAuth: true,
      activeScene: SCENES_KEYS.ClubsMapScene,
      isClubBuildingRoute: true,
    },
  },
  {
    path: '/clubs/restaurant/:task?',
    name: 'ClubsRestaurant',
    components: {
      header: HeaderComponent,
      default: ClubsRestaurant,
    },
    meta: {
      requiresMechanic: MECHANIC_CLUB,
      requiresAuth: true,
      activeScene: SCENES_KEYS.ClubsMapScene,
      isClubBuildingRoute: true,
    },
  },
  {
    path: '/clubs/medical/:task?',
    name: 'ClubsMedical',
    components: {
      header: HeaderComponent,
      default: ClubsMedical,
    },
    meta: {
      requiresMechanic: MECHANIC_CLUB,
      requiresAuth: true,
      activeScene: SCENES_KEYS.ClubsMapScene,
      isClubBuildingRoute: true,
    },
  },
  {
    path: '/clubs/hotel/:task?',
    name: 'ClubsHotel',
    components: {
      header: HeaderComponent,
      default: ClubsHotel,
    },
    meta: {
      requiresMechanic: MECHANIC_CLUB,
      requiresAuth: true,
      activeScene: SCENES_KEYS.ClubsMapScene,
      isClubBuildingRoute: true,
    },
  },
  {
    path: '/clubs/media/:task?',
    name: 'ClubsMedia',
    components: {
      header: HeaderComponent,
      default: ClubsMedia,
    },
    meta: {
      requiresMechanic: MECHANIC_CLUB,
      requiresAuth: true,
      activeScene: SCENES_KEYS.ClubsMapScene,
      isClubBuildingRoute: true,
    },
  },
  {
    path: '/clubs/directorate/:task?',
    name: 'ClubsDirectorate',
    components: {
      header: HeaderComponent,
      default: ClubsDirectorate,
    },
    meta: {
      requiresMechanic: MECHANIC_CLUB,
      requiresAuth: true,
      activeScene: SCENES_KEYS.ClubsMapScene,
      isClubBuildingRoute: true,
    },
  },
  {
    path: '/clubs/buildings/:task?',
    name: 'ClubsBuildings',
    components: {
      header: HeaderComponent,
      default: ClubsBuildings,
    },
    meta: {
      requiresMechanic: MECHANIC_CLUB,
      requiresAuth: true,
      activeScene: SCENES_KEYS.ClubsMapScene,
    },
  },
  {
    path: '/clubs/employees/:task?',
    name: 'ClubsEmployees',
    components: {
      header: HeaderComponent,
      default: ClubsEmployees,
    },
    meta: {
      requiresMechanic: MECHANIC_CLUB,
      requiresAuth: true,
      activeScene: SCENES_KEYS.ClubsMapScene,
    },
  },
  {
    path: '/clubs/clubhouse/:task?',
    name: 'ClubsHouse',
    components: {
      header: HeaderComponent,
      default: ClubsHouse,
    },
    meta: {
      requiresMechanic: MECHANIC_CLUB,
      requiresAuth: true,
      activeScene: SCENES_KEYS.ClubsMapScene,
    },
  },
  {
    path: '/clubs/stadium/:task?',
    name: 'ClubsStadium',
    components: {
      header: HeaderComponent,
      default: ClubsStadium,
    },
    meta: {
      requiresMechanic: MECHANIC_CLUB,
      requiresAuth: true,
      activeScene: SCENES_KEYS.ClubsMapScene,
    },
  },
  {
    path: '/clubs/profile/:clubProfileId?/:task?',
    name: 'ClubsProfile',
    components: {
      header: HeaderComponent,
      default: ClubsProfile,
    },
    meta: {
      requiresMechanic: MECHANIC_CLUB,
      requiresAuth: true,
      activeScene: SCENES_KEYS.MainMapScene,
    },
  },
  {
    path: '/clubs/championship-competitions/:task?',
    name: 'ClubsChampionshipCompetitions',
    components: {
      header: HeaderComponent,
      default: ClubsChampionshipCompetitions,
    },
    meta: {
      requiresMechanic: MECHANIC_CLUB,
      requiresAuth: true,
      activeScene: SCENES_KEYS.MainMapScene,
    },
  },
  {
    path: '/clubs/championship-table/:task?',
    name: 'ClubsChampionshipTable',
    components: {
      header: HeaderComponent,
      default: ClubsChampionshipTable,
    },
    meta: {
      requiresMechanic: MECHANIC_CLUB,
      requiresAuth: true,
      activeScene: SCENES_KEYS.MainMapScene,
    },
  },
  {
    path: '/clubs/championship-history/:task?',
    name: 'ClubsChampionshipHistory',
    components: {
      header: HeaderComponent,
      default: ClubsChampionshipHistory,
    },
    meta: {
      requiresMechanic: MECHANIC_CLUB,
      requiresAuth: true,
      activeScene: SCENES_KEYS.MainMapScene,
    },
  },
  {
    path: '/clubs/championship-report/:task?',
    name: 'ClubsChampionshipReport',
    components: {
      default: ClubsChampionshipReport,
    },
    meta: {
      requiresMechanic: MECHANIC_CLUB,
      requiresAuth: true,
      activeScene: SCENES_KEYS.MainMapScene,
    },
  },
  {
    path: '/tasks/career/:task?',
    name: 'TasksCareer',
    components: {
      header: HeaderComponent,
      default: TasksCareer,
    },
    meta: {
      requiresMechanic: MECHANIC_CAREER,
      requiresAuth: true,
      activeScene: SCENES_KEYS.MainMapScene,
    },
  },
  {
    path: '/career/report/:task?',
    name: 'CareerReport',
    components: {
      default: CareerReport,
    },
    meta: {
      requiresMechanic: MECHANIC_CAREER,
      requiresAuth: true,
      activeScene: SCENES_KEYS.MainMapScene,
    },
  },
  {
    path: '/grand-prize/progress/:task?',
    name: 'GrandPrizeProgress',
    components: {
      header: HeaderComponent,
      default: GrandPrizeProgress,
    },
    meta: { requiresAuth: true, activeScene: SCENES_KEYS.MainMapScene },
  },
  {
    path: '/group/:id?/:task?',
    name: 'GroupUserParser',
    components: {
      default: GroupUserParser,
    },
    meta: { requiresAuth: true, activeScene: SCENES_KEYS.MainMapScene },
  },
  {
    path: '/grand-prize/warehouse/:task?',
    name: 'GrandPrizeWarehouse',
    components: {
      default: GrandPrizeWarehouse,
      header: HeaderComponent,
    },
    meta: { requiresAuth: true, activeScene: SCENES_KEYS.MainMapScene },
  },
  {
    path: '/grand-prize/main/:id?/:task?',
    name: 'GrandPrizeMain',
    components: {
      default: GrandPrizeMain,
      header: HeaderComponent,
    },
    meta: { requiresAuth: true, activeScene: SCENES_KEYS.MainMapScene },
  },
  {
    path: '/grand-prize/history/:task?',
    name: 'GrandPrizeHistory',
    components: {
      default: GrandPrizeHistory,
      header: HeaderComponent,
    },
    meta: { requiresAuth: true, activeScene: SCENES_KEYS.MainMapScene },
  },
  {
    path: '/equipment/:id?/:task?',
    name: EQUIPMENT_ROUTE_NAME,
    components: {
      default: Equipment,
      header: HeaderComponent,
    },
    meta: {
      requiresMechanic: MECHANIC_EQUIPMENT,
      requiresAuth: true,
      activeScene: SCENES_KEYS.MainMapScene,
    },
  },
  {
    path: '/equipment/detail/:id?/:task?',
    name: EQUIPMENT_DETAIL_ROUTE_NAME,
    props: (route: RouteLocationNormalized): PropsCustom => ({
      id: route.params.id,
      isOffer: route.query.offer,
    }),
    components: {
      default: EquipmentDetail,
      header: HeaderComponent,
    },
    meta: {
      requiresMechanic: MECHANIC_EQUIPMENT,
      requiresAuth: true,
      activeScene: SCENES_KEYS.MainMapScene,
    },
  },
  {
    path: '/benefits/buildings/:task?',
    name: 'BenefitsBuildings',
    components: {
      default: BenefitsBuildings,
      header: HeaderComponent,
    },
    meta: {
      requiresMechanic: MECHANIC_BENEFIT,
      requiresAuth: true,
      activeScene: SCENES_KEYS.MainMapScene,
    },
  },
  {
    path: '/benefits/inventory/:task?',
    name: 'BenefitsInventory',
    components: {
      default: BenefitsInventory,
      header: HeaderComponent,
    },
    meta: {
      requiresMechanic: MECHANIC_BENEFIT,
      requiresAuth: true,
      activeScene: SCENES_KEYS.MainMapScene,
    },
  },
  {
    path: '/choose-discipline/:task?',
    name: 'ChooseDiscipline',
    components: {
      default: ChooseDiscipline,
    },
    meta: { requiresAuth: true, activeScene: SCENES_KEYS.MainMapScene },
  },
  {
    path: '/info/contact/:task?',
    name: 'Contact',
    components: {
      default: Contact,
      header: HeaderComponent,
    },
    meta: { requiresAuth: true, activeScene: SCENES_KEYS.MainMapScene },
  },
  {
    path: '/info/instructions/:task?',
    name: 'Instructions',
    components: {
      default: Instructions,
      header: HeaderComponent,
    },
    meta: { requiresAuth: true, activeScene: SCENES_KEYS.MainMapScene },
  },
  {
    path: '/info/rules/:task?',
    name: 'Rules',
    components: {
      default: Rules,
      header: HeaderComponent,
    },
    meta: { requiresAuth: true, activeScene: SCENES_KEYS.MainMapScene },
  },
  {
    path: '/staroftheday/athlete/:task?',
    name: 'StarofthedayAthlete',
    components: {
      default: StarofthedayAthlete,
      header: HeaderComponent,
    },
    meta: {
      requiresMechanic: MECHANIC_STAR_OF_THE_DAY,
      requiresAuth: true,
      activeScene: SCENES_KEYS.MainMapScene,
    },
  },
  {
    path: '/staroftheday/disciplines/:task?',
    name: 'StarofthedayDisciplines',
    components: {
      header: HeaderComponent,
      default: StarofthedayDisciplines,
    },
    meta: {
      requiresMechanic: MECHANIC_STAR_OF_THE_DAY,
      requiresAuth: true,
      activeScene: SCENES_KEYS.MainMapScene,
    },
  },
  {
    path: '/staroftheday/standings/:task?',
    name: 'StarofthedayStandings',
    components: {
      default: StarofthedayStandings,
      header: HeaderComponent,
    },
    meta: {
      requiresMechanic: MECHANIC_STAR_OF_THE_DAY,
      requiresAuth: true,
      activeScene: SCENES_KEYS.MainMapScene,
    },
  },
  {
    path: '/staroftheday/disciplines/detail/:id?/:task?',
    name: 'StarofthedayDisciplineDetail',
    components: {
      default: StarofthedayDisciplineDetail,
      header: HeaderComponent,
    },
    meta: {
      requiresMechanic: MECHANIC_STAR_OF_THE_DAY,
      requiresAuth: true,
      activeScene: SCENES_KEYS.MainMapScene,
    },
  },
  {
    path: '/info/bug-report/:task?',
    name: 'BugReport',
    components: {
      default: BugReport,
      header: HeaderComponent,
    },
    meta: { requiresAuth: true, activeScene: SCENES_KEYS.MainMapScene },
  },
  {
    path: '/flutter-test/:task?',
    name: 'FlutterTest',
    components: {
      default: FlutterTestView,
      header: HeaderComponent,
    },
    meta: { requiresAuth: true, activeScene: SCENES_KEYS.MainMapScene },
  },
  {
    path: '/settings/settings/:task?',
    name: 'Settings',
    components: {
      default: Settings,
      header: HeaderComponent,
    },
    meta: { requiresAuth: true, activeScene: SCENES_KEYS.MainMapScene },
  },
  {
    path: '/settings/audio/:task?',
    name: 'Audio',
    components: {
      default: Audio,
      header: HeaderComponent,
    },
    meta: { requiresAuth: true, activeScene: SCENES_KEYS.MainMapScene },
  },
  {
    path: '/settings/account/:task?',
    name: 'Account',
    components: {
      default: Account,
      header: HeaderComponent,
    },
    meta: { requiresAuth: true, activeScene: SCENES_KEYS.MainMapScene },
  },
  {
    path: '/dailyBonus/:task?',
    name: 'DailyBonus',
    components: {
      default: DailyBonus,
      header: HeaderComponent,
    },
    meta: { requiresAuth: true, activeScene: SCENES_KEYS.MainMapScene },
  },
  {
    path: '/profile/create/:task?',
    name: 'CreateProfile',
    components: {
      default: CreateProfile,
      header: HeaderComponent,
    },
    meta: { requiresAuth: true, activeScene: SCENES_KEYS.MainMapScene },
  },
  {
    path: '/profile/overview/:id?/:user?/:task?',
    name: 'ProfileOverview',
    components: {
      default: ProfileOverview,
      header: HeaderComponent,
    },
    meta: { requiresAuth: true, activeScene: SCENES_KEYS.MainMapScene },
  },

  {
    path: '/profile/avatar/:task?',
    name: 'ProfileAvatars',
    components: {
      default: ProfileAvatars,
      header: HeaderComponent,
    },
    meta: { requiresAuth: true, activeScene: SCENES_KEYS.MainMapScene },
  },
  {
    path: '/profile/backgrounds/:task?',
    name: 'ProfileBackgrounds',
    components: {
      default: ProfileBackgrounds,
      header: HeaderComponent,
    },
    meta: { requiresAuth: true, activeScene: SCENES_KEYS.MainMapScene },
  },
  {
    path: '/dailyTask/:task?/:id?',
    name: 'DailyTask',
    components: {
      default: DailyTask,
      header: HeaderComponent,
    },
    meta: {
      requiresMechanic: MECHANIC_DAILY_QUESTS,
      requiresAuth: true,
      activeScene: SCENES_KEYS.MainMapScene,
    },
  },
  {
    path: '/lab/disciplines/:id?/:task?',
    name: 'LabDisciplines',
    components: {
      default: LabDisciplines,
      header: HeaderComponent,
    },
    meta: { requiresAuth: true, activeScene: SCENES_KEYS.MainMapScene },
  },
  {
    path: '/lab/economics/:task?',
    name: 'LabEconomics',
    components: {
      default: LabEconomics,
      header: HeaderComponent,
    },
    meta: { requiresAuth: true, activeScene: SCENES_KEYS.MainMapScene },
  },
  {
    path: '/lab/camps/:task?',
    name: 'LabCamps',
    components: {
      default: LabCamps,
      header: HeaderComponent,
    },
    meta: { requiresAuth: true, activeScene: SCENES_KEYS.MainMapScene },
  },
  {
    path: '/reports/:task?',
    name: 'Reports',
    components: {
      default: Reports,
      header: HeaderComponent,
    },
    meta: { requiresAuth: true, activeScene: SCENES_KEYS.MainMapScene },
  },
  {
    path: '/news/:task?',
    name: 'News',
    components: {
      default: News,
      header: HeaderComponent,
    },
    meta: { requiresAuth: true, activeScene: SCENES_KEYS.MainMapScene },
  },
  {
    path: '/news/message/:news_id?/:task?',
    name: 'NewsMessage',
    components: {
      default: NewsMessage,
      header: HeaderComponent,
    },
    meta: { requiresAuth: true, activeScene: SCENES_KEYS.MainMapScene },
  },
  {
    path: '/mail/received/:task?',
    name: 'MailReceived',
    components: {
      default: MailMessages,
      header: HeaderComponent,
    },
    meta: { requiresAuth: true, activeScene: SCENES_KEYS.MainMapScene },
  },
  {
    path: '/mail/sent/:task?',
    name: 'MailSent',
    components: {
      default: MailMessages,
      header: HeaderComponent,
    },
    meta: { requiresAuth: true, activeScene: SCENES_KEYS.MainMapScene },
  },
  {
    path: '/mail/blocked/:task?',
    name: 'MailBlocked',
    components: {
      default: MailBlocked,
      header: HeaderComponent,
    },
    meta: { requiresAuth: true, activeScene: SCENES_KEYS.MainMapScene },
  },
  {
    path: '/mail/new/:recipient?/:task?',
    name: 'MailNew',
    components: {
      default: MailNew,
      header: HeaderComponent,
    },
    meta: { requiresAuth: true, activeScene: SCENES_KEYS.MainMapScene },
  },
  {
    path: '/mail/detail/:id?/:task?',
    name: 'MailDetail',
    components: {
      default: MailDetail,
      header: HeaderComponent,
    },
    meta: { requiresAuth: true, activeScene: SCENES_KEYS.MainMapScene },
  },
  {
    path: '/seasons/:type/:task?',
    name: 'Seasons',
    components: {
      default: Seasons,
      header: HeaderComponent,
    },
    meta: { requiresAuth: true, activeScene: SCENES_KEYS.MainMapScene },
  },
  {
    path: '/seasons-end',
    name: 'SeasonsEnd',
    components: {
      default: SeasonsEnd,
      header: HeaderComponent,
    },
    meta: { requiresAuth: true },
  },
  ...eventRouterMobile,
  ...premiumRoutesMobile,
  // WEB: redirect to settings for specific PPS account deep links
  {
    path: '/new-mania-account-change-password',
    redirect: '/reset-form',
  },
  {
    path: '/new-mania-account-verified',
    redirect: '/account-verificator',
  },
  {
    path: '/new-mania-account-failed',
    redirect: '/settings/cross-account-linking-web',
  },
  // fallback for undefined paths
  {
    // catches all unmatched paths
    path: '/:catchAll(.*)',
    redirect: '/',
    meta: { requiresAuth: true, activeScene: SCENES_KEYS.MainMapScene },
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes: routes as RouteRecordRaw[],
})

router.beforeEach(
  async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
  ): Promise<boolean | RouteLocationRaw> => {
    const cookiesElement = document.getElementById('cmpbox')
    const mechanic: string | null = to.meta.requiresMechanic || null
    const requiresAuth: boolean | null = to.meta.requiresAuth || null

    if (to.query.redirectFrom === 'ClubsMap') {
      to.meta.activeScene = SCENES_KEYS.ClubsMapScene
    }

    const avatarCustomizationRoutes = [
      getRouteWebName('ProfileAvatars'),
      getRouteWebName('ProfileBackgrounds'),
    ]

    // check if currently on an avatar customization route (from),
    if (avatarCustomizationRoutes.includes(from.name?.toString())) {
      // and transitioning to a non-customization route (to)
      if (!avatarCustomizationRoutes.includes(to.name?.toString())) {
        // then clear the sessionStorage for avatar items
        sessionStorage.removeItem('idAvatar')
        sessionStorage.removeItem('idBg')
      }
    }

    if (cookiesElement) {
      if (to.meta.showCookies) {
        cookiesElement.style.visibility = 'visible'
      } else if (cookiesElement.style.visibility === 'visible') {
        cookiesElement.style.visibility = 'hidden'
      }
    }

    to.meta.isPremiumRoute = to.name?.toString().startsWith('Premium')

    if (requiresAuth !== null && mechanic !== null && mechanic == MECHANIC_EVENT) {
      return (
        authRouteGuard() && mechanicRouteGuard(mechanic) && (await eventRouteGuardPromise(to, from))
      )
    } else if (requiresAuth !== null && mechanic !== null) {
      return authRouteGuard() && mechanicRouteGuard(mechanic)
    } else if (requiresAuth !== null) {
      return authRouteGuard()
    } else if (mechanic !== null) {
      return mechanicRouteGuard(mechanic)
    } else {
      return true
    }
  },
)

export default router
