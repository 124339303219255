<template>
  <button
    :id="btnId"
    v-tippy="{
      theme: 'WSM',
      content: getTooltipContent,
      placement: 'top',
    }"
    :type="btnVariant"
    :data-cy="btnId"
    class="clickable-skew-element btn multi flexing app-multi-button1"
    :class="[
      'btn-' + getBtnType + btnTheme,
      'btn-size-' + btnSize,
      addClass,
      buttonInteractionStateClass,
      { 'pointer-events-none': loading },
    ]"
    :style="{ width: getSize }"
    @click="clickAction"
  >
    <aside
      class="btn-multi-left flexing revert-skew px-2"
      :style="{ width: customLeftWidth + 'rem' }"
    >
      <shrink-text
        v-if="btnText"
        :key="shrinkTextComponentKey"
        :text="btnText ? $filters.$formatNumber(btnText).toString() : ''"
        :font-size="buttonFontSize"
        container-class-list="flexing pt-1"
        :text-class-list="$isWsm ? 'font-bold uppercase' : 'uppercase font-medium'"
        :class="$isWsm ? getTextColor : undefined"
        :animated-overflow="true"
      />
      <div v-else>
        <slot name="leftPart" />
      </div>
    </aside>
    <aside
      ref="btnRightPart"
      class="btn-multi-right flexing"
      :style="{ width: customRightWidth + 'rem' }"
    >
      <span
        class="revert-skew flex h-full flexing font-bold"
        :class="
          !hasEnoughResources && isResourcesStatusColored
            ? 'text-texts-standard-danger'
            : 'text-texts-standard-default'
        "
      >
        <short-text
          :text="btnCount ? $filters.$formatNumber(btnCount).toString() : ''"
          :first-cut="shortCutValue"
          class="pt-1"
        />
        <slot name="rightPart" />
        <app-main-icon v-if="buttonType && isIcon" :icon-size="48" :icon-name="getIconName" />
      </span>
    </aside>
    <div v-if="locked" class="btn-locked-overlay w-full h-full absolute top-0 left-0 flexing">
      <app-icon icon-name="lock-sm" class="btn-locked-overlay-icon revert-skew" />
    </div>
    <popup-window
      v-if="noGemsPopup && !hideActionPopup"
      popup-type="info"
      :popup-title="$t('common.notEnoughGemsTitle')"
      @close="noGemsPopup = false"
    >
      <div class="no-gems-popup-content flexing">
        <p class="text-texts-standard-default text-36">
          {{ $t('common.notEnoughGems') }}
        </p>
      </div>
      <app-button
        btn-id="no-enought-gems-btn"
        btn-size="xl"
        btn-type="credit"
        :btn-text="$t('map.premiumName')"
        @click="goToPremium"
      />
    </popup-window>
    <popup-window
      v-if="showPopup && !hideActionPopup"
      popup-type="confirm"
      :popup-data="popupData"
      @close="showPopup = false"
      @action="useGems"
    >
      <slot name="popupSlot" />
    </popup-window>
    <parameter-popup
      v-if="isParameterPopupOpen"
      :parameter="buttonType"
      @close="isParameterPopupOpen = false"
    />
    <div
      v-if="loading"
      class="absolute revert-skew loading-btn top-0 right-0 w-full h-full"
      style="transform: skewX(0deg)"
    >
      <component-loading width="100%" :is-loading="true" />
    </div>
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'pinia'
import { createRandomId, toSnakeCase } from '@/helpers'
import ParameterPopup from '@/components/Popup/Parameter/ParameterPopup.vue'
import PopupWindow from '@/components/Popup/PopupWindow.vue'
import ShortText from '@/components/ShortText.vue'
import {
  CONFIRM_TYPE,
  CREDIT_OPERATION,
  ENERGY,
  ENERGY_DRINK,
  GEMS,
  MONEY,
  PRESTIGE,
  REAL_CURRENCY,
  RESEARCH_POINTS,
  STARTS,
  UNIVERSAL_TRAINING_POINTS,
  WILD_CARD,
} from '@/globalVariables'
import { useClubStore } from '@/store/pinia/clubs/clubStore'
import { useConsumablesStore } from '@/store/pinia/consumablesStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import ShrinkText from '@/components/ShrinkText.vue'

import type { PropType } from 'vue'
import type { Nullable } from '@/interfaces/utils'

interface MultiButtonPopupData {
  btnId: string
  btnText: string
  btnType: string
  text: string
  title: string
  btnValue?: string
}

type MultiButtonType = 'button' | 'submit' | 'reset'

interface ComponentData {
  icon: string
  leftWidth: number
  rightWidth: number
  noGemsPopup: boolean
  showPopup: boolean
  isParameterPopupOpen: boolean
  creditConf: boolean
  btnWithoutTooltip: string[]
  darkText: string[]
  shrinkTextComponentKey: number
  rightPartObserver: MutationObserver
}

export default defineComponent({
  name: 'AppMultiButton',
  components: {
    PopupWindow,
    ShortText,
    ParameterPopup,
    ShrinkText,
  },

  props: {
    btnId: {
      // id pre button. Kazdy button by mal mat id aby v pripade pisania testov sa dali lahsie touch - ovat
      type: String,
      default: createRandomId('button'),
    },
    btnType: {
      type: String,
      default: '',
      validator(value: string) {
        // všetky názvy by bolo najlepšie vytiahnut ako konštanty z gameConstants
        // zakladné typy butonov podla typu parametra ktory konzumuju
        return [
          REAL_CURRENCY,
          CREDIT_OPERATION,
          MONEY,
          ENERGY,
          STARTS,
          ENERGY_DRINK,
          WILD_CARD,
          PRESTIGE,
          UNIVERSAL_TRAINING_POINTS,
          '',
        ].includes(value)
      },
    },
    btnTheme: {
      // ak chceme pouzit nieco čo je mimo standard napriklad modry button na energy pouzijeme theme btnTheme: primary
      type: String,
      default: '',
      validator(value: string): boolean {
        return [
          'primary',
          'secondary',
          'credit',
          'danger',
          'inactive',
          'confirm',
          'position',
          'dark',
          '',
        ].includes(value)
      },
    },
    btnVariant: {
      // v prípade ze chceme iny button type
      type: String as PropType<MultiButtonType>,
      default: 'button',
      validator(value: MultiButtonType): boolean {
        return ['button', 'submit', 'reset'].includes(value)
      },
    },

    btnSize: {
      // btn size hovorí len o heaight buttonu nie o width
      type: String,
      default: 'md',
      validator(value: string): boolean {
        return ['sm', 'md', 'xl'].includes(value)
      },
    },
    addClass: {
      // custom add class
      type: String,
      default: '',
    },
    customWidth: {
      // ak chceme zadat sirku celmeu buttonu ale vyhouje nam pomer stran ako je zadany defaultne v css
      type: Number,
      default: null,
    },
    // TODO po uspesnom nasadeni ShrinkText customLeftWidth & customRightWidth odstranit
    customLeftWidth: {
      // upravy šírky lavej časti buttonu
      type: Number,
      default: null,
    },
    customRightWidth: {
      // upravy širky pravej časti buttonu
      type: Number,
      default: null,
    },
    disabled: {
      // znefunkčnenie buttonu
      type: Boolean,
      default: false,
    },
    locked: {
      // stav kedy chceme button locknut (pridá ikonu zámku)
      type: Boolean,
      default: false,
    },
    btnText: {
      // text ktory chceme vypísať do buttonu
      type: String,
      default: '',
    },
    btnCount: {
      // hodnota ktorú po kliku minieme
      type: Number,
      default: 0,
    },
    popupData: {
      // v prípade ze má button confirm okno tak mu vieme do tohto confirm okna poslat content
      type: Object as PropType<Nullable<MultiButtonPopupData>>,
      default: () => null,
    },
    isIcon: {
      // v prípade žeby sme chceli vypnúť buttonu ikonku
      type: Boolean,
      default: true,
    },
    isCustomAction: {
      // ak chceme buttonu vypnut zakladnu funkcionalitu po kliku
      type: Boolean,
      default: false,
    },
    shortCutValue: {
      // ak chcem zmensit pismo value
      type: Number,
      default: 100,
    },
    hideActionPopup: {
      // ak potrebujem UI btn bez zobrazovania popupov
      type: Boolean,
      default: false,
    },
    isResourcesStatusColored: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['enoughResourcesBtnClick', 'redirectClose', 'action'],
  data(): ComponentData {
    return {
      icon: '',
      leftWidth: 0,
      rightWidth: 0,
      noGemsPopup: false,
      showPopup: false,
      isParameterPopupOpen: false,
      creditConf: false,
      btnWithoutTooltip: [CREDIT_OPERATION, ENERGY, STARTS], // pri tychto buttonoch nechceme tootlip v prípade nedostatku zdrojov
      darkText: ['primary', 'secondary'],
      shrinkTextComponentKey: 0,
      rightPartObserver: null,
    }
  },
  computed: {
    ...mapState(useConsumablesStore, ['getConsumable']),
    ...mapState(useClubStore, {
      prestige: 'getPrestige',
    }),
    ...mapState(useResponseTaskStore, {
      gems: 'getGems',
      money: 'getMoney',
      energy: 'getEnergy',
      starts: 'getStarts',
      researchPoints: 'getResearchPoints',
      universalTrainingPoints: 'getUniversalTrainingPoints',
    }),
    getBtnType(): string {
      // vyberieme aka téma ma byť pre jednotlive typy buttonov
      switch (this.buttonType) {
        case CREDIT_OPERATION:
          return CREDIT_OPERATION
        case MONEY:
        case WILD_CARD:
        case ENERGY_DRINK:
        case UNIVERSAL_TRAINING_POINTS:
          return CONFIRM_TYPE
        case ENERGY:
        case PRESTIGE:
          return 'secondary'
        case STARTS:
        case RESEARCH_POINTS:
          return 'primary'
      }
      return ''
    },

    getSize(): string | null {
      // nadstavime velkost
      if (this.customLeftWidth && this.customRightWidth)
        return this.customLeftWidth + this.customRightWidth + 'rem'
      else if (this.customWidth) return this.customWidth + 'rem'
      else return null
    },

    getResources(): number {
      // nadstav koľko mám zdrojov pre konkretny type ktorý chceme použiť
      if (!this.btnType) return 0
      switch (this.btnType) {
        case CREDIT_OPERATION:
          return this.gems.value
        case MONEY:
          return this.money.value
        case ENERGY:
          return this.energy.value
        case STARTS:
          return this.starts.value
        case WILD_CARD:
        case ENERGY_DRINK:
          return this.getConsumable(toSnakeCase(this.buttonType)).value
        case PRESTIGE:
          return this.prestige
        case RESEARCH_POINTS:
          return this.researchPoints.value
        case UNIVERSAL_TRAINING_POINTS:
          return this.universalTrainingPoints.value
      }
      return 0
    },

    hasEnoughResources(): boolean {
      // kontrola či mám dostatok zdrojov ktoré chcem používať pre danú funkciu buttonu
      if (this.btnType === REAL_CURRENCY) return true
      return this.btnType.length > 0 && this.getResources >= this.btnCount
    },

    getTextColor(): string {
      // farba forntov v buttonoch
      if (this.darkText.includes(this.btnTheme) || this.darkText.includes(this.getBtnType))
        return 'text-texts-standard-dark'
      return 'text-texts-standard-default'
    },

    getTooltipContent(): string {
      // nadstavit kedy automaticky zobrazovať btn tooltip
      if (!this.hasEnoughResources && !this.btnWithoutTooltip.includes(this.buttonType))
        return this.setTooltipText
      return ''
    },

    setTooltipText(): string {
      // tooltip texty ak nemám dostatok zdrojov
      switch (this.buttonType) {
        case MONEY:
          return this.$t('research.dontMoney')
        case WILD_CARD:
          return this.$t('common.notEnoughWildCards')
        case ENERGY_DRINK:
          return this.$t('common.notEnoughEnergyDrinks')
        case PRESTIGE:
          return this.$t('common.notEnoughPrestige')
        case RESEARCH_POINTS:
          return this.$t('common.notEnoughResearchPoints')
        case UNIVERSAL_TRAINING_POINTS:
          return this.$t('trainingHall.notEnoughTpInfo')
      }
      return ''
    },

    getIconName(): string {
      // konvertovanie nazvov ikon ktore sa inak volaju na BE a inak v CSS
      if (this.buttonType === CREDIT_OPERATION) return GEMS
      return this.toSnakeCase(this.buttonType)
    },

    buttonType(): string {
      // ostrenie aby btn types bolivzdy pisane malym pismom
      return this.btnType.toLowerCase()
    },

    buttonFontSize(): number {
      switch (this.btnSize) {
        case 'sm':
          return 36
        case 'md':
          return 36
        case 'xl':
          return 36
        default:
          return 36
      }
    },
    buttonInteractionStateClass(): string {
      if (this.disabled) {
        return !this.locked ? 'disabled pointer-events-none' : 'cursor-default'
      }
      return 'active'
    },
  },
  mounted(): void {
    const rightPartObserver = new MutationObserver(this.dispatchRightPartChanges)
    rightPartObserver.observe(this.$refs.btnRightPart, {
      childList: true,
      subtree: true,
    })
    this.rightPartObserver = rightPartObserver
  },
  beforeUnmount(): void {
    this.rightPartObserver.disconnect()
  },
  methods: {
    toSnakeCase,
    clickAction(): void {
      // akcia po kliku. Kontrolujeme či nám nemá button zobraziť popup poprípade presmerovať na inú adresu
      if (this.disabled || this.isCustomAction) return

      if (this.buttonType === 'credit') {
        if (!this.hasEnoughResources) this.noGemsPopup = true
        else {
          this.$emit('enoughResourcesBtnClick', true)
          this.showPopup = true
        }
      } else if (
        (this.buttonType === 'energy' || this.buttonType === 'starts') &&
        !this.hasEnoughResources
      ) {
        this.isParameterPopupOpen = true
      } else {
        this.$emit('enoughResourcesBtnClick', true)
      }
    },

    goToPremium(): void {
      // redirect do premia v prípade že nemám gemy a chcem si ich kúpiť
      this.noGemsPopup = false
      this.$emit('redirectClose', true)
      this.$router.push({ name: this.$getWebView('PremiumGems') })
    },

    useGems(e: Event): void {
      // ak v confirm okne potvrdím, že chcem spraviť kreditnú transakciu
      this.showPopup = false
      this.$emit('action', e)
    },
    forceRerender(): void {
      // SHRINK-TEXT: Changing the key causes the component to be rerendered
      this.shrinkTextComponentKey += 1
    },
    dispatchRightPartChanges(): void {
      // *must have – dynamic data updates
      this.forceRerender()
    },
  },
})
</script>

<style lang="scss" scoped>
// TODO: OPRAVIT DO JEDNEHO STYLEU (TIETO KOPONENTY MOZU IST DO ZVLAST REPA)
button {
  &.btn {
    transform: $skewX-value;
    outline: none;

    @if $isWsm {
      font-style: italic;
      box-shadow:
        inset 0.188rem 0 0 0 rgba(255, 255, 255, 0.4),
        inset -0.188rem 0 0 0 rgba(255, 255, 255, 0.4),
        inset 0 -0.125rem 0.063rem 0 rgba(255, 255, 255, 0.4),
        inset 0 0.25rem 0 0 rgba(255, 255, 255, 0.64);
    }

    .btn-icon {
      margin: 0 0.313rem;
    }

    &:active {
      transform: $skewX-value scale(0.95);
    }

    & > div,
    section {
      transform: $skewX-revert-value; // opacny skew pre childs
    }

    section,
    .btn-count {
      pointer-events: none;
    }

    &-primary {
      @if $isWsm {
        color: theme('colors.texts.buttons.bright');
        background-image: linear-gradient(
          80deg,
          rgb(215, 237, 250) 0%,
          rgb(189, 225, 247) 49%,
          rgb(137, 187, 215) 100%
        );
      }

      @if $isSsm {
        color: theme('colors.texts.buttons.bright');
        background-image: linear-gradient(to right, #b9b9dd, #eeedf6);
        border: solid 0.188rem #f6f6f9;
      }

      &.inactive {
        &::after {
          content: '';
          width: 100%;
          height: 100%;
          background: rgba($color: #89bbd7, $alpha: 0.4);
          position: absolute;
          left: 0;
          top: 0;
        }

        &:active {
          transform: $skewX-value !important;
        }
      }
    }

    &-secondary {
      @if $isWsm {
        color: theme('colors.texts.buttons.bright');
        background-image: linear-gradient(
          80deg,
          rgb(255, 239, 132) 0%,
          rgb(251, 219, 78) 33%,
          rgb(246, 199, 23) 100%
        );
      }

      @if $isSsm {
        color: theme('colors.texts.buttons.bright');
        background-image: linear-gradient(to right, #ffaa3c, #ffe719);
        border: solid 0.188rem #ffdc96;
      }
    }

    &-credit {
      @if $isWsm {
        color: theme('colors.texts.buttons.darker');
        background-image: linear-gradient(
          80deg,
          rgb(199, 111, 224) 0%,
          rgb(159, 59, 210) 100%,
          rgb(159, 72, 172) 100%
        );
      }

      @if $isSsm {
        color: theme('colors.texts.buttons.darker');
        background-image: linear-gradient(to right, #861cd4, #cc5df9);
        border: solid 0.188rem #d47cfd;
      }
    }

    &-confirm {
      @if $isWsm {
        color: theme('colors.texts.buttons.darker');
        background-image: linear-gradient(80deg, rgb(73, 172, 46) 0%, rgb(22, 148, 16) 100%);
      }

      @if $isSsm {
        color: theme('colors.texts.buttons.bright');
        background-image: linear-gradient(to right, #01e3cd, #5fffc8);
        border: solid 0.188rem #81ffee;
      }
    }

    &-danger {
      background-image: linear-gradient(
        80deg,
        rgb(159, 72, 172) 0%,
        rgb(255, 87, 62) 0%,
        rgb(234, 44, 20) 100%
      );
      color: theme('colors.texts.buttons.darker');
    }

    &-inactive {
      background-image: linear-gradient(
        80deg,
        rgba(71, 135, 180, 0.6) 0%,
        rgba(57, 107, 142, 0.6) 33%,
        rgba(43, 78, 103, 0.6) 100%
      );
      border-color: rgb(82, 142, 191);
      border-width: 0.125rem;
      box-shadow: none;
      color: theme('colors.texts.buttons.darkerInactive');
    }

    &-position {
      background-color: #0f3251;
      border-image-source: linear-gradient(to top, #fff284, #f6c717);
      border-image-slice: 1;
      border-width: 0.125rem;
      box-shadow:
        inset -7px 0px 21px 0 rgba(51, 123, 214, 0.19),
        inset -0.3px -3px 40px 0 rgba(6, 34, 55, 0.48);
    }

    &-dark {
      color: theme('colors.texts.standard.default');
      background-image: linear-gradient(10deg, rgba(67, 134, 182, 1) 0%, rgba(48, 94, 127, 1) 100%);
    }
  }
}

button {
  &.multi {
    position: relative;

    .btn-text {
      width: 63%;
      position: absolute;
      left: 0;
    }

    .btn-count {
      width: 37%;
      height: 89%;
      margin-top: 0.125rem;
      position: absolute;
      right: 0;

      .center-text-vertically {
        height: 100%;
      }
    }

    .btn-locked-overlay {
      background: rgba(0, 0, 0, 0.6);
      transform: none;
      pointer-events: none;

      &-icon {
        left: 50%;
      }
    }
  }

  .loading-btn {
    background: rgba($color: #254257, $alpha: 0.8);
  }
}

.multi {
  &.app-multi-button--timer,
  &.app-multi-button--tracker {
    .btn-multi-right {
      padding: 0 1.5rem;
    }
  }

  .btn-multi-left {
    flex: 1;
    position: relative;
    overflow: hidden;
    height: 92%;
    @if $isSsm {
      height: 100%;
    }
  }

  .btn-multi-right {
    position: relative;
    flex: 0 0 auto;
    justify-content: center;
    align-items: center;
    padding: 0 1rem 0 1.5rem;
    width: auto;

    @if $isWsm {
      height: 92%;
      margin-right: 0.188rem;
    }

    @if $isSsm {
      height: 100%;
      margin-right: 0;
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;

      @if $isWsm {
        top: 0.094rem;
      }

      @if $isSsm {
        top: 0;
      }
    }
  }

  &.btn-credit {
    .btn-multi-right::before {
      content: '';
      @if $isWsm {
        background: #591488;
      }
      @if $isSsm {
        background: #6e09b2;
      }
    }
  }

  &.btn-primary {
    .btn-multi-right::before {
      content: '';
      background: #446c84;
    }
  }

  &.btn-secondary {
    .btn-multi-right::before {
      content: '';

      @if $isSsm {
        background: #cb8a33;
      }

      @if $isWsm {
        background: #887528;
      }
    }
  }

  &.btn-confirm {
    .btn-multi-right::before {
      content: '';
      @if $isWsm {
        background: #19600b;
      }
      @if $isSsm {
        background: #119780;
      }
    }
  }

  &.btn-position {
    .btn-multi-right::before {
      content: '';
      background: linear-gradient(to top, #d29520, #ffe98e);
    }
  }

  &.btn-dark {
    .btn-multi-right::before {
      content: '';
      background: #1b3c54;
    }
  }
}

.no-gems-popup-content {
  width: 95%;
  height: 14rem;
  margin: 0 auto;
}

#no-enought-gems-btn {
  margin: 0 auto;
}
</style>
