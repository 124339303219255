<template>
  <teleport to="#app">
    <transition name="show" :class="!isForcedOff ? 'transition--base' : 'transition--forced'">
      <div
        v-if="
          (progress || (progress && progress.progressGrandPrize)) &&
          !actualStageData &&
          !isEventSessionModal
        "
        :id="'progress-message-' + messageId"
        :data-cy="'progress-message-' + messageId"
        class="progress-message"
        :class="{
          'progress-message-mobile': $isMobile(),
          'progress-message--extended': isFooterActive,
        }"
        @click="hideProgress"
      >
        <div class="progress-message-container">
          <header class="flexing">
            <h2 class="text-texts-standard-additional text-40 uppercase">
              {{ progressMessageTitle }}
            </h2>
          </header>
          <main class="flexing flex-col">
            <div class="progress-message-text">
              <p
                v-if="progress.type !== 'adRewardsClaimed'"
                class="text-texts-standard-default text-32 uppercase"
              >
                {{
                  progress.rewards?.length
                    ? $t('common.yourRewards')
                    : progress.progressGrandPrize
                      ? $t(GP_PROGRESS_MAPPING[progress.progressGrandPrize.name])
                      : ''
                }}
              </p>
            </div>
            <div class="progress-message-content">
              <div v-if="progress.rewards?.length" class="flexing">
                <rewards-component
                  reward-id="progress-messages-rewards"
                  :reward-data="rewards || []"
                  :icon-size="56"
                  :vertical="isVerticalRewardsRequired"
                  :is-highlighted-reward="true"
                />
              </div>
              <div v-else-if="progress.progressGrandPrize" class="pm-progress-bar">
                <app-progress-bar
                  class="progressbar"
                  :bar-width="32.313"
                  :bar-height="1.625"
                  :text-size="2.25"
                  :actual="progress.progressGrandPrize.value"
                  :goal="progress.progressGrandPrize.target_value"
                  :counts="true"
                />
                <app-main-icon
                  :icon-size="80"
                  :icon-name="'gp-' + progress.progressGrandPrize.target_gp"
                  :tooltip="
                    $t('grandPrize.tier' + capitalize(progress.progressGrandPrize.target_gp))
                  "
                />
              </div>
            </div>
          </main>
        </div>
        <footer v-if="isFooterActive">
          <div v-if="progress.training" class="pm-extened-left">
            <aside class="flexing">
              <h2 class="text-texts-standard-important text-40 font-bold uppercase">
                {{ $translateDiscipline(progress.training.discipline_id) }}
              </h2>
              <p class="text-texts-standard-default text-28 non-italic uppercase">
                {{ $t('trainingHall.trainingProgress') }}
              </p>
            </aside>
            <app-progress-bar
              class="progressbar"
              :bar-width="18.938"
              :bar-height="0.813"
              :text-size="2.25"
              :actual="progress.training.tp_value"
              :goal="progress.training.tp_target_value"
              :counts-actual="progress.training.attribute_value"
              :counts="true"
            />
          </div>
          <div v-if="progress.progressGrandPrize" class="pm-extened-right">
            <aside class="flexing">
              <h2 class="text-texts-standard-important text-40 font-bold uppercase">
                {{ GRAND_PRIZE }}
              </h2>
              <p class="text-texts-standard-default text-28 non-italic uppercase">
                {{ $t(GP_PROGRESS_MAPPING[progress.progressGrandPrize.name]) }}
              </p>
            </aside>
            <app-progress-bar
              class="progressbar"
              :bar-width="18.938"
              :bar-height="0.813"
              :text-size="2.25"
              :actual="progress.progressGrandPrize.value"
              :goal="progress.progressGrandPrize.target_value"
              :counts="true"
              :show-bottom-status="false"
            />
            <app-main-icon
              class="pm-extend-icon"
              :icon-size="80"
              :icon-name="'gp-' + progress.progressGrandPrize.target_gp"
              :tooltip="translateGrandPrizeIcon(progress.progressGrandPrize.target_gp)"
            />
          </div>
        </footer>
      </div>
    </transition>
  </teleport>
</template>

<script lang="ts">
import RewardsComponent from '@/components/Rewards.vue'
import { GRAND_PRIZE, pathImages } from '@/globalVariables'
import { capitalize, createRandomId, formatResult, getArenaDisciplineId } from '@/helpers'
import type Reward from '@/interfaces/Reward'
import { useTaskChainStore } from '@/store/pinia/events/taskChainStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
const GP_PROGRESS_MAPPING = {
  training_experiences: 'common.experiences',
  arena_ranking_points: 'rankingsGroup.rankingPoints',
  championship_points: 'common.championshipPoints',
}

const TITLE_MAPPING = {
  matchSimulation: '', // TODO zrevidovat a doplnit podla specky
  trainingSimulation: 'trainingHall.trainingCompleted',
  eventRewardsClaimed: 'common.rewardsClaimed',
  adRewardsClaimed: 'common.yourRewards',
}

interface ComponentData {
  GRAND_PRIZE: typeof GRAND_PRIZE
  GP_PROGRESS_MAPPING: typeof GP_PROGRESS_MAPPING
  TITLE_MAPPING: typeof TITLE_MAPPING
  showPopup: boolean
  pathImages: typeof pathImages
  isForcedOff: boolean
}

export default defineComponent({
  name: 'ProgressMessages',
  components: {
    RewardsComponent,
  },
  props: {
    messageId: {
      type: String,
      default: createRandomId('randomId'),
    },
  },
  data(): ComponentData {
    return {
      GRAND_PRIZE,
      GP_PROGRESS_MAPPING,
      TITLE_MAPPING,
      showPopup: false,
      pathImages,
      isForcedOff: false,
    }
  },
  computed: {
    ...mapState(useTutorialStore, {
      actualStageData: 'getActualStage',
    }),
    ...mapState(useResponseTaskStore, {
      progress: 'getProgress',
    }),
    progressMessageTitle(): string {
      return this.progress.type === 'matchSimulation'
        ? this.$replacePlaceholder(this.$t('common.placement'), '%s', this.progress.position) +
            ` (${formatResult(
              this.progress.result,
              this.progress.disciplineId,
              this.progress.fail_status,
            )})`
        : TITLE_MAPPING[this.progress.type]
          ? this.$t(TITLE_MAPPING[this.progress.type])
          : this.progress.progressGrandPrize
            ? GRAND_PRIZE
            : ''
    },
    rewards(): Reward[] {
      if (!this.progress.rewards) {
        return []
      }
      return this.progress.rewards.map((reward: Reward): Reward => {
        return {
          type: reward.type,
          value: reward.value,
          name: reward?.name ?? '',
          eventCurrency: reward?.eventCurrency ?? false,
        }
      })
    },
    isVerticalRewardsRequired(): boolean {
      const casesVerticalRewards = ['adRewardsClaimed']
      return casesVerticalRewards.includes(this.progress.type) && this.progress.rewards.length === 1
    },
    isEventSessionModal(): boolean {
      return !!this.progress?.session_modal
    },
    isFooterActive(): boolean {
      return !!this.progress.training || !!this.progress.progressGrandPrize
    },
  },
  watch: {
    progress(): void {
      if (this.isEventSessionModal) {
        // Sem pridavajte aj ine eventove session modals.
        if (this.progress.session_modal === 'taskchain' && this.progress.last_in_chain)
          this.setEventTaskchainSessionModalData(this.progress)
      }
    },
  },
  created(): void {
    window.addEventListener('click', async (e: MouseEvent): Promise<void> => {
      if (this.progress) {
        if (!this.$el.contains(e.target)) {
          // force quit progress message even on clicking outside the component
          this.isForcedOff = true
          await this.$nextTick()
          this.hideProgress()
          this.isForcedOff = false
        }
      }
    })
  },
  methods: {
    ...mapActions(useTaskChainStore, {
      setEventTaskchainSessionModalData: 'setTaskChainSessionModalData',
    }),
    ...mapActions(useResponseTaskStore, { hideProgress: 'hideProgress' }),
    formatResult,
    getArenaDisciplineId,
    capitalize,
    translateGrandPrizeIcon(rewardRarity: string): string {
      return this.$replacePlaceholder(
        this.$t('grandPrize.tier' + this.capitalize(rewardRarity)),
        '{grandprize}',
        GRAND_PRIZE,
      )
    },
  },
})
</script>

<style lang="scss" scoped>
.transition {
  &--forced {
    &.show-leave-active {
      transition-duration: 0s;
      transition-delay: 0s;
    }
  }
}

.progress-message {
  width: 100%;
  height: 16.938rem;
  z-index: 10;
  position: fixed;
  left: 50%;
  top: 43%;
  transform: translate(-50%, -43%);
  box-shadow: 0px 0.313rem 0.813rem 0 #272727;

  @if $isWsm {
    background-image: linear-gradient(
      to right,
      transparent,
      #154e79 10%,
      #0f2a4e 40%,
      #0b1e37 50%,
      #0f2a4e 60%,
      #154e79 90%,
      transparent
    );
  }

  @if $isSsm {
    background-image: linear-gradient(to right, #2f3457, #2f3457, #4f4d79, #2f3457, #2f3457);
  }

  &--extended {
    // footer with a custom gradient cut,
    // the gradient cannot be applied to the entire height of progress message
    background-image: none;

    footer {
      position: relative;
      // Chrome: fix gap between content and footer
      top: -0.063rem;
      width: 100%;
      height: 12.813rem;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      background-image: url($path-components + 'progressMessage/BG_BOTTOM_CONTENT.avif');
      background-size: cover;
      background-repeat: no-repeat;

      .pm-extened-left,
      .pm-extened-right {
        width: 30rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        position: relative;
        top: -1rem;

        aside {
          line-height: 2.5rem;
          position: relative;
          top: -1rem;
          flex-direction: column;
        }

        .pm-extend-progress {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
        }

        .pm-extend-icon {
          position: absolute;
          right: -6rem;
          top: 3rem;
        }
      }
    }
  }

  &-container {
    @if $isWsm {
      background-image: linear-gradient(to bottom, #154e79, #0b1e37);
    }

    @if $isSsm {
      background-image: linear-gradient(to bottom, #4f4d79, #21273c);
    }

    // constant gradient for masking
    mask-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.5) 5%,
      rgba(0, 0, 0, 1) 10%,
      rgba(0, 0, 0, 1) 90%,
      rgba(0, 0, 0, 0.5) 95%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  header {
    width: 100%;
    height: 5rem;
    background-image: url($path-components + 'progressMessage/BG_TOP_CONTENT.avif');
    background-size: cover;
    background-repeat: no-repeat;
  }

  main {
    width: 100%;
    min-height: 8.125rem;

    .progress-message-text {
      margin: 0.938rem auto 0;
      position: relative;

      &:before {
        content: '';
        width: 100%;
        height: 0.125rem;
        background: linear-gradient(
          to right,
          rgba(251, 227, 110, 0) 0%,
          #154e77 12%,
          #fff 50%,
          #154e79 88%,
          rgba(251, 227, 110, 0) 100%
        );
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }

    .progress-message-content {
      display: flex;
      margin: 1rem auto;
      height: 60%;

      .pm-progress-bar {
        display: flex;
        align-items: center;
        position: relative;
        min-width: 45rem;
        justify-content: space-between;
        margin-left: 11rem;
      }

      .progress-message-reward {
        margin: 0 0.5rem;
      }
    }
  }
}

.progress-message-mobile {
  width: 100vw;
  margin: 0 auto;
}
</style>
