import { OfferType } from '@/interfaces/events/Offers'
import { PassType } from '@/interfaces/events/Passes'

interface EventCustomProps {
  offers: OfferType[]
  passes: PassType[]
  routes: {
    event: {
      progress: 'EventsTaskchain' | 'EventsOverview'
      collection: 'EventsMilestones' | 'EventsOverview'
    }
    passes: {
      // progress: 'EventsPasses'
      'progress.query.pass'?: PassType
      // collection: 'EventsPasses'
      'collection.query.pass'?: PassType
    }
    premium: {
      progress: 'PremiumEventOffers' | 'PremiumEventPasses'
      'progress.query.pass'?: PassType
      collection: 'PremiumEventOffers' | 'PremiumEventPasses'
      'collection.query.pass'?: PassType
    }
  }
}

export const getEventAssetsDirectory = (event_type: string): string => {
  switch (event_type) {
    case 'challenge_cup': {
      return 'challenge-cup'
    }
    case 'frozen': {
      return 'frozen-championship'
    }
    case 'discipline': {
      return 'discipline-event'
    }
    case 'board_games_event': {
      return 'board-games-event'
    }
    default: {
      return event_type
    }
  }
}

export const getEventCustomProps = (event_type: string): EventCustomProps => {
  switch (event_type) {
    case 'challenge_cup':
    case 'frozen': {
      return {
        offers: [OfferType.Offer],
        passes: [PassType.Event, PassType.Champion],
        routes: {
          event: {
            progress: 'EventsTaskchain',
            collection: 'EventsMilestones',
          },
          passes: {
            // progress: 'EventsPasses',
            'progress.query.pass': PassType.Event,
            // collection: 'EventsPasses',
            'collection.query.pass': PassType.Champion,
          },
          premium: {
            progress: 'PremiumEventOffers',
            'progress.query.pass': undefined,
            collection: 'PremiumEventPasses',
            'collection.query.pass': PassType.Champion,
          },
        },
      }
    }
    case 'discipline': {
      return {
        offers: [OfferType.Offer],
        passes: [PassType.Master],
        routes: {
          event: {
            progress: 'EventsOverview',
            collection: 'EventsOverview',
          },
          passes: {
            // progress: 'EventsPasses',
            'progress.query.pass': PassType.Master,
            // collection: 'EventsPasses',
            'collection.query.pass': PassType.Master,
          },
          premium: {
            progress: 'PremiumEventOffers',
            'progress.query.pass': undefined,
            collection: 'PremiumEventOffers',
            'collection.query.pass': undefined,
          },
        },
      }
    }
  }
}
