<template>
  <div class="payment-message-wrapper">
    <div class="payment-message">
      <header class="payment-message-title font-bold flex flex-col items-center justify-end">
        <h2 class="text-60 uppercase not-italic">
          {{ $t('premiumGroup.paymentSuccess') }}
        </h2>
      </header>
      <main
        class="payment-message-main flex flex-col items-center"
        :class="{
          'is-game-pass': isGamePass,
          'is-event-pass': isEventPass,
          'is-champion-pass': isChampionPass,
          'is-master-pass': isMasterPass,
          [`event-pass-${getEventPassMaxTier}`]: isEventPass && getEventPassMaxTier,
        }"
        :style="{
          height: isBoardGamesEventPass ? 'auto' : undefined,
        }"
      >
        <section class="payment-message-main-thanks">
          <p class="text-texts-standard-additional text-32">
            {{ $t('premiumGroup.thanks') }}
          </p>
        </section>
        <section class="payment-message-main-name">
          <h2 class="text-texts-standard-important text-40 uppercase font-bold">
            <template v-if="isGamePass">
              {{ $t(`gamePass.gamePass${gamePassSuffix}`) }}
            </template>
            <template v-if="isPiggyBank">
              {{ $t('piggyBank.piggyBank') }}
            </template>
            <template v-if="isEventPayment && !isEventOffer">
              {{ eventPaymentMessageTitle }}
            </template>
            <template v-else>
              {{ $t('premiumGroup.' + paymentMessage?.pack) }}
            </template>
          </h2>
        </section>
        <div
          class="payment-message-main-text relative"
          :class="{
            'no-underline': isBoardGamesEventPass,
          }"
        >
          <p class="text-texts-standard-default uppercase text-36 pb-2">
            <template v-if="isGamePass">
              {{ $t('gamePass.premiumMilestonesAvailable') }}
            </template>
            <template v-else-if="isEventPayment && !isEventOffer && !isBoardGamesEventOffer">
              {{ eventPaymentMessageDescription }}
            </template>
            <template v-else>
              {{ $t('premiumGroup.addedItems') }}
            </template>
          </p>
        </div>
        <div v-if="isGamePass">
          <img
            class="game-pass-rewards"
            :src="`${pathImages}premium/gamePass/paymentMessage/game-pass-rewards-img-${gamePassSuffix.toLowerCase()}.avif`"
          />
        </div>
        <div v-if="isEventPayment && !isEventOffer && !isBoardGamesEvent">
          <div v-if="isEventPass" class="event-pass-body">
            <event-pass1-body
              v-if="getEventPassMaxTier === 1"
              :is-payment-view="true"
              class="mt-5"
            />
            <event-pass3-detail-body
              v-else-if="getEventPassMaxTier === 3"
              :is-payment-view="true"
              :store-id="paymentMessage.pack"
              class="mt-8"
            />
          </div>
          <div v-if="isChampionPass">
            <img
              class="champion-pass-rewards"
              :src="`${pathImages}events/${eventAssetsDirectory}/passes/payment-champion-pass-img.webp`"
            />
          </div>
          <div v-if="isMasterPass">
            <img
              class="master-pass-rewards"
              :src="`${pathImages}events/${eventAssetsDirectory}/passes/payment-master-pass-img.webp?v=1`"
            />
          </div>
        </div>
        <div v-else-if="!isBoardGamesEventPass" class="payment-message-main-rewards flexing">
          <reward-box
            v-for="(reward, index) in paymentMessage?.rewards"
            :key="index"
            :reward-icon-size="72"
            :reward-icon-name="getRewardIconNameWithoutBonuxPostfix(reward)"
            :reward-number="reward.value"
            :reward-icon-rarity="reward.rarity ?? ''"
            :force-percentage="OFFER_BONUSES.includes(reward.name)"
            :is-pass="isRookiePass"
            :reward-data="reward || null"
            :sex="sex"
          />
        </div>
      </main>
      <footer class="payment-message-footer flexing">
        <app-button
          btn-id="payment-message-footer-btn"
          btn-size="md"
          btn-type="secondary"
          :btn-text="$t('button.continue')"
          @click="closeMessage"
        />
      </footer>
    </div>
  </div>
</template>

<script lang="ts">
import EventPass1Body from '@/components/Events/PassesComponents/EventPass1/EventPass1Body.vue'
import EventPass3DetailBody from '@/components/Events/PassesComponents/EventPass3/Detail/EventPass3DetailBody.vue'
import { OFFER_BONUSES, PROD, ROOKIE_PASS, pathImages } from '@/globalVariables'
import {
  getRewardIconNameWithoutBonuxPostfix,
  isEventOffer,
  isEventPass,
  isChampionPass,
  isMasterPass,
  isGamePass,
  isPiggyBank,
  isBoardGamesEventPass,
  isBoardGamesEventOffer,
  openBoardGamesEvent,
  sendToFlutter,
} from '@/helpers'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { usePremiumStore } from '@/store/pinia/premiumStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useUserStore } from '@/store/pinia/userStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

interface ComponentData {
  pathImages: typeof pathImages
  OFFER_BONUSES: typeof OFFER_BONUSES
}

export default defineComponent({
  name: 'PaymentStatusMessage',
  components: {
    EventPass1Body,
    EventPass3DetailBody,
  },
  data(): ComponentData {
    return {
      pathImages,
      OFFER_BONUSES,
    }
  },
  computed: {
    ...mapState(useResponseTaskStore, {
      paymentMessage: 'getPaymentMessage',
    }),
    ...mapState(useEventInfoStore, {
      eventName: 'getEventName',
      eventAssetsDirectory: 'getEventAssetsDirectory',
    }),
    ...mapState(usePremiumStore, {
      eventPass: 'getEventPass',
    }),
    ...mapState(useUserStore, {
      sex: 'getSex',
    }),
    isGamePass(): boolean {
      return isGamePass({ store_id: this.paymentMessage.pack })
    },
    isPiggyBank(): boolean {
      return isPiggyBank({ store_id: this.paymentMessage.pack })
    },
    isEventOffer(): boolean {
      return isEventOffer({ store_id: this.paymentMessage.pack })
    },
    isEventPass(): boolean {
      return isEventPass({ store_id: this.paymentMessage.pack })
    },
    getEventPassMaxTier(): number {
      return this.eventPass?.length ?? 0
    },
    isChampionPass(): boolean {
      return isChampionPass({ store_id: this.paymentMessage.pack })
    },
    isMasterPass(): boolean {
      return isMasterPass({ store_id: this.paymentMessage.pack })
    },
    isBoardGamesEventPass(): boolean {
      return isBoardGamesEventPass({ store_id: this.paymentMessage.pack })
    },
    isBoardGamesEventOffer(): boolean {
      return isBoardGamesEventOffer({ store_id: this.paymentMessage.pack })
    },
    isBoardGamesEvent(): boolean {
      return this.isBoardGamesEventPass || this.isBoardGamesEventOffer
    },
    isEventPayment(): boolean {
      return (
        this.isBoardGamesEvent ||
        this.isEventOffer ||
        this.isEventPass ||
        this.isChampionPass ||
        this.isMasterPass
      )
    },
    gamePassSuffix(): string {
      if (!this.isGamePass) return ''

      const suffix = this.paymentMessage.pack.split('_').pop()
      switch (suffix) {
        case '1':
          return 'Pro'
        case '2':
          return 'Elite'
        default:
          return ''
      }
    },
    eventPaymentMessageTitle(): string {
      if (this.isBoardGamesEventPass && this.isEventPass)
        return `${this.$t('boardGames.eventBoardGames')} - ${this.$t('event.eventpass')}`
      if (this.isBoardGamesEventPass && this.isChampionPass)
        return `${this.$t('boardGames.eventBoardGames')} - ${this.$t('event.championpass')}`
      if (this.isBoardGamesEventOffer) return this.$t(`boardGames.${this.paymentMessage?.pack}`)
      if (this.isEventPass) return `${this.eventName} - ${this.$t('event.eventpass')}`
      if (this.isChampionPass) return `${this.eventName} - ${this.$t('event.championpass')}`
      if (this.isMasterPass) return `${this.eventName} - ${this.$t('event.masterpass')}`
      return ''
    },
    eventPaymentMessageDescription(): string {
      if (this.isEventPass) return this.$t('event.paymentMessagePremiumEventPass')
      if (this.isChampionPass) return this.$t('event.paymentMessagePremiumChampionPass')
      if (this.isMasterPass) return this.$t('event.paymentMessagePremiumMasterPass')
      return ''
    },
    isRookiePass(): boolean {
      return this.paymentMessage.pack === ROOKIE_PASS
    },
    currentRouteName(): string {
      return this.$route.name.toString()
    },
  },
  async created(): Promise<void> {
    if (this.$isMobile()) {
      this.sendDataToFlutter()
    } else {
      if (import.meta.env.VITE_ENV === PROD) {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'payment_success',
          paymentInfo: {
            storeId: this.paymentMessage.af_store_id,
            revenue: this.paymentMessage.gtm_revenue,
            currency: this.paymentMessage.gtm_currency,
            quantity: this.paymentMessage.af_quantity,
          },
        })
      }
    }

    // Ak nemame eventove offre, potrebujeme ich nacitat, lebo event passove body-cko potrebuje z nich data.
    if (this.isEventPayment && !this.getEventPassMaxTier) {
      await this.loadEventOffers()
    }
  },
  methods: {
    ...mapActions(usePremiumStore, ['loadRightboxes', 'hideOfferDetail', 'loadEventOffers']),
    ...mapActions(useResponseTaskStore, ['loadNotifications', 'hidePaymentMessage']),
    getRewardIconNameWithoutBonuxPostfix,
    sendDataToFlutter(): void {
      // eslint-disable-next-line no-useless-escape
      sendToFlutter(
        '{\r\n  "event": "analyticsEvent",\r\n  "eventName": "af_purchase",\r\n  "af_revenue": ' +
          this.paymentMessage.af_revenue +
          ',\r\n  "af_currency": ' +
          `"${this.paymentMessage.af_currency}"` +
          ',\r\n  "af_quantity": ' +
          this.paymentMessage.af_quantity +
          '\r\n}',
      )
      sendToFlutter(
        '{\r\n  "event": "analyticsEvent",\r\n  "eventName": "af_basic_early_event",\r\n  "af_revenue": ' +
          this.paymentMessage.af_revenue +
          ',\r\n  "af_currency": ' +
          `"${this.paymentMessage.af_currency}"` +
          ',\r\n  "af_quantity": ' +
          this.paymentMessage.af_quantity +
          '\r\n}',
      )
    },
    async closeMessage(): Promise<void> {
      if (
        this.$route.params?.pack_id?.includes('lvl_bag') ||
        this.currentRouteName.includes('PremiumLevelBagsPacks')
      ) {
        this.$router.push({ name: this.$getWebView('PremiumLevelBags') })
        this.hidePaymentMessage()
        return
      }
      if (this.$route.params?.pack_id?.includes('chain')) {
        this.$router.push({ name: this.$getWebView('PremiumChain') })
        this.hidePaymentMessage()
        return
      }
      if (!this.currentRouteName.includes('PremiumChain')) {
        try {
          // continue button lasts too long, we don't need to wait for notifications or rightboxes to load
          this.loadNotifications()
          this.loadRightboxes(true)
        } catch (error: unknown) {
          console.error(error)
        }
      }

      if (this.isBoardGamesEvent) {
        openBoardGamesEvent()
      }

      this.hideOfferDetail()
      this.hidePaymentMessage()

      if (this.$route.path.includes('premium') || this.$route.path.includes('event')) {
        this.$router.replace({
          ...this.$router.currentRoute,
          query: {
            ...(this.$route.path.includes('event') && this.$route.query?.pass
              ? this.$route.query
              : null),
            reloadData: 'true',
          },
        })
      }
    },
  },
})
</script>

<style lang="scss">
@import '@/assets/styles/views/premium/paymentMessage.scss';

.champion-pass-rewards {
  position: relative;
  top: 1rem;
  height: 8.75rem;
}

.game-pass-rewards {
  position: relative;
  top: -2.5rem;
  height: 21.25rem;
}

.payment-message-main {
  &.is-event-pass {
    &.event-pass-1 {
      height: 40.5rem;
    }

    &.event-pass-3 {
      height: 39rem;
    }
  }

  &.is-master-pass {
    height: auto;

    .master-pass-rewards {
      width: 43.75rem;
      height: 15.625rem;
    }
  }

  &.is-game-pass {
    height: 32rem;
  }
}

.event-pass-body {
  margin-top: -1.25rem;
}
</style>
