<template>
  <div class="ranking-country app-page-wrapper absolute">
    <ranking-menu :current="1" :custom-current-route="$getWebView('RankingGlobalCountry')" />
    <section class="w-full h-full safe-area">
      <div class="ranking-country-wrapper m-auto flex">
        <aside class="leftSide">
          <ranking-box-position v-if="rankings" :ranking-position="rankings?.slice(0, 3)" />
        </aside>
        <aside class="rightSide">
          <header class="rightSide-header relative w-full flex justify-end">
            <app-select
              :options="formatedCountries"
              :default="selectedCountry"
              class="selectbox-country"
              @select="changeCountry"
            />
            <tippy theme="WSM" placement="left" max-width="35rem">
              <app-icon icon-name="info-70" />
              <template #content>
                <div class="tooltip-content text-texts-standard-default text-30">
                  <!-- eslint-disable -->
                  <p class="tooltip-content-bottom w-full"
                    v-html="$replacePlaceholder($t('rankingsGroup.rankingRefreshTime'), '%s', `<b class='text-texts-standard-important'>30</b>`)" />
                  <!-- eslint-enable -->
                </div>
              </template>
            </tippy>
          </header>
          <ranking-table-head />
          <app-scrollbar
            class="ranking-global-scrollbar"
            width="100%"
            height="35.8rem"
            scroll="y"
            slide="y"
          >
            <ranking-row-table :rows="rankings" table="global" width="81.1875rem" />
          </app-scrollbar>
          <ranking-player v-if="rankings?.length" :player-data="myRanking" />
        </aside>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import RankingBoxPosition from '@/components/Rankings/RankingBoxPosition.vue'
import RankingMenu from '@/components/Rankings/RankingMenu.vue'
import RankingPlayer from '@/components/Rankings/RankingPlayer.vue'
import RankingRowTable from '@/components/Rankings/RankingRowTable.vue'
import RankingTableHead from '@/components/Rankings/RankingTableHead.vue'
import { arenaRankingNationalEndpoint, pathImages } from '@/globalVariables'
import { useCountriesStore } from '@/store/pinia/countriesStore'
import { useUserStore } from '@/store/pinia/userStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

import type { Nullable } from '@/interfaces/utils'
import type { RankingUser } from '@/interfaces/RankingsInterfaces'
import type { RankingsApiResponse } from '@/interfaces/responses/rankings/RankingsApiResponse'

interface ComponentData {
  pathImages: typeof pathImages
  myRanking: RankingsApiResponse['ranking']['player']
  rankings: Nullable<RankingUser[]>
}

export default defineComponent({
  name: 'RankingGlobalCountry',
  components: {
    RankingBoxPosition,
    RankingRowTable,
    RankingTableHead,
    RankingPlayer,
    RankingMenu,
  },
  data(): ComponentData {
    return {
      pathImages,
      rankings: null,
      myRanking: {} as RankingsApiResponse['ranking']['player'],
    }
  },
  computed: {
    ...mapState(useCountriesStore, {
      formatedCountries: 'getFormattedCountries',
    }),
    ...mapState(useUserStore, {
      selectedCountry: 'getCountry',
    }),
  },
  async created(): Promise<void> {
    this.getRankings()
  },
  methods: {
    async getRankings(selectedCountry?: string): Promise<void> {
      const response = await this.$axios.get<{}, RankingsApiResponse>(
        arenaRankingNationalEndpoint + '/' + (selectedCountry ?? this.selectedCountry),
      )
      this.myRanking = response?.ranking?.player
      this.rankings =
        Object.keys(response?.ranking).reduce(
          (finalArray: RankingUser[], userId: string): RankingUser[] => {
            if (userId !== 'player') {
              finalArray.push({
                id: userId,
                name: response.ranking[userId].username,
                points: response.ranking[userId].RANKING_POINTS,
                level: response.ranking[userId].LEVEL,
                experience: response.ranking[userId].EXPERIENCE,
                training_points: response.ranking[userId].TRAINING_POINTS,
                clubId: response.ranking[userId].club_id,
                clubLogoId: response.ranking[userId].club_logo_id,
                clubLogoBgId: response.ranking[userId].club_background_logo_id,
                eventBadges: response.ranking[userId].event_badges ?? null,
                ...response.ranking[userId],
              })
            }
            return finalArray
          },
          [],
        ) ?? []
    },
    changeCountry(newCountry?: string): void {
      this.getRankings(newCountry)
    },
  },
})
</script>

<style lang="scss" scoped>
.ranking-country-wrapper {
  width: 116.8125rem;
  height: 50rem;

  .leftSide {
    width: 33.125rem;
    padding-top: 1.9375rem;
  }

  .rightSide {
    margin-left: 1.875rem;
    height: 100%;
    width: 81.1875rem;

    &-header {
      height: 4.375rem;
      margin-top: 1.1875rem;
      margin-bottom: 1.25rem;

      .selectbox-country {
        position: absolute;
        left: 50%;
        transform: translateX(-50%) $skewX-value;
      }

      .selectbox-season {
        right: 14%;
      }
    }

    .table-row:nth-child(1) > .table-row-position {
      @if $isWsm {
        background-image: linear-gradient(to top, #ffef84, #f6c717);
      }
      @if $isSsm {
        background-image: linear-gradient(to top, #daab36, #ffdf91);
      }
    }

    .table-row:nth-child(2) > .table-row-position {
      @if $isWsm {
        background-image: linear-gradient(to top, #a4a4a4, #fff);
      }
      @if $isSsm {
        background-image: linear-gradient(to top, #c3c3c3, #f5f0f0);
      }
    }

    .table-row:nth-child(3) > .table-row-position {
      @if $isWsm {
        background-image: linear-gradient(to top, #e77447, #ffb99e);
      }
      @if $isSsm {
        background-image: linear-gradient(to top, #f58d57, #ffb28a);
      }
    }
  }

  .ranking-global-scrollbar {
    width: 82.5rem;
    height: 35.7rem;
  }
}

.tooltip-content {
  height: 5.9375rem;

  &-top {
    height: 4.4375rem;
    line-height: 4.4375rem;
  }

  &-bottom {
    padding-top: 0.85rem;
    height: 6.375rem;
  }

  .center-strip {
    width: 20.5625rem;
    height: 0.125rem;
    background: linear-gradient(to right, #a4a4a400, #a4a4a4, #a4a4a4, #a4a4a400);
  }
}
</style>
