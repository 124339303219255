<template>
  <div
    v-if="isPremiumPack"
    class="chain-pack chain-pack-premium relative text-texts-standard-default flexing"
    :class="[
      {
        'chain-pack-premium--claimed': packStatus === PackStatus.Claimed,
        'chain-pack-unlocked clickable-element cursor-pointer': packStatus === PackStatus.Unlocked,
      },
    ]"
    @click="packStatus === PackStatus.Unlocked ? buyPack() : () => {}"
  >
    <div
      v-if="packStatus === PackStatus.Locked"
      class="chain-pack-locked chain-pack-locked-premium absolute flexing"
    >
      <div class="chain-pack-locked-info flexing flex-col">
        <app-icon icon-name="lock-lg" />
        <p class="text-30 mt-4">{{ $t('premiumGroup.buy_previous_package') }}</p>
      </div>
      <div class="chain-pack-locked-bg"></div>
    </div>
    <section v-if="packStatus != PackStatus.Claimed">
      <span class="chain-decoration chain-decoration-1" />
      <span class="chain-decoration chain-decoration-2" />
    </section>
    <span v-else class="chain-decoration chain-decoration-3" />
    <div class="chain-pack-bonus absolute">
      <app-plate-value
        color="red"
        :text-value="`<span class='font-bold'>${bonus.value}</span>${bonus.sign}`"
      />
    </div>
    <span class="chain-pack-reward" :class="`chain-pack-reward-${iconName}`"></span>
    <div v-if="reward.oldValue" class="chain-reward-old flexing text-36">
      <p>{{ $filters.$formatNumber(reward.oldValue) }}</p>
      <app-main-icon :icon-size="24" :icon-name="iconName" />
    </div>
    <div class="chain-reward flexing text-60 text-texts-standard-default font-bold">
      <p>{{ $filters.$formatNumber(reward.value) }}</p>
      <app-main-icon :icon-size="56" :icon-name="iconName" />
    </div>
    <div class="chain-price italic flexing">
      <p
        v-if="packStatus === PackStatus.Claimed"
        class="claimed-text text-texts-standard-positive text-40 uppercase mx-4 font-bold"
      >
        {{ $t('premiumGroup.purchased') }}
      </p>
      <app-price v-else-if="price">
        {{ `${$filters.$formatNumber(price.value)} ${price.currency}` }}
      </app-price>
    </div>
    <payment-terminal
      v-if="terminal"
      :item-id="storeId"
      :wire-transfer-off="true"
      @close="terminal = false"
    />
  </div>
  <div
    v-else
    class="chain-pack chain-pack-free relative text-texts-standard-default"
    :class="[
      {
        'unknown flex items-end justify-center': packType === PackType.Unknown,
        claimed: packStatus === PackStatus.Claimed,
        'chain-pack-unlocked clickable-element cursor-pointer': packStatus === PackStatus.Unlocked,
      },
    ]"
    @click="claimReward(storeId, packStatus as PackStatus, packType as PackType)"
  >
    <div
      v-if="packStatus === PackStatus.Locked && packType !== PackType.Unknown"
      class="chain-pack-locked absolute flex justify-center"
    >
      <span class="chain-pack-icon">
        <premium-rounded-icon
          type="lock"
          :text="$t('premiumGroup.wsm_chain_of_legends_locked_info')"
        />
      </span>
      <div class="chain-pack-locked-bg" />
    </div>
    <span
      v-if="
        (packType === PackType.Free || packType === PackType.Video) &&
        packStatus === PackStatus.Claimed
      "
      class="chain-pack-icon"
    >
      <premium-rounded-icon type="done" />
    </span>
    <main v-if="packType !== PackType.Unknown" class="flexing">
      <div
        class="reward-box flexing flex-col"
        :class="{ 'opacity-50': packStatus === PackStatus.Claimed }"
      >
        <app-main-icon :icon-size="184" :icon-name="iconName"></app-main-icon>
        <p class="text-texts-standard-default text-60 font-bold mt-4">
          {{ $filters.$formatNumber(reward.value) }}
        </p>
      </div>
    </main>
    <footer class="flexing">
      <section v-if="packType === PackType.Unknown">
        <p class="text-texts-standard-default text-30">
          {{ $t('premiumGroup.buy_previous_package') }}
        </p>
      </section>
      <section v-else-if="packStatus === PackStatus.Claimed">
        <p class="claimed-text text-texts-standard-positive text-40 uppercase mx-4 font-bold">
          {{ $t('common.claimed') }}
        </p>
      </section>
      <section v-else>
        <app-button
          v-if="packType === PackType.Free"
          btn-id="premium-chain-claim"
          btn-type="confirm"
          :btn-text="$t('common.claim')"
          btn-size="md"
        />
        <app-multi-button
          v-if="packType === PackType.Video && isRewardedAdLoaded"
          :btn-id="storeId"
          btn-type="glowing"
          btn-size="md"
          :btn-text="$t('button.watch')"
          multi-width="24rem"
          :loading="isMobilePlayingVideo"
          :disabled="packStatus === PackStatus.Locked"
        >
          <template #rightPart>
            <app-icon icon-name="play-ad-md" />
          </template>
        </app-multi-button>
      </section>
    </footer>
    <video-ad-web v-show="isWebVideo" />
  </div>
</template>

<script lang="ts">
import AppPlateValue from '@/components/GlobalComponents/AppPlateValue.vue'
import AppPrice from '@/components/GlobalComponents/AppPrice.vue'
import PaymentTerminal from '@/components/Popup/Premium/PaymentTerminal.vue'
import PremiumRoundedIcon from '@/components/Premium/Accessories/RoundedIcon.vue'
import VideoAdWeb from '@/components/Premium/Ads/VideoAdWeb.vue'
import {
  VIDEO_AD_DURATION,
  metaPremiumAdsClaimEndpoint,
  metaPremiumPackEndpoint,
  metaPremiumPayEndpoint,
} from '@/globalVariables'
import { formatTime, getIsMobileLocalStorage, requestWebAd, sendToFlutter } from '@/helpers'
import { useAdStore } from '@/store/pinia/adStore'
import { usePremiumStore } from '@/store/pinia/premiumStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

import { PackStatus, PackType } from '@/interfaces/premium/enums'
import type { PropType } from 'vue'
import type { Nullable } from '@/interfaces/utils'
import type Price from '@/interfaces/Price'
import type PremiumPayApiResponse from '@/interfaces/responses/premium/PremiumPayApiResponse'
import type PremiumPackApiResponse from '@/interfaces/responses/premium/PremiumPackApiResponse'
import type { PremiumVideoAdsClaimApiResponse } from '@/interfaces/responses/premium/PremiumVideoAdsClaimApiResponse'
import { VideoAdState } from '@/enums/VideoAdStates'

interface PremiumChainPackBonus {
  value: number
  sign: string
}

interface PremiumChainPackReward {
  name: string
  value: number
  rarity?: string
  gdd_id?: number
  oldValue: number
}

interface ComponentData {
  adType: string
  terminal: boolean
  PackStatus: typeof PackStatus
  PackType: typeof PackType
}

export default defineComponent({
  name: 'PremiumChainPack',
  components: {
    AppPrice,
    AppPlateValue,
    VideoAdWeb,
    PaymentTerminal,
    PremiumRoundedIcon,
  },
  props: {
    packType: {
      type: String,
      required: true,
      validator(propName: PackType): boolean {
        return [PackType.Premium, PackType.Video, PackType.Free, PackType.Unknown].includes(
          propName,
        )
      },
    },
    packStatus: {
      type: String,
      required: true,
      validator(propName: PackStatus): boolean {
        return [PackStatus.Locked, PackStatus.Unlocked, PackStatus.Claimed].includes(propName)
      },
    },
    reward: {
      type: Object as PropType<Nullable<PremiumChainPackReward>>,
      default: () => null,
    },
    price: {
      type: Object as PropType<Nullable<Price>>,
      default: () => null,
    },
    bonus: {
      type: Object as PropType<Nullable<PremiumChainPackBonus>>,
      default: () => null,
    },
    storeId: {
      type: String,
      default: '',
    },
  },
  emits: ['reloadContent'],
  data(): ComponentData {
    return {
      adType: 'chain_video_ads',
      terminal: false,
      PackStatus,
      PackType,
    }
  },
  computed: {
    ...mapState(usePremiumStore, {
      isMobilePlayingVideo: 'getMobileVideoPlaying',
      isWebVideo: 'getWebVideoPlaying',
      isRewardedAdLoaded: 'getRewardedAdLoaded',
    }),
    isPremiumPack(): boolean {
      return this.packType === PackType.Premium
    },
    isMobileLocalStorage(): boolean {
      return getIsMobileLocalStorage() === 1
    },
    iconName(): string {
      if (this.reward.rarity) {
        return this.getRarityIconName
      } else {
        return this.reward.name
      }
    },
    getRarityIconName(): string {
      if (this.reward.gdd_id) {
        return `${this.reward.name}-${this.reward.gdd_id}`
      } else {
        return `${this.reward.name}-${this.reward.rarity}`
      }
    },
  },
  mounted(): void {
    window.rewardedVideoEarned = this.rewardedVideoEarned
    window.failedToLoadVideoAds = this.rewardedVideoFailed
    window.rewardedVideoEnd = this.rewardedVideoEnd
  },
  methods: {
    formatTime,
    ...mapActions(useAdStore, ['setAdBlockState']),
    ...mapActions(usePremiumStore, {
      webVideoPlay: 'setWebVideoPlay',
      setVideoPlay: 'setVideoPlay',
    }),
    ...mapActions(useResponseTaskStore, {
      showPaymentLoading: 'showPaymentLoading',
      showPaymentMessage: 'showPaymentMessage',
    }),
    async buyPack(): Promise<void> {
      const localStorageVideoId = localStorage.getItem('playedVideoId')

      const pack = localStorageVideoId ?? this.storeId

      if (!this.isMobileLocalStorage && this.price.value > 0) {
        this.terminal = true
        return
      }

      this.showPaymentLoading()

      const responsePay = await this.$axios.get<{}, PremiumPayApiResponse>(
        `${metaPremiumPayEndpoint}/pack/${pack}${
          !this.isMobileLocalStorage ? '/bank_transfer' : ''
        }`,
      )
      if (responsePay?.event) {
        const message = {
          event: responsePay.event,
          productId: responsePay.productId,
        }
        sendToFlutter(JSON.stringify(message))
        return
      }

      const responsePack = await this.$axios.get<{}, PremiumPackApiResponse>(
        `${metaPremiumPackEndpoint}${localStorageVideoId ?? this.storeId}`,
      )
      this.showPaymentMessage({
        pack: this.storeId,
        rewards: responsePack.rewards,
        failed: false,
        af_quantity: responsePack.firebaseData.af_quantity,
        af_store_id: responsePack.firebaseData.af_store_id,
        gtm_currency: responsePack.firebaseData.gtm_currency,
        gtm_revenue: responsePack.firebaseData.gtm_revenue,
      })
      localStorage.removeItem('playedVideoId')
    },
    rewardedVideoFailed(): void {
      this.setAdBlockState(true)
      this.setVideoPlay(false)
    },
    rewardedVideoEnd(): void {
      this.setVideoPlay(false)
    },
    async rewardedVideoEarned(): Promise<void> {
      try {
        await this.$axios.put<{}, PremiumVideoAdsClaimApiResponse>(metaPremiumAdsClaimEndpoint, {
          type: this.adType,
          state: VideoAdState.Finished,
        })

        this.webVideoPlay(false)
        this.buyPack()
        this.setVideoPlay(false)
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async playVideo(id: string): Promise<void> {
      localStorage.setItem('playedVideoId', id)
      this.setVideoPlay(true)
      setTimeout((): void => {
        this.setVideoPlay(false)
      }, VIDEO_AD_DURATION)
      if (!this.$isMobile()) {
        this.webVideoPlay(true)
      }
      try {
        await this.$axios.put<{}, PremiumVideoAdsClaimApiResponse>(metaPremiumAdsClaimEndpoint, {
          type: this.adType,
          state: VideoAdState.Started,
        })
      } catch (error: unknown) {
        console.error(error)
      }

      if (this.$isMobile()) {
        sendToFlutter('{\r\n "event":"playRewarded"\r\n}')
      }

      if (!this.$isMobile()) {
        requestWebAd({
          id: 'video-ad-web-default',
          onRequest: (): void => {
            this.webVideoPlay(true)
          },
          onSuccess: (): void => {
            this.webVideoPlay(true)
          },
          onError: (): void => {
            this.webVideoPlay(false)
          },
          onCallbackComplete: (): void => {
            this.rewardedVideoEarned()
            this.webVideoPlay(false)
          },
        })
      }
    },
    claimReward(id: string, packStatus: PackStatus, packType: PackType): void {
      localStorage.removeItem('playedVideoId')

      if (packStatus !== PackStatus.Unlocked) return
      if (packType === PackType.Free) {
        this.buyPack()
        return
      }
      if (packType === PackType.Video) {
        this.playVideo(id)
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.chain-pack {
  &:first-child {
    margin-left: 0;
  }

  &:not(:first-child) {
    &:before,
    &:after {
      width: 7.813rem;
      height: 3.438rem;
      background: url($path-premium + 'chain/chain.webp') center no-repeat;
      background-size: contain;
      content: '';
      position: absolute;
      left: -7rem;
    }

    &:before {
      top: 2rem;
    }

    &:after {
      bottom: 2rem;
    }
  }

  .claimed-text {
    &:before {
      content: '[';
      color: #5199c6;
      margin-right: 1rem;
      font-style: normal;
    }

    &:after {
      content: ']';
      color: #5199c6;
      margin-left: 1rem;
      font-style: normal;
    }
  }

  &-locked {
    top: 0.2rem;
    left: 0.3rem;
    width: 27.1rem;
    height: 99%;
    z-index: 1;

    &-bg {
      width: 100%;
      height: 100%;
      background: rgba($color: #000000, $alpha: 0.7);
      position: absolute;
      left: 0;
      top: 0;
      pointer-events: none;
    }

    &-premium {
      top: 2.1rem;
      left: 2.1rem;
      width: 27.4rem;
      height: 90.5%;
    }

    &-info {
      width: 97%;
      height: 11.25rem;
      z-index: 1;
      position: relative;
      top: -5rem;
      background: linear-gradient(
        to right,
        rgba(29, 62, 93, 0),
        rgba(29, 62, 93, 0.7),
        rgba(29, 62, 93, 0.69),
        rgba(29, 62, 93, 0)
      );

      &:before,
      &:after {
        content: '';
        width: 100%;
        height: 0.25rem;
        position: absolute;
        top: -0.25rem;
        left: 0;
      }

      &:before {
        top: -0.25rem;
      }

      &:after {
        bottom: 0;
        top: auto;
      }

      &:before {
        background: linear-gradient(
          to left,
          rgba(154, 190, 246, 0.1) 20%,
          rgba(154, 190, 246, 1),
          rgba(154, 190, 246, 0.1) 80%
        );
      }

      &:after {
        background: linear-gradient(
          to left,
          rgba(154, 190, 246, 0.1) 20%,
          rgba(154, 190, 246, 1),
          rgba(154, 190, 246, 0.1) 80%
        );
      }
    }
  }

  &-icon {
    position: absolute;
    top: -3rem;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
  }
}

.chain-pack-premium {
  width: 31.625rem;
  height: 45.375rem;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url($path-premium + 'chain/chain-pack-bg.webp');
  margin: 0 1rem;

  &--claimed {
    background-image: url($path-premium + 'chain/chain-pack-bought-bg.webp');
  }

  &:not(:first-child) {
    &:before {
      top: 6rem;
      left: -5rem;
    }

    &:after {
      bottom: 6rem;
      left: -5rem;
    }
  }

  .chain-price {
    position: absolute;
    bottom: 2rem;
    height: 6.5rem;
  }

  .chain-reward {
    position: absolute;
    bottom: 8.5rem;
    width: 90%;
    height: 5rem;
    background: linear-gradient(
      90deg,
      rgba(57, 97, 162, 0.1) 0%,
      rgba(57, 97, 162, 0.7) 15%,
      rgba(57, 97, 162, 1) 50%,
      rgba(57, 97, 162, 0.7) 85%,
      rgba(57, 97, 162, 0.1) 100%
    );
    border-style: solid;
    border-width: 0.125rem;
    border-image-slice: 1;
    border-image-source: linear-gradient(
      90deg,
      rgba(125, 156, 205, 0.05) 0%,
      rgba(125, 156, 205, 1) 50%,
      rgba(125, 156, 205, 0.05) 100%
    );
    border-bottom: 0;

    & > p {
      line-height: 5rem;
    }

    &-old {
      position: absolute;
      bottom: 13.5rem;
      color: rgba(217, 236, 255, 0.6);

      &:before {
        content: '';
        position: absolute;
        background: #b52e2d;
        width: 100%;
        height: 0.25rem;
        left: 49%;
        transform: translate(-50%) skewY(-12deg);
        top: 45%;
      }
    }
  }
}

.chain-pack-free {
  width: 27.625rem;
  height: 37.625rem;
  background-repeat: no-repeat;
  background-image: url($path-premium + 'chain/chain-pack-free-bg.avif');
  background-size: contain;
  margin: 0 3rem;

  &.unknown {
    background-image: url($path-premium + 'chain/chain-pack-unknown-bg.avif');
  }

  &.claimed {
    background-image: url($path-premium + 'chain/chain-pack-free-claimed-bg.avif');
  }

  main {
    height: 29.5rem;

    .reward-box {
      width: 15.25rem;
      height: 19.5rem;
      background: url($path-premium + 'chain/reward-box.webp') center no-repeat;
      background-size: contain;
    }
  }

  footer {
    height: 8.125rem;

    button {
      width: 24rem;
    }
  }
}

.chain-decoration {
  width: 27.625rem;
  height: 41.125rem;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  left: 2rem;
  top: 2rem;

  &-1 {
    background-image: url($path-premium + 'chain/chain-pack-decoration-1.webp');
  }

  &-2 {
    background-image: url($path-premium + 'chain/chain-pack-decoration-2.webp');
  }

  &-3 {
    background-image: url($path-premium + 'chain/chain-pack-decoration-bought.webp');
  }
}

.chain-pack-bonus {
  right: 4rem;
  top: 3rem;
}

.chain-pack-reward {
  width: 27.625rem;
  height: 41.125rem;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  left: 2rem;
  top: 2rem;

  &-benefit {
    &-3 {
      background-image: url($path-premium + 'chain/rewards/reward-benefit-3.webp');
    }

    &-6 {
      background-image: url($path-premium + 'chain/rewards/reward-benefit-6.webp');
    }

    &-9 {
      background-image: url($path-premium + 'chain/rewards/reward-benefit-9.webp');
    }
  }

  &-energy {
    background-image: url($path-premium + 'chain/rewards/reward-energy.webp');
  }

  &-gems {
    background-image: url($path-premium + 'chain/rewards/reward-gem.webp');
  }

  &-starts {
    background-image: url($path-premium + 'chain/rewards/reward-starts.webp');
  }

  &-universal_training_points {
    background-image: url($path-premium + 'chain/rewards/reward-training-points.webp');
  }

  &-research_points {
    background-image: url($path-premium + 'chain/rewards/reward-research-points.webp');
  }

  &-energy_drink {
    background-image: url($path-premium + 'chain/rewards/reward-energy-drink.webp');
  }

  &-wild_card {
    background-image: url($path-premium + 'chain/rewards/reward-wild-cards.webp');
  }

  &-money {
    background-image: url($path-premium + 'chain/rewards/reward-money.webp');
  }
}
</style>
