<template>
  <div id="gp-history" class="gp-history app-page-wrapper absolute" data-cy="gp-history">
    <menu-component
      menu-type="gp-history"
      title="Grand Prize"
      :menu-items="{
        1: {
          text: $t('grandPrize.warehouse'),
          route: $getWebView('GrandPrizeWarehouse'),
        },
        2: {
          text: $t('grandPrize.progress'),
          route: $getWebView('GrandPrizeProgress'),
        },
        3: {
          text: $t('grandPrize.history'),
          route: $getWebView('GrandPrizeHistory'),
        },
      }"
    />
    <section class="w-full h-full safe-area">
      <header class="gp-history-header">
        <div
          class="text-36 uppercase flex items-center justify-between warehouse-subtitle"
          :class="{
            'text-texts-standard-default': $isWsm,
            'text-texts-standard-additional': $isSsm,
          }"
        >
          <div class="flex items-center">
            <app-check-box
              v-for="filterType in filterTypes"
              :key="filterType.type"
              :size="$isWsm ? 'md' : 'sm'"
              :is-checked="hasFilter(filterType.type)"
              @checked-action="setFilter(filterType.type as GrandPrizeHistoryPreference)"
            >
              <p
                class="pl-5 pr-5 cursor-pointer"
                :class="{
                  'text-texts-standard-important': hasFilter(filterType.type),
                  'font-bold': $isSsm,
                }"
              >
                {{ $t(filterType.text) }}
              </p>
            </app-check-box>
          </div>
          <div class="flex items-center">
            <app-icon icon-name="question" @click="showPopup = true" />
            <info-popup
              v-if="showPopup"
              :popup-title="$replacePlaceholder($t('grandPrize.info'), '{grandprize}', GRAND_PRIZE)"
              @close="showPopup = false"
            >
              <grand-prize-about />
            </info-popup>
          </div>
        </div>
      </header>
      <main v-if="filteredHistoryData.length > 0 && !isLoading" class="text-texts-standard-default">
        <app-scrollbar
          scroll="y"
          slide="y"
          width="98%"
          :height="allRewards.length > 0 ? '63%' : '69%'"
          class="gp-history-scroll"
        >
          <grand-prize-history-item
            v-for="(history, i) in filteredHistoryData"
            :key="i"
            :history-data="history"
            @claim="claimGPRewards"
            @open="openGrandPrize"
            @time-out="grandPrizeTimeOut"
          />
        </app-scrollbar>
      </main>
      <main v-if="filteredHistoryData.length === 0 && !isLoading" class="flexing">
        <p class="text-texts-standard-name uppercase text-40">
          {{ $replacePlaceholder($t('grandPrize.gpNotFound'), '{grandprize}', GRAND_PRIZE) }}
        </p>
      </main>

      <component-loading :height="'65%'" :is-loading="isLoading" />
      <grand-prize-history-footer
        v-if="allRewards.length > 0 && !isLoading"
        :rewards="allRewards"
        @claim="claimAllRewards"
      />
    </section>
  </div>
</template>

<script lang="ts">
import GrandPrizeAbout from '@/components/GrandPrize/GrandPrizeAbout.vue'
import GrandPrizeHistoryFooter from '@/components/GrandPrize/GrandPrizeHistoryFooter.vue'
import GrandPrizeHistoryItem from '@/components/GrandPrize/GrandPrizeHistoryItem.vue'
import InfoPopup from '@/components/Popup/InfoPopup.vue'
import {
  ALL_GRAND_PRIZES,
  GRAND_PRIZE,
  GRAND_PRIZE_CLAIMED,
  GRAND_PRIZE_OPENED,
  GRAND_PRIZE_REWARD_CLAIMED,
  GRAND_PRIZE_REWARD_OPENED,
  GRAND_PRIZE_UNCLAIMED,
  GRAND_PRIZE_UNOPENED,
  grandPrizeClaimEndPoint,
  grandPrizeEndPoint,
  grandPrizeHistoryEndPoint,
  grandPrizeRewardsEndPoint,
} from '@/globalVariables'
import { useGrandPrizeStore } from '@/store/pinia/grandPrize/mainPrizeStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import type Reward from '@/interfaces/Reward'
import type {
  GrandPrizeHistoryApiResponse,
  GrandPrizeParameter,
  OpenGrandPrizeApiResponse,
} from '@/interfaces/responses/grandPrize/GrandPrizeApiResponse'
import type { GrandPrizeHistoryData } from '@/interfaces/GrandPrize'
import type { GrandPrizeHistoryPreference } from '@/interfaces/GrandPrize'

const TIME_WARNING_HOUR_CONST = 3600
const SECOND_IN_MILISECONDS = 1000

interface FilterType {
  type: string
  text: string
}
interface ComponentData {
  GRAND_PRIZE: typeof GRAND_PRIZE
  showPopup: boolean
  filterTypes: FilterType[]
  allRewards: Reward[]
  historyData: GrandPrizeHistoryData[]
  isLoading: boolean
  isReloading: boolean
}

export default defineComponent({
  name: 'GrandPrizeHistory',
  components: {
    GrandPrizeHistoryItem,
    GrandPrizeHistoryFooter,
    GrandPrizeAbout,
    InfoPopup,
  },
  data(): ComponentData {
    return {
      GRAND_PRIZE,
      showPopup: false,
      filterTypes: [
        {
          type: ALL_GRAND_PRIZES,
          text: 'grandPrize.filterAll',
        },
        {
          type: GRAND_PRIZE_UNOPENED,
          text: 'grandPrize.filterUnopened',
        },
        {
          type: GRAND_PRIZE_UNCLAIMED,
          text: 'grandPrize.filterUnclaimed',
        },
        {
          type: GRAND_PRIZE_CLAIMED,
          text: 'grandPrize.filterClaimed',
        },
      ],
      allRewards: [],
      historyData: [],
      isLoading: false,
      isReloading: false,
    }
  },
  computed: {
    ...mapState(useGrandPrizeStore, {
      getHistoryPreference: 'getHistoryPreference',
    }),
    filteredHistoryData(): GrandPrizeHistoryData[] {
      if (!this.historyData) return []
      if (this.getHistoryPreference === ALL_GRAND_PRIZES) {
        return this.historyData
      }
      return this.historyData?.filter(
        (grandPrize: GrandPrizeHistoryData): boolean =>
          grandPrize.status === this.getHistoryPreference,
      )
    },
  },
  created(): void {
    this.loadData()
  },
  methods: {
    ...mapActions(useGrandPrizeStore, {
      setHistoryPreference: 'setHistoryPreference',
      loadHistoryPreferenceFromSession: 'loadHistoryPreferenceFromSession',
    }),
    async loadData(): Promise<void> {
      try {
        this.loadHistoryPreferenceFromSession()
        const result = await this.loadHistory()
        if (result) this.loadUnclaimedRewards()
      } catch (err: unknown) {
        console.error(err)
      }
    },
    hasFilter(type: string): boolean {
      return this.getHistoryPreference === type
    },
    setFilter(type: GrandPrizeHistoryPreference): void {
      this.setHistoryPreference(type)
    },
    hasUnclaimedRewards(rewards: Reward[]): boolean {
      return !!rewards.filter(
        (reward: Reward): boolean => reward.slot_state === GRAND_PRIZE_REWARD_OPENED,
      ).length
    },
    redirectToDetail(id: string): void {
      this.$router.push({
        name: this.$getWebView('GrandPrizeMain'),
        params: { id: id },
      })
    },
    async loadHistory(): Promise<boolean> {
      try {
        this.isLoading = true
        const rewardsFilter = [GRAND_PRIZE_REWARD_CLAIMED, GRAND_PRIZE_REWARD_OPENED]
        const response = await this.$axios.get<{}, GrandPrizeHistoryApiResponse[]>(
          grandPrizeHistoryEndPoint,
        )
        let opened = null
        const data = response.map(
          (grandPrize: GrandPrizeHistoryApiResponse): GrandPrizeHistoryData => {
            if (grandPrize.item_state === GRAND_PRIZE_OPENED) {
              opened = grandPrize
            }
            return {
              id: grandPrize.id,
              gpType: grandPrize.parameters.find(
                (parameter: GrandPrizeParameter): boolean => parameter.name === 'grand_prize_name',
              )?.value as string,
              gpLevel: grandPrize.stats.level,
              gpProgressName: grandPrize.stats.gp_progress_name,
              status: this.hasUnclaimedRewards(grandPrize.rewards)
                ? GRAND_PRIZE_UNCLAIMED
                : grandPrize.item_state,
              time: grandPrize.expire_in * SECOND_IN_MILISECONDS,
              timeWarning:
                (grandPrize.parameters.find(
                  (parameter: GrandPrizeParameter): boolean =>
                    parameter.name === 'grand_prize_expire_hours_warning',
                )?.value as number) * TIME_WARNING_HOUR_CONST,
              existsTime: new Date(Date.now() - grandPrize.exists_time * SECOND_IN_MILISECONDS),
              rewards: grandPrize.rewards
                .filter((reward: Reward): boolean => rewardsFilter.includes(reward.slot_state))
                .map((reward: Reward): Reward => {
                  return {
                    rarity: reward.rarity,
                    name: reward.name,
                    value: reward.value,
                  }
                }),
            }
          },
        )
        if (opened !== null) {
          this.redirectToDetail(opened.id)
          return false
        }
        this.historyData = data
        this.isLoading = false

        return true
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async loadUnclaimedRewards(): Promise<void> {
      const response = await this.$axios.get<{}, Reward[]>(grandPrizeRewardsEndPoint)
      this.allRewards = response.map((reward: Reward): Reward => {
        return {
          type: reward.name,
          value: reward.value,
        }
      })
      this.isReloading = false
    },
    async claimAllRewards(): Promise<void> {
      try {
        await this.$axios.put<{}, true>(grandPrizeClaimEndPoint)
      } catch (error: unknown) {
        console.error(error)
      }
      this.allRewards = []
      this.historyData = this.historyData.map(
        (grandPrize: GrandPrizeHistoryData): GrandPrizeHistoryData => {
          if (grandPrize.status === GRAND_PRIZE_UNCLAIMED) {
            grandPrize.status = GRAND_PRIZE_CLAIMED
          }
          return grandPrize
        },
      )
    },
    async claimGPRewards(grandPrize: GrandPrizeHistoryData) {
      try {
        await this.$axios.put<{}, true>(grandPrizeClaimEndPoint, {
          grand_prizes_id: grandPrize.id,
        })
      } catch (error: unknown) {
        console.error(error)
      }
      grandPrize.rewards.forEach((gpReward: Reward): void => {
        this.allRewards = this.allRewards
          .map((reward: Reward): Reward => {
            if (reward.type === gpReward.name) {
              reward.value -= gpReward.value
            }
            return reward
          })
          .filter((reward: Reward): boolean => reward.value > 0)
      })
      const gp = this.historyData.find(
        (gp: GrandPrizeHistoryData): boolean => gp.id === grandPrize.id,
      )
      gp.status = GRAND_PRIZE_CLAIMED
    },
    async openGrandPrize(grandPrize: GrandPrizeHistoryData): Promise<void> {
      if (grandPrize.status === GRAND_PRIZE_UNOPENED) {
        try {
          await this.$axios.put<{}, OpenGrandPrizeApiResponse>(grandPrizeEndPoint, {
            grand_prize_id: grandPrize.id,
          })
        } catch (error: unknown) {
          console.error(error)
        }
      }
      this.redirectToDetail(grandPrize.id)
    },
    grandPrizeTimeOut(): void {
      if (!this.isReloading) {
        this.isReloading = true
        this.allRewards = []
        this.historyData = []
        this.loadData()
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.gp-history {
  &-scroll {
    padding-right: 1.5rem;
    position: absolute;
  }

  &-header,
  main {
    width: 98%;
    margin: 1.625rem auto 0 auto;
  }

  main {
    height: 69%;
  }
}
</style>
