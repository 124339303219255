<template>
  <div>
    <section
      v-for="(ranking, index) in rankingPosition"
      :key="index"
      class="position-box relative"
      :style="{
        background: 'url(' + pathImages + 'rankings/box-' + (index + 1) + '.avif) center no-repeat',
        backgroundSize: 'contain',
      }"
    >
      <div
        class="position-box-cup absolute"
        :style="{
          background:
            'url(' + pathImages + 'rankings/cup-' + (index + 1) + '.avif) center no-repeat',
          backgroundSize: 'contain',
        }"
      />
      <div
        v-if="ranking.name != null"
        class="flag m-auto"
        :style="{
          backgroundImage: 'url(' + pathImages + 'flags/102x68/' + ranking.country + '.avif)',
        }"
      />
      <p
        v-if="ranking.name"
        class="player-name text-texts-standard-dark font-bold text-32 flexing"
        :class="!ranking.isBot && ranking.id ? 'cursor-pointer' : ''"
        @click="
          !ranking.isBot && ranking.id
            ? $router.push({
                name: $getWebView('ProfileOverview'),
                params: { id: 1, user: ranking.id },
              })
            : ''
        "
      >
        {{ ranking.name }}
        <span>
          <event-badge :current-badges="ranking.event_badges ?? null" />
        </span>
      </p>
      <p
        v-if="ranking.club"
        :class="
          ranking.name == null
            ? 'text-texts-standard-dark font-bold text-32 cursor-pointer col-wrapper'
            : 'club-name text-texts-standard-dark text-28 cursor-pointer flexing'
        "
        @click="goToClubProfile(ranking.clubId)"
      >
        <app-club-name
          :club-id="ranking.clubId ?? ranking.club_id"
          :name="ranking.club"
          :dark-text="true"
          :club-logo-id="ranking.clubLogoId"
          :clubs-background-id="ranking.clubLogoBgId"
          :is-flex-col="ranking.name == null"
        />
      </p>
      <div class="points-box flexing">
        <p class="font-bold text-texts-standard-default text-36 revert-skew">
          <template v-if="isEventReputation">
            {{ $filters.$formatNumber(ranking.points) }}
          </template>
          <template v-else>
            {{ ranking.points }}
          </template>
        </p>
        <app-main-icon
          v-if="pointsType === 'ranking_points'"
          class="revert-skew"
          icon-name="ranking_points"
          :icon-size="48"
        />
        <app-icon
          v-if="isEventReputation"
          :icon-name="EVENT_REPUTATION"
          :tooltip-text="$t('event.reputation')"
          class="ml-4 revert-skew"
        />
      </div>
      <div
        class="position-box-badge absolute"
        :style="{
          background:
            'url(' + pathImages + 'rankings/number-' + (index + 1) + '.avif)center no-repeat',
          backgroundSize: 'contain',
        }"
      />
    </section>
  </div>
</template>

<script lang="ts">
import AppClubName from '@/components/GlobalComponents/AppClubName.vue'
import EventBadge from '@/components/GlobalComponents/EventBadge.vue'
import { EVENT_REPUTATION, pathImages } from '@/globalVariables'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { RankingUser } from '@/interfaces/RankingsInterfaces'

interface ComponentData {
  EVENT_REPUTATION: typeof EVENT_REPUTATION
  pathImages: typeof pathImages
}

export default defineComponent({
  components: {
    AppClubName,
    EventBadge,
  },
  props: {
    rankingPosition: {
      type: Array as PropType<RankingUser[]>,
      default: () => [],
    },
    pointsType: {
      type: String,
      default: 'ranking_points',
    },
  },
  data(): ComponentData {
    return {
      EVENT_REPUTATION,
      pathImages,
    }
  },
  computed: {
    isEventReputation(): boolean {
      return this.pointsType === EVENT_REPUTATION
    },
  },
  methods: {
    goToClubProfile(clubId): void {
      if (clubId == null) return

      this.$router.push({
        name: this.$getWebView('ClubsProfile'),
        params: { clubProfileId: clubId },
        query: { homepage: 'true' },
      })
    },
  },
})
</script>

<style lang="scss" scoped>
.position-box {
  width: 33.125rem;
  height: 14.375rem;
  margin: 1.5rem 0;
  display: grid;
  justify-content: flex-end;
  padding-right: 1.125rem;
  padding-bottom: 0.875rem;

  .flag {
    margin-top: 1.375rem;
    width: 3.0625rem;
    min-width: 3.0625rem;
    height: 2rem;
    background-size: contain;
  }

  &-cup {
    width: 14.125rem;
    height: 14.125rem;
    bottom: 0;
  }

  &-badge {
    width: 5rem;
    height: 4rem;
    right: 0;
    top: -1rem;
  }

  .club-name {
    height: 3rem;
  }

  .points-box {
    width: 16.875rem;
    height: 4.0625rem;
    transform: $skewX-value;
    border-radius: 0.125rem;
    font-size: 2.25rem;
    padding-left: 2rem;
    margin: 0 auto;
    box-shadow:
      inset -0.4375rem 0 1.3125rem 0 rgba(51, 123, 214, 0.19),
      inset -0.019625rem -0.1865rem 2.5rem 0 rgba(6, 34, 55, 0.48);
    border-width: 0.125rem;
    border-style: solid;
    @if $isWsm {
      background: rgb(15, 50, 81);
      border-color: #f4d91e;
    }
    @if $isSsm {
      background: #363e5d;
      border-image-source: linear-gradient(to top, #fff284, #f6c717);
    }
  }
}

.col-wrapper {
  margin-top: 1rem;
  margin-bottom: 2rem;
}
</style>
