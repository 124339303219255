import {
  metaNewsActionedEndpoint,
  metaNewsGetAllUserNewsEndpoint,
  metaNewsUnreadEndpoint,
} from '@/globalVariables'
import { internalAxios } from '@/plugins/vueAxios'
import type { TrueFalseInterface } from '@/interfaces/utils'
import { defineStore } from 'pinia'
import type { NewsRequestResponseInterface, UserNewsDetail } from '@/interfaces/News'
import { ApiService } from '@/services/ApiService'

interface NewsState {
  newsDetail: UserNewsDetail
  unreadNewsCount: number
  hideNewsOnMap: boolean
  allNews: NewsRequestResponseInterface[]
  newsLoaded: boolean
  reloadNews: boolean
}

export const useNewsStore = defineStore('newsStore', {
  state: (): NewsState => ({
    newsDetail: {} as UserNewsDetail,
    unreadNewsCount: 0,
    hideNewsOnMap: false,
    allNews: [] as NewsRequestResponseInterface[],
    newsLoaded: false,
    reloadNews: false,
  }),
  getters: {
    getNewsDetail(): NewsState['newsDetail'] {
      return this.newsDetail
    },
    getUnreadNewsCount(): NewsState['unreadNewsCount'] {
      return this.unreadNewsCount
    },
    getHideNewsOnMap(): NewsState['hideNewsOnMap'] {
      return this.hideNewsOnMap
    },
    getReloadNews(): NewsState['reloadNews'] {
      return this.reloadNews
    },
    getAllNews(): NewsState['allNews'] {
      return this.allNews
    },
  },
  actions: {
    setNewsDetail(payload: UserNewsDetail): void {
      this.newsDetail = payload
    },
    async loadUnreadNewsCount(): Promise<void> {
      try {
        const newsCount =
          (await ApiService.get<number>(metaNewsUnreadEndpoint, { force: true })) ?? 0
        this.unreadNewsCount = newsCount
      } catch (error: unknown) {
        return Promise.reject(error)
      }
    },
    setHideNewsOnMap(state: boolean): void {
      this.hideNewsOnMap = state
    },
    async getLatestUnreadMessage(): Promise<NewsRequestResponseInterface> {
      await this.loadNews()
      // get latest unread message no more than 3 days old
      const dateSet = new Date()
      dateSet.setDate(dateSet.getDate() - 3)
      const threeDaysAgo = dateSet.getTime()

      if (this.allNews[0].unread && this.allNews[0].created * 1000 >= threeDaysAgo) {
        return this.allNews[0]
      }
      return {} as NewsRequestResponseInterface
    },
    async getNews(): Promise<NewsState['allNews']> {
      await this.loadNews()
      return this.allNews
    },
    async loadNews(): Promise<boolean> {
      let allNews = await internalAxios.post<{}, NewsRequestResponseInterface[]>(
        metaNewsGetAllUserNewsEndpoint,
      )
      if (!allNews || !allNews.length) {
        allNews = []
      } else {
        allNews = allNews.slice(0, 10).map((item: UserNewsDetail): UserNewsDetail => {
          const time = item.availableFrom ?? item.created
          const timestamp = new Date(time * 1000)
          return {
            ...item,
            id: item.id,
            title: item.title,
            text: item.content,
            image: item.image,
            unread: !item.seen,
            date: timestamp.toLocaleDateString(),
            time:
              timestamp.getHours() +
              ':' +
              (timestamp.getMinutes() < 10 ? '0' + timestamp.getMinutes() : timestamp.getMinutes()),
            created: item.created,
          }
        })
      }
      this.newsLoaded = true
      this.allNews = allNews
      return true
    },
    async loadNewsDetail(newsId: number): Promise<void> {
      if (!this.newsLoaded) {
        await this.loadNews()
      }
      const newsData = this.allNews.find((item: NewsRequestResponseInterface): boolean => {
        return item.id === newsId
      })

      this.setNewsDetail(newsData)
    },
    markAsRead(newsId: number): void {
      internalAxios.put<{}, TrueFalseInterface>(metaNewsActionedEndpoint + newsId).then(() => {
        this.loadNews()
        this.unreadNewsCount--
      })
    },
    setReloadNews(state: boolean): void {
      this.newsLoaded = false
      this.reloadNews = state
    },
  },
})
