<template>
  <section
    :class="[
      buttonState,
      { 'is-masters': isMasters, 'no-qualify': notQualified },
      addActivePlayClass,
      finalState,
    ]"
    class="cta"
  >
    <p v-if="isRecalculating"></p>
    <p
      v-else-if="
        buttonState !== TournamentCtaStates.in_progress &&
        buttonState !== TournamentCtaStates.not_joined &&
        !isMasters
      "
      style="line-height: 1.7rem"
      class="text-texts-standard-default text-28"
      :class="{ 'colorize-gold': stateText !== $te('signUpPrompt') }"
    >
      {{ stateText }}
    </p>
    <p
      v-else-if="
        buttonState === TournamentCtaStates.in_progress &&
        tournamentStatus === ActiveState.Draw &&
        !isMasters
      "
      style="line-height: 1.7rem"
      class="text-texts-standard-default text-28"
      :class="{ 'colorize-gold': stateText !== $te('signUpPrompt') }"
    >
      {{ stateText }}
    </p>
    <p
      v-else-if="
        (buttonState === TournamentCtaStates.in_progress &&
          tournamentStatus === ActiveState.Qualification) ||
        (isMasters &&
          (tournamentStatus === ActiveState.Qualification ||
            tournamentStatus === ActiveState.NotStarted))
      "
      style="line-height: 1.7rem"
      class="text-texts-standard-default text-28"
    >
      <span class="colorize-gold">{{ $te('position') }}:</span>
      <strong>
        {{ isMasters ? qualificationPosition || rankingPosition || '---' : stateText }}
      </strong>
    </p>
    <p
      v-else-if="isMasters && tournamentStatus === ActiveState.Draw"
      class="text-texts-standard-default text-28"
    >
      <span class="colorize-gold">{{ mastersText }}</span>
    </p>
    <app-button
      v-if="buttonState === TournamentCtaStates.joinable"
      class="btn"
      btn-size="xsm"
      btn-type="confirm"
      :btn-text="$te('signUp')"
      style="width: 19.375rem; margin: 0.5rem"
      :loading="inAction"
      :disabled="inAction"
      @click="joinTournament"
    />
    <app-button
      v-if="
        buttonState === TournamentCtaStates.in_progress ||
        (buttonState === TournamentCtaStates.ended && joinStatus === JoinStatus.Joined) ||
        buttonState === TournamentCtaStates.masters_active
      "
      class="btn"
      btn-size="xsm"
      btn-type="secondary"
      :btn-text="$te('detail')"
      style="width: 19.375rem; margin: 0.5rem"
      :disabled="inAction"
      @click="openQualificationDetail"
    />
    <app-button
      v-if="buttonState === TournamentCtaStates.masters_inactive"
      class="btn"
      btn-size="xsm"
      :btn-text="$te('tournamentRanking')"
      :disabled="inAction"
      style="width: 19.375rem; margin: 0.5rem"
      @click="openLadders"
    />
    <p
      v-if="buttonState === TournamentCtaStates.not_joined"
      class="text-texts-standard-default text-30"
    >
      {{ $te('notSignedUp') }}
    </p>
  </section>
</template>

<script lang="ts">
import {
  ActiveState,
  DrawState,
  EliminationEnum,
  JoinStatus,
  ResultType,
} from '@/interfaces/events/MastersPlayoffInterfaces'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'

enum TournamentCtaStates {
  joinable = 1,
  joined,
  not_joined,
  in_progress,
  masters_inactive,
  masters_active,
  ended,
}

interface ComponentData {
  inAction: boolean
  JoinStatus: typeof JoinStatus
  ActiveState: typeof ActiveState
  TournamentCtaStates: typeof TournamentCtaStates
  ResultType: typeof ResultType
  EliminationEnum: typeof EliminationEnum
}

export default defineComponent({
  name: 'TournamentCta',
  props: {
    finalState: {
      type: String,
      default: '',
    },
    tournamentId: {
      type: Number,
      required: true,
    },
    isMasters: {
      type: Boolean,
      default: false,
    },
    isRecalculating: {
      type: Boolean,
      default: false,
    },
    notQualified: {
      type: Boolean,
      default: false,
    },
    joinStatus: {
      type: String as PropType<JoinStatus>,
      required: true,
    },
    tournamentStatus: {
      type: String as PropType<ActiveState>,
      required: true,
    },
    rankingPosition: {
      type: Number,
      required: false,
      default: 0,
    },
    qualificationPosition: {
      type: Number,
      required: false,
      default: 0,
    },
    isWinning: {
      type: Boolean,
      required: true,
    },
    eliminationReason: {
      type: String as PropType<EliminationEnum>,
      default: '',
    },
    drawState: {
      type: String as PropType<DrawState>,
      default: '',
    },
  },
  emits: ['joinTournament'],
  data(): ComponentData {
    return {
      inAction: false,
      JoinStatus,
      ActiveState,
      TournamentCtaStates,
      ResultType,
      EliminationEnum,
    }
  },
  computed: {
    buttonState(): TournamentCtaStates {
      if (this.isMasters) {
        if (
          this.tournamentStatus === ActiveState.Qualification ||
          this.tournamentStatus === ActiveState.NotStarted
        ) {
          return TournamentCtaStates.masters_inactive
        } else {
          return TournamentCtaStates.masters_active
        }
      }
      if (this.joinStatus === JoinStatus.NotJoined) {
        return TournamentCtaStates.not_joined
      }
      if (
        this.joinStatus === JoinStatus.CanJoin &&
        this.tournamentStatus === ActiveState.NotStarted
      ) {
        return TournamentCtaStates.joinable
      }
      if (
        this.joinStatus === JoinStatus.Joined &&
        this.tournamentStatus === ActiveState.NotStarted
      ) {
        return TournamentCtaStates.joined
      }
      if (
        (this.joinStatus === JoinStatus.Joined &&
          this.tournamentStatus === ActiveState.Qualification) ||
        (this.joinStatus === JoinStatus.Joined && this.tournamentStatus === ActiveState.Draw)
      ) {
        return TournamentCtaStates.in_progress
      }
      if (this.tournamentStatus === ActiveState.Ended) {
        return TournamentCtaStates.ended
      }
      return TournamentCtaStates.joinable
    },
    stateText(): string | number {
      if (
        this.joinStatus === JoinStatus.CanJoin &&
        this.tournamentStatus === ActiveState.NotStarted
      ) {
        return this.$te('signUpPrompt')
      }
      if (
        this.joinStatus === JoinStatus.Joined &&
        this.tournamentStatus === ActiveState.NotStarted
      ) {
        return this.$te('signedUp')
      }
      if (
        this.joinStatus === JoinStatus.Joined &&
        this.tournamentStatus === ActiveState.Qualification
      ) {
        return this.qualificationPosition
      }
      if (this.tournamentStatus === ActiveState.Draw) {
        // TOTO NIKDY NEMENTE EVER!!! PP
        if (this.eliminationReason === EliminationEnum.QuarterFinal) {
          return this.$te('playoffEliminatedInRound1')
        } else if (this.eliminationReason === EliminationEnum.SemiFinal) {
          return this.$te('playoffEliminatedInRound2')
        } else if (this.eliminationReason === EliminationEnum.Final) {
          return this.$te('playoffEliminatedInRound3')
        } else if (this.eliminationReason === EliminationEnum.Qualification) {
          return this.$te('playoffEliminated')
        } else if (this.drawState === DrawState.SemiFinal) {
          return this.$te('inSemifinal')
        } else if (this.drawState === DrawState.QuarterFinal) {
          return this.$te('inQuarterfinal')
        } else if (this.drawState === DrawState.Final) {
          return this.$te('inFinal')
        } else {
          return this.$te('playoffWinner')
        }
      }
      if (this.tournamentStatus === ActiveState.Ended) {
        if (this.eliminationReason === EliminationEnum.QuarterFinal) {
          return this.$te('playoffEliminatedInRound1')
        } else if (this.eliminationReason === EliminationEnum.SemiFinal) {
          return this.$te('playoffEliminatedInRound2')
        } else if (this.eliminationReason === EliminationEnum.Final) {
          return this.$te('playoffEliminatedInRound3')
        } else if (this.eliminationReason === EliminationEnum.Qualification) {
          return this.$te('playoffEliminated')
        } else {
          return this.$te('playoffWinner')
        }
      }
      return 'unknown'
    },
    mastersText(): string {
      if (this.drawState === DrawState.SemiFinal) {
        return this.$te('playoffDrawRound2')
      } else if (this.drawState === DrawState.QuarterFinal) {
        return this.$te('playoffDrawRound1')
      } else {
        return this.$te('playoffDrawRound3')
      }
    },
    addActivePlayClass(): string {
      if (
        this.tournamentStatus === ActiveState.NotStarted ||
        this.tournamentStatus === ActiveState.Ended ||
        this.joinStatus === JoinStatus.NotJoined ||
        this.isRecalculating
      )
        return ''
      if (this.isWinning) return 'is-winning'
      return 'is-losing'
    },
  },
  methods: {
    joinTournament(): void {
      this.inAction = true
      this.$emit('joinTournament')
      this.inAction = false
    },
    openQualificationDetail(): void {
      this.inAction = true
      this.$router.push({
        query: { tournamentId: this.tournamentId },
      })
      this.inAction = false
    },
    openLadders(): void {
      this.inAction = true
      this.$router.push({
        query: { component: 'ladder' },
      })
      this.inAction = false
    },
  },
})
</script>

<style lang="scss" scoped>
.cta {
  width: 25.15rem;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  clip-path: polygon(5.25% 0%, 100% 0%, 100% 100%, 0% 100%);
  padding-left: 0.5rem;

  @if $isWsm {
    background-color: #0f2e52;
  }

  @if $isSsm {
    background-color: #23293e;
  }

  &.loss {
    background-color: #7d2424;
  }

  &.won {
    background-color: #136426;
  }

  &.is-winning {
    background-color: #136426;
  }

  &.is-losing {
    background-color: #7d2424;
  }

  &.is-masters {
    background-color: #125b26;

    &.no-qualify {
      background-color: #846702;
    }
  }

  .colorize-gold {
    color: theme('colors.texts.standard.important');
  }

  .btn {
    height: 4.375rem;
  }
}
</style>
