import type {
  BuildingRewardConfigInterface,
  BuildingRewardDataInterface,
  BuildingHtmlTextConfigInterface,
} from '@/map-phaser-new/interfaces'
import { useRewardStore } from '@/store/pinia/clubs/rewardStore'
import { BUILDING_REWARD_CONTAINER_NAMES } from '@/map-phaser-new/constants'
import { pathImages } from '@/globalVariables'

export class BuildingReward {
  private buildingRewardContainer: Phaser.GameObjects.Container | null = null

  constructor() {}

  /**
   *
   * @param activeScene Scene where reward will be added.
   * @param buildingImage Image of building for which reward will be added.
   * @param config Contains config data for reward.
   * @param rewardData Contains dynamic data for reward.
   */
  public addToBuilding(
    activeScene: Phaser.Scene,
    buildingImage: Phaser.GameObjects.Image,
    config: BuildingRewardConfigInterface,
    rewardData: BuildingRewardDataInterface,
  ): void {
    this.addBuildingReward(activeScene, buildingImage, config, rewardData)
  }

  public destroyReward(): void {
    if (!this.buildingRewardContainer) return

    let backgroundImage = this.buildingRewardContainer.getByName(
      BUILDING_REWARD_CONTAINER_NAMES.BackgroundReward,
    )
    backgroundImage.removeAllListeners()
    backgroundImage = null
    this.buildingRewardContainer.removeAll(true)
    this.buildingRewardContainer.destroy()
    this.buildingRewardContainer = null
  }

  private addBuildingReward(
    activeScene: Phaser.Scene,
    buildingImage: Phaser.GameObjects.Image,
    config: BuildingRewardConfigInterface,
    rewardData: BuildingRewardDataInterface,
  ): void {
    this.buildingRewardContainer = activeScene.add
      .container(buildingImage.x + buildingImage.width / 2, buildingImage.y)
      .setDepth(config.rewardDepth)

    const backgroundImage = activeScene.add
      .dom(
        0,
        0,
        'span',
        `width: ${config.backgroundSize.width}; height: ${config.backgroundSize.height}; background: url(${pathImages}/map/club-reward-bg.avif) no-repeat 0 0; cursor: pointer;`,
      )
      .setName(config.backgroundTextureName)
      .setInteractive({ useHandCursor: true })
      .addListener('click')

    backgroundImage.setName(BUILDING_REWARD_CONTAINER_NAMES.BackgroundReward)

    const rewardImage = activeScene.add
      .dom(0, 0, 'span')
      .setClassName(`icon-${rewardData.name.toLowerCase()}-90 icon-in-pixels`)
      .setName(config.rewardTextureName)
      .setScale(config.rewardTextureScale)
      .setOrigin(config.rewardTextureOrigin.x, config.rewardTextureOrigin.y)
    rewardImage.pointerEvents = 'none'

    const titleText = this.addTitleText(
      activeScene,
      config.htmlTextConfig,
      rewardData.value.toString(),
    ).setOrigin(config.valueTextureOrigin.x, config.valueTextureOrigin.y)

    this.buildingRewardContainer.add([backgroundImage, rewardImage, titleText])

    this.addRewardListener(backgroundImage, rewardData.id)
  }

  private addRewardListener(image: Phaser.GameObjects.DOMElement, rewardId: string): void {
    image.on('click', (event: MouseEvent | TouchEvent) => {
      event.stopPropagation()
      useRewardStore().claimRewards(rewardId)
      this.destroyReward()
    })
  }

  private addTitleText(
    activeScene: Phaser.Scene,
    config: BuildingHtmlTextConfigInterface,
    title: string,
  ): Phaser.GameObjects.DOMElement {
    const rewardText = activeScene.add.dom(
      0,
      0,
      'span',
      `color: ${config.fontColor}; font-size: ${config.fontSize}; font-family: ${config.fontName}; text-transform: uppercase; font-weight: ${config.fontWeight}; font-style: ${config.fontStyle};`,
      title,
    )
    rewardText.pointerEvents = 'none'
    return rewardText
  }
}
