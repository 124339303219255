import type { Mode } from '@/interfaces/Mode'

export const routeObject = (
  modes: Mode,
): {
  [x: number]: {
    route: string
  }
} => {
  return {
    [modes.duel]: {
      route: 'ArenaReport',
    },
    [modes.tutorial]: {
      route: 'LayoutViewTutorial',
    },
    [modes.task_training]: {
      route: 'TrainingDisciplineDetail',
    },
    [modes.task_training_trainer]: {
      route: 'TrainingTrainer',
    },
    [modes.training_tutorial]: {
      route: 'TrainingTutorial',
    },
    [modes.boss_fight]: {
      route: 'CareerReport',
    },
    [modes.daily_league]: {
      route: 'StarofthedayDisciplineDetail',
    },
    [modes.club_duel]: {
      route: 'ClubsChampionshipReport',
    },
    [modes.event_boss_fight]: {
      route: 'EventsTaskchain',
    },
  }
}
export const allowedDisciplineModes = (modes: Mode): number[] => {
  return [modes.duel, modes.task_training, modes.daily_league, modes.event_boss_fight]
}
