import type { ImageLoadData } from '@/map-phaser-new/interfaces'

export const mainMapImageLoadData: () => ImageLoadData[] = () => [
  {
    name: 'map',
    compressed: true,
    data: {
      astc: {
        type: 'KTX',
        textureURL: 'map/compressed/mainMap-astc12x12.ktx',
      },
      etc: {
        type: 'KTX',
        textureURL: 'map/compressed/mainMap-etc2RGBA.ktxr',
      },
      img: {
        textureURL: 'map/mapNew.avif',
      },
      place: 'map/mapNew.avif',
    },
  },
  {
    name: 'overlayimage',
    compressed: true,
    data: {
      astc: {
        type: 'KTX',
        textureURL: 'map/compressed/overlay-astc12x12-2.ktx?v2',
      },
      etc: {
        type: 'KTX',
        textureURL: 'map/compressed/overlay-etc2RGBA.ktx',
      },
      img: {
        textureURL: 'map/overlay.avif?v1',
      },
      place: 'map/overlay.avif?v1',
    },
  },
  {
    name: 'premium_bg',
    compressed: false,
    data: {
      place: 'map/PREMIUM_1_BG.png',
    },
  },
  {
    name: 'building_new_label',
    compressed: false,
    data: {
      place: 'map/building_new_label.avif',
    },
  },
  {
    name: 'building_championship_bg',
    compressed: false,
    data: {
      place: 'map/building_championship_bg.avif',
    },
  },
  {
    name: 'lock',
    compressed: false,
    data: {
      place: 'icons/icon-lock-lg.avif',
    },
  },
  {
    name: 'blocked',
    compressed: false,
    data: {
      place: 'icons/icon-blocked.avif',
    },
  },
  {
    name: 'timerbar',
    compressed: false,
    data: {
      place: 'lab/timerbar.avif',
    },
  },
  {
    name: 'exclamationMarkNotification',
    compressed: false,
    data: {
      place: 'map/info_bubble.avif',
    },
  },
  {
    name: 'bubbleNotification',
    compressed: false,
    data: {
      place: 'map/bubble_notification.avif',
    },
  },
  {
    name: 'careerArrow',
    compressed: false,
    data: {
      place: 'map/career-map-arrow-yellow.avif',
    },
  },
  {
    name: 'link_account_image',
    compressed: false,
    data: {
      place: 'map/link-account-image.avif',
    },
  },
  {
    name: 'board_games_event_osra_image',
    compressed: false,
    data: {
      place: 'map/board-games-event-osra.avif',
    },
  },
  {
    name: 'board_games_event_osra_image_outline',
    compressed: false,
    data: {
      place: 'map/board-games-event-osra-outline.avif',
    },
  },
  {
    name: 'piggy_bank_osra_image',
    compressed: false,
    data: {
      place: 'map/piggy-bank-osra.avif',
    },
  },
  {
    name: 'piggy_bank_osra_image_outline',
    compressed: false,
    data: {
      place: 'map/piggy-bank-osra-outline.avif',
    },
  },
  {
    name: 'building_tooltip_top',
    compressed: false,
    data: {
      place: 'map/building_tooltip_top.avif',
    },
  },
  {
    name: 'building_tooltip_left',
    compressed: false,
    data: {
      place: 'map/building_tooltip_left.avif',
    },
  },
  {
    name: 'building_tooltip_right',
    compressed: false,
    data: {
      place: 'map/building_tooltip_right.avif',
    },
  },
]
