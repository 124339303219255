<template>
  <div class="notification-message">
    <div class="window-overlay" />
    <div class="notification-message-content">
      <header class="flexing">
        <h2 class="text-texts-standard-additional text-40 uppercase">
          {{ $t('common.pushNotificationRewardTitle') }}
        </h2>
      </header>
      <main>
        <h2 class="text-texts-standard-default text-30 my-4">
          {{ $t('common.youReceived') }}
        </h2>
        <aside class="flexing">
          <reward-box
            v-for="(reward, index) in notificationData.rewards"
            :key="index"
            :reward-icon="72"
            :reward-icon-name="reward.name"
            :reward-number="reward.value"
            :reward-icon-rarity="reward.data?.rarity ? reward.data.rarity : ''"
          />
        </aside>
      </main>
    </div>
    <div class="notification-message-button" :class="$isMobile() ? 'mobile' : 'web'">
      <app-button
        btn-id="notification-btn-id"
        btn-type="secondary"
        :btn-text="$t('button.continue')"
        btn-size="md"
        @click="hideMessage"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { reportsRewardsClaimEndpoint } from '@/globalVariables'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { mapActions } from 'pinia'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { Nullable } from '@/interfaces/utils'
import type { ReportsDetailApiResponse } from '@/interfaces/responses/reports/ReportsApiResponse'

interface NotificationData {
  report_id: number
  rewards: RewardType[]
}

interface RewardType {
  amount: number
  name: string
  value: number
  data: {
    rarity: string
  }
}

export default defineComponent({
  name: 'NotificationMessages',
  props: {
    notificationData: {
      type: Object as PropType<Nullable<NotificationData>>,
      default: () => null,
    },
  },
  methods: {
    ...mapActions(useResponseTaskStore, ['removeLastMobileNotificationMessage']),
    async hideMessage(): Promise<void> {
      await this.$axios.put<{}, ReportsDetailApiResponse>(reportsRewardsClaimEndpoint, {
        reports_id: this.notificationData.report_id,
      })
      this.removeLastMobileNotificationMessage()
    },
  },
})
</script>

<style lang="scss" scoped>
.notification-message {
  &-content {
    width: 100%;
    height: 20rem;
    z-index: 1;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0px 0.313rem 0.813rem 0 #272727;
    background: linear-gradient(
      to right,
      transparent,
      #154e79 10%,
      #0f2a4e 40%,
      #0b1e37 50%,
      #0f2a4e 60%,
      #154e79 90%,
      transparent
    );

    header {
      width: 100%;
      height: 5rem;
      background-image: url($path-components + 'progressMessage/BG_TOP_CONTENT.avif');
      background-size: cover;
      background-repeat: no-repeat;
    }

    main {
      width: 100%;
      height: 10.9375rem;

      .progress-message-text {
        margin: 0.6875rem auto;
        position: relative;

        &:before {
          content: '';
          width: 100%;
          height: 0.125rem;
          background: linear-gradient(
            to right,
            rgba(251, 227, 110, 0) 0%,
            #154e77 12%,
            #fff 50%,
            #154e79 88%,
            rgba(251, 227, 110, 0) 100%
          );
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }
    }
  }

  &-button {
    position: absolute;
    z-index: 1;

    &.web {
      top: 65%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &.mobile {
      bottom: 2rem;
      right: 2rem;
    }
  }
}
</style>
