import type { EventBadgeInterface } from '@/interfaces/EventBadgeInterface'
import type Reward from '@/interfaces/Reward'
export interface RankingUser {
  id?: string
  name?: string
  points?: number
  level?: number
  clubId?: string
  clubLogoId?: number
  clubLogoBgId?: number
  eventBadges?: EventBadgeInterface[]
  username?: string
  club?: string
  club_id?: string
  country?: string
  LEVEL?: number
  RANKING_POINTS?: number
  experience?: number
  training_points?: number
  isBot?: boolean
  position?: number
  result?: number
  event_badges?: EventBadgeInterface[]
  club_logo_id?: number
  season?: string
  members?: number
  type?: string
  discipline_id?: number
  club_background_logo_id?: number
  rewards?: Reward[]
}

export enum RankingType {
  Global = 'global',
  Disciplines = 'disciplines',
  Records = 'records',
  ClubActivity = 'clubActivity',
  ClubLeaderboard = 'clubLeaderboard',
}
