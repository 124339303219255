<template>
  <div>
    <teleport to="#app">
      <transition name="fade-only-in">
        <error-message v-if="axiosErrors.length > 0 && showErrorMessage" :errors="axiosErrors" />
      </transition>
    </teleport>
    <offline-popup v-if="offline" />
    <building-level-up v-if="levelUp" :level-data="levelUp" />
    <grand-prize-achievement v-if="achievementGP" :achievement="achievementGP" />
    <session-message
      v-for="(session, index) in sessionMessage"
      :key="index"
      :session-data="session"
      :time-to-hide="getTimeToHide(index)"
    />
    <progress-messages />
    <level-up v-if="userLevelUp" :level-up-data="userLevelUp" />
    <career-rewards v-if="questCompleted" :quest-completed-data="questCompleted" />
    <level-up-discipline v-if="disciplineId" :dicipline-id="disciplineId" />
    <teleport to="#app">
      <transition name="fade-only-in">
        <payment-status-message v-if="paymentMessage" />
      </transition>
    </teleport>
    <tutorial-completed
      v-if="actualStageData && actualStageData.name === 'valleyTutorialEndCareer'"
      :rewards="actualStageData.unlocks"
    />
    <teleport to="#app">
      <transition name="fade-only-in">
        <notification-messages
          v-if="mobileNotificationMessages.length > 0 && !isTutorial"
          :notification-data="mobileNotificationMessages[0]"
        />
      </transition>
    </teleport>
    <payment-failed-message v-if="paymentFailed" />

    <offer-detail
      v-if="
        offerDetail &&
        !isTutorial &&
        !isChampionPass(offerDetail) &&
        getCurrentComponentInQueue === RenderQueueComponent.OfferDetail
      "
      :offer-data="offerDetail"
    />
    <!-- KLUBY -  ZMENY ULOZENE -->
    <clubs-settings-popup v-if="changesSaved" type="settings" />
    <!-- KLUBY -  KLUB ZRUSENY -->
    <clubs-settings-popup v-if="clubDeleted" type="delete" />

    <!-- KLUBY -  ASISTENT -->
    <clubs-management-popup v-if="userPromoted.status" :user="userPromoted.user" />

    <!-- CLUBS - PROMOTED - ASSISTENT - MANAGER -->
    <clubs-management-popup v-if="showPromotedPopUp" :user="promotedUser" :is-promoted="true" />

    <!-- KLUBY - VYTVORENIE KLUBU -->
    <clubs-create-join-popup v-if="clubCreated" type="create" />

    <!-- KLUBY - PRIDANIE CLENA DO KLUBU -->
    <clubs-create-join-popup v-if="joinedToClub || isInvitationAccepted" type="join" />

    <!-- KLUBY -  ODMENY -->
    <clubs-rewards-popup v-if="false" />

    <!-- KLUBY - PODPISANA ZMLUVA -->
    <clubs-signed-contract v-if="getSignedContract" />
    <championship-summary v-if="championshipSummary" :summary-data="championshipSummary" />

    <clubs-reminder v-if="showClubsReminderPopup" />

    <blocked-user-popup v-if="isUserBlocked" />

    <fail-video-popup v-if="isAdBlock" @close-fail-video-popup="closeFailPopup()" />

    <account-connected-popup v-if="showAccountConnectedPopup" />

    <teleport to="#app">
      <transition name="fade-only-in">
        <flash-news-message v-if="getShowFlashNewsMessage" />
      </transition>
    </teleport>
  </div>
</template>

<script lang="ts">
import OfflinePopup from './Popup/OfflinePopup.vue'
import BlockedUserPopup from '@/components/BlockedUserPopup.vue'
import BuildingLevelUp from '@/components/BuildingLevelUp.vue'
import CareerRewards from '@/components/Career/CareerRewards.vue'
import ChampionshipSummary from '@/components/Club/Championship/ChampionshipSummary.vue'
import ClubsReminder from '@/components/Club/ClubsReminder.vue'
import ClubsCreateJoinPopup from '@/components/Club/Popups/ClubsCreateJoinPopup.vue'
import ClubsManagementPopup from '@/components/Club/Popups/ClubsManagementPopup.vue'
import ClubsRewardsPopup from '@/components/Club/Popups/ClubsRewardsPopup.vue'
import ClubsSettingsPopup from '@/components/Club/Popups/ClubsSettingsPopup.vue'
import ClubsSignedContract from '@/components/Club/Popups/ClubsSignedContract.vue'
import ErrorMessage from '@/components/ErrorMessage.vue'
import GrandPrizeAchievement from '@/components/GrandPrize/GrandPrizeAchievement.vue'
import LevelUp from '@/components/LevelUp/LevelUp.vue'
import LevelUpDiscipline from '@/components/LevelUpDiscipline.vue'
import NotificationMessages from '@/components/NotificationMessages.vue'
import OfferDetail from '@/components/OfferDetail.vue'
import PaymentFailedMessage from '@/components/PaymentFailedMessage.vue'
import PaymentStatusMessage from '@/components/PaymentStatusMessage.vue'
import AccountConnectedPopup from '@/components/Popup/AccountConnectedPopup.vue'
import FailVideoPopup from '@/components/Popup/FailVideoPopup.vue'
import TutorialCompleted from '@/components/Popup/Tutorial/TutorialCompleted.vue'
import ProgressMessages from '@/components/ProgressMessages.vue'
import SessionMessage from '@/components/SessionMessage.vue'
import FlashNewsMessage from '@/components/FlashNewsMessage.vue'
import { CLUB_ASSISTANT, CLUB_MANAGER, MINIGAME_ID } from '@/globalVariables'
import { isChampionPass } from '@/helpers'
import { useAdStore } from '@/store/pinia/adStore'
import { useClubStore } from '@/store/pinia/clubs/clubStore'
import { useEmployeeState } from '@/store/pinia/clubs/employeeStore'
import { useManagementStore } from '@/store/pinia/clubs/managementStore'
import { useNotificationStore } from '@/store/pinia/clubs/notificationStore'
import { useCoreStore } from '@/store/pinia/coreStore'
import { usePremiumStore } from '@/store/pinia/premiumStore'
import { useRenderQueueStore } from '@/store/pinia/renderQueueStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useMainSeasonStore } from '@/store/pinia/seasons/mainSeasonsStore'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import { useUserStore } from '@/store/pinia/userStore'
import { useFlashNewsStore } from '@/store/pinia/flashNews'
import { RenderQueueComponent } from '@/enums/RenderQueueTypes'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

interface UserData {
  name: string
  country: string
  role: string
}

interface ComponentData {
  RenderQueueComponent: typeof RenderQueueComponent
}

export default defineComponent({
  name: 'PopupsComponent',
  components: {
    OfflinePopup,
    ErrorMessage,
    BuildingLevelUp,
    GrandPrizeAchievement,
    SessionMessage,
    ProgressMessages,
    LevelUp,
    CareerRewards,
    LevelUpDiscipline,
    PaymentStatusMessage,
    PaymentFailedMessage,
    TutorialCompleted,
    NotificationMessages,
    OfferDetail,
    ClubsSettingsPopup,
    ClubsManagementPopup,
    ClubsRewardsPopup,
    ClubsCreateJoinPopup,
    ClubsSignedContract,
    ChampionshipSummary,
    ClubsReminder,
    BlockedUserPopup,
    FailVideoPopup,
    AccountConnectedPopup,
    FlashNewsMessage,
  },
  data(): ComponentData {
    return {
      RenderQueueComponent,
    }
  },
  computed: {
    ...mapState(useAdStore, { isAdBlock: 'getIsAdBlock' }),
    ...mapState(usePremiumStore, { offerDetail: 'getOfferDetail' }),
    ...mapState(useUserStore, {
      userData: 'getUserData',
      showAccountConnectedPopup: 'showAccountConnectedPopup',
    }),
    ...mapState(useTutorialStore, {
      actualStageData: 'getActualStage',
      isTutorial: 'getIsTutorial',
    }),
    ...mapState(useCoreStore, {
      axiosErrors: 'getAxiosErrors',
      hasBadCredentials: 'hasBadCredentials',
      emailExists: 'emailExists',
      isLoading: 'isLoadingScreen',
      offline: 'getOffline',
    }),
    ...mapState(useManagementStore, {
      userPromoted: 'getUserPromoted',
    }),
    ...mapState(useNotificationStore, {
      isInvitationAccepted: 'getInvitationAccepted',
      promotedRole: 'getPromotedRole',
    }),
    ...mapState(useEmployeeState, ['getSignedContract']),
    ...mapState(useClubStore, {
      changesSaved: 'changesSaved',
      clubDeleted: 'clubDeleted',
      clubCreated: 'clubCreated',
      joinedToClub: 'joinedToClub',
    }),
    ...mapState(useRenderQueueStore, ['getCurrentComponentInQueue']),
    ...mapState(useMainSeasonStore, {
      isSeasonModalVisible: 'getSeasonModalState',
    }),
    ...mapState(useResponseTaskStore, {
      levelUp: 'getLevelUp',
      achievementGP: 'getAchievement',
      userLevelUp: 'getUserLevelUp',
      questCompleted: 'getQuestCompleted',
      disciplineId: 'getDisciplineBranchCompleted',
      paymentMessage: 'getPaymentMessage',
      paymentFailed: 'getPaymentFailed',
      sessionMessage: 'getSessionMessage',
      mobileNotificationMessages: 'getMobileNotificationMessages',
      championshipSummary: 'getChampionshipSummary',
      showClubsReminderPopup: 'showClubsReminderPopup',
      isUserBlocked: 'isUserBlocked',
    }),
    ...mapState(useFlashNewsStore, ['getShowFlashNewsMessage']),
    promotedUser(): UserData {
      return {
        name: this.userData.username ?? '',
        country: this.userData.country?.three_letter_code ?? '',
        role: this.promotedRole ?? 'member',
      }
    },
    showPromotedPopUp(): boolean {
      return [CLUB_MANAGER, CLUB_ASSISTANT].includes(this.promotedRole) && !this.isLoading
    },
    showErrorMessage(): boolean {
      return !this.hasBadCredentials && !this.emailExists
    },
    hasMinigameError(): string {
      return sessionStorage.getItem(MINIGAME_ID)
    },
  },
  watch: {
    isSeasonModalVisible(isSeasonVisible: boolean): void {
      // (i) BE sends tasks.showSeasonSummary from LvlUp 3 and above
      if (
        isSeasonVisible &&
        !this.isTutorial &&
        this.getCurrentComponentInQueue === RenderQueueComponent.SeasonsEnd
      ) {
        this.$router.push({ name: this.$getWebView('SeasonsEnd') })
      }
    },
  },
  methods: {
    ...mapActions(useAdStore, {
      setAdBlockState: 'setAdBlockState',
    }),
    ...mapActions(usePremiumStore, ['setWebVideoPlay', 'setVideoPlay']),
    isChampionPass,
    closeFailPopup(): void {
      this.setAdBlockState(false)
      this.setWebVideoPlay(false)
      this.setVideoPlay(false)
    },
    getTimeToHide(index: number): number {
      return 3000 * (index + 1)
    },
  },
})
</script>
