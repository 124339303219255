<template>
  <div id="settings" class="settings-wrapper app-page-wrapper absolute">
    <user-id-box class="absolute id-box" :class="{ 'id-box-web': !$isMobile() }" />
    <menu-component
      menu-type="settings"
      :title="$t('settings.settings')"
      :sub-menu-active="true"
      :sub-menu="{
        1: {
          text: $t('settings.global'),
          route: $getWebView('Settings'),
          current: $getWebView('Settings'),
        },
        2: {
          text: $t('settings.audio'),
          route: $getWebView('Audio'),
        },
        3: {
          text: $t('settings.account'),
          route: $getWebView('Account'),
        },
      }"
    />

    <section class="w-full h-full safe-area">
      <main class="settings relative w-full">
        <app-scrollbar
          :height="$isMobile() ? '75vh' : '49rem'"
          width="100%"
          slide="y"
          scroll="y"
          class="settings-scroll"
          :options="{ suppressScrollY: false, suppressScrollX: true }"
        >
          <settings-box :title="$t('settings.language')">
            <app-select
              :options="formatedLanguages"
              :default="selectedLanguage"
              @select="selectLanguage"
            />
          </settings-box>
          <settings-box
            :title="$t('settings.competitionReport')"
            :description="$t('settings.competitionReportDescription')"
          >
            <app-toggle
              :texts="toggleTexts"
              :selected-button="formatToggleValue(competitionReport)"
              @action="toggle('competitionReport', $event)"
            />
          </settings-box>
          <settings-box
            :title="$t('mobileApp.notifications')"
            :description="$t('settings.notificationsDescription')"
          >
            <app-toggle
              :texts="toggleTexts"
              :selected-button="formatToggleValue(showPushNotifications)"
              @action="sendNotificationAction($event)"
            />
          </settings-box>
          <settings-box
            :title="$t('settings.buildingsTitles')"
            :description="$t('settings.buildingsTitlesDescription')"
          >
            <app-toggle
              :texts="toggleTexts"
              :selected-button="formatToggleValue(showBuildingsTitles)"
              @action="toggle('buildingsTitles', $event)"
            />
          </settings-box>
          <settings-box :title="$t('common.privacy')">
            <app-button
              class="settings-button"
              btn-id="settings-btn-privacy"
              btn-type="confirm"
              :btn-text="$t('common.readMore')"
              @click="redirect('privacy-policy')"
            />
          </settings-box>
          <settings-box :title="$t('common.termsOfUse')">
            <app-button
              class="settings-btn"
              btn-id="settings-btn-terms"
              btn-type="confirm"
              :btn-text="$t('common.readMore')"
              @click="redirect('license')"
            />
          </settings-box>
          <settings-box v-if="!$isMobile()" :title="'Cookies'">
            <app-button
              class="settings-btn"
              btn-id="settings-btn-terms"
              btn-type="confirm"
              :btn-text="$t('settings.cookieSettings')"
              @click="showCookieSettings"
            />
          </settings-box>
        </app-scrollbar>
      </main>
    </section>
  </div>
</template>

<script lang="ts">
import AppToggle from '@/components/GlobalComponents/AppToggle.vue'
import SettingsBox from '@/components/Settings/SettingsBox.vue'
import UserIdBox from '@/components/Settings/UserIdBox.vue'
import { userProfileEndpoint } from '@/globalVariables'
import { getCookieConsent, sendToFlutter } from '@/helpers'
import { useCountriesStore } from '@/store/pinia/countriesStore'
import { useUserStore } from '@/store/pinia/userStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import { formatToggleValue } from './formatToggleValue'

import type { Attribute } from '@/interfaces/User'
import type { UserProfile } from '@/types/userProfile'
import { usePhaserGameIntegrationStore } from '@/store/pinia/map-new/phaserGameIntegrationStore'

interface ComponentData {
  setNotifications: string
  setLanguage: string
  toggleTexts: string[]
}

export default defineComponent({
  name: 'SettingsComponent',
  components: {
    UserIdBox,
    SettingsBox,
    AppToggle,
  },
  data(): ComponentData {
    return {
      // eslint-disable-next-line no-useless-escape
      setNotifications: '{\r\n  "event": "setNotifications",\r\n "enabled": true\r\n}',
      // eslint-disable-next-line no-useless-escape
      setLanguage: '{\r\n  "event": "setLanguage",\r\n "language": "en"\r\n}',
      toggleTexts: ['On', 'Off'],
    }
  },
  computed: {
    ...mapState(useCountriesStore, {
      formatedLanguages: 'getFormattedLanguages',
    }),
    ...mapState(useUserStore, {
      selectedLanguage: 'getLanguage',
      competitionReport: 'getShowResultScreen',
      showPushNotifications: 'showPushNotifications',
      showBuildingsTitles: 'showBuildingsTitles',
    }),
  },
  methods: {
    ...mapActions(useCountriesStore, ['loadCountries']),
    ...mapActions(usePhaserGameIntegrationStore, ['setBuildingsTitlesVisibility']),
    ...mapActions(useUserStore, ['setProfileAttributes', 'loadUserData']),
    formatToggleValue,
    async toggle(category: string, selectedOption: number): Promise<void> {
      switch (category) {
        case 'competitionReport':
          this.setProfileAttributes({
            name: 'show_result_screen',
            value: selectedOption === 1 ? 1 : 0,
          })
          return
        case 'notifications':
          this.setProfileAttributes({
            name: 'push_notifications',
            value: selectedOption === 1 ? 1 : 0,
          })
          return
        case 'buildingsTitles':
          await this.setProfileAttributes({
            name: 'buildings_names',
            value: selectedOption === 1 ? 1 : 0,
          })
          this.setBuildingsTitlesVisibility(selectedOption === 1)
          return
        case 'transactionsAlert':
          this.setProfileAttributes({
            name: 'gems_transactions_warnings',
            value: selectedOption === 1 ? 1 : 0,
          })
      }
    },
    sendNotificationAction(e: number): void {
      this.toggle('notifications', e)
      this.sendNotification(e)
    },
    redirect(type: string): void {
      const language = ['sk', 'cs'].includes(this.selectedLanguage) ? 'sk' : 'en'
      window.open(`https://www.powerplay.studio/${language}/${type}/`, '_blank')
    },
    async selectLanguage(languageId: string): Promise<void> {
      localStorage.removeItem('language')
      if (languageId) {
        await this.$axios.post<{}, UserProfile, { profile_attributes: Attribute[] }>(
          userProfileEndpoint,
          {
            profile_attributes: [
              {
                name: 'language',
                value: languageId,
              },
            ],
          },
        )
        // eslint-disable-next-line no-useless-escape
        this.setLanguage =
          '{\r\n  "event": "setLanguage",\r\n "language": "' + languageId + '"\r\n}'
        sendToFlutter(this.setLanguage)
        sessionStorage.removeItem('texts')
        this.loadUserData()
        await this.loadCountries(true)
      }
    },
    sendNotification(status: number): void {
      // eslint-disable-next-line no-useless-escape
      this.setNotifications =
        '{\r\n  "event": "setNotifications",\r\n "enabled": ' + (status === 1) + '\r\n}'
      sendToFlutter(this.setNotifications)
    },
    showCookieSettings(): void {
      getCookieConsent()?.showSettings(0)
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/views/settings/settings.scss';

.id-box-web {
  right: 2rem;
  justify-content: flex-end;

  :deep(.hamburger-menu-footer-id-content) {
    margin: 0;
  }
}
</style>
