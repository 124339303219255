<template>
  <footer>
    <div
      v-if="!quest.isLocked && !quest.isFinalQuest"
      class="flex justify-between items-center w-full"
    >
      <info-text
        :text-size="32"
        :text="
          disciplineId === 0 || !quest.isActive
            ? $t('career.progressInQuestToUnlock')
            : $t('career.trackQuestToSeeInTracker')
        "
      />
      <arrow-animation border="button" position="right" :tutorial="isTutorialStep">
        <app-multi-button1
          v-if="quest.isActive && disciplineId !== 0"
          v-tippy="
            isTrackerFull &&
            !isTaskTracked && {
              theme: 'WSM',
              content: $t('career.taskTrackerFullInfo'),
              placement: 'top',
            }
          "
          btn-theme="dark"
          btn-id="tracker-btn"
          :btn-text="setTrackerBtnText"
          :custom-left-width="22"
          :disabled="isTrackerFull && !isTaskTracked"
          class="app-multi-button--tracker"
          @click="isTrackerFull && !isTaskTracked ? {} : toggleTaskTracking()"
        >
          <template #rightPart>
            <app-check-box
              v-if="quest"
              :is-checked="isTaskTracked"
              :is-transparent-background="true"
            />
          </template>
        </app-multi-button1>
      </arrow-animation>
    </div>

    <div v-if="quest.isLocked" class="flexing w-full">
      <info-text
        :text-size="36"
        class="quest-locked-text"
        :text="$t('career.finishPreviousQuest')"
      />
    </div>

    <div v-if="quest.isFinalQuest" class="flexing">
      <info-text :text-size="36" :text="$t('career.nextQuestsInFuture')" />
    </div>
  </footer>
</template>

<script lang="ts">
import ArrowAnimation from '@/components/ArrowAnimation.vue'
import InfoText from '@/components/InfoText.vue'
import { MECHANIC_CAREER } from '@/globalVariables'
import { setTasksActiveStates } from '@/helpers'
import { useTaskTrackerStore } from '@/store/pinia/career/taskTracker'
import { useDisciplineStore } from '@/store/pinia/disciplinesStore'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

import type { PropType } from 'vue'
import type { CareerQuest } from '@/interfaces/Quest'

interface ComponentData {
  showCancel: boolean
  showAccept: boolean
  isTaskTracked: boolean
  buttonCancel: {
    btnId: string
    btnSize: string
    btnType: string
  }
  taskId: number
}

export default defineComponent({
  name: 'CareerFooter',
  components: {
    InfoText,
    ArrowAnimation,
  },
  props: {
    quest: {
      type: Object as PropType<CareerQuest>,
      required: true,
    },
    disciplineId: {
      type: Number,
      default: 0,
    },
    careerId: {
      type: String,
      default: '',
    },
  },
  data(): ComponentData {
    return {
      showCancel: false,
      showAccept: false,
      isTaskTracked: false,
      buttonCancel: {
        btnId: 'management-button',
        btnSize: 'xl',
        btnType: 'danger',
      },
      taskId: 0,
    }
  },
  computed: {
    ...mapState(useTutorialStore, {
      actualStageData: 'getActualStage',
      isTutorial: 'getIsTutorial',
    }),
    ...mapState(useTaskTrackerStore, ['getTaskTrackerCapacityStatus']),
    isTutorialStep(): boolean {
      return this.actualStageData?.name === 'clickTrackTaskCareer'
    },
    isTrackerFull(): boolean {
      return this.getTaskTrackerCapacityStatus
    },
    setTrackerBtnText(): string {
      return this.isTaskTracked ? this.$t('career.trackingQuest') : this.$t('career.trackQuest')
    },
  },
  created(): void {
    this.isTaskTracked = this.quest.isTracked
  },
  methods: {
    ...mapActions(useTaskTrackerStore, {
      addTrackingTask: 'addTrackingTask',
      removeTrackingTask: 'removeTrackingTask',
      loadTaskTrackerQuests: 'loadTaskTrackerQuests',
    }),
    ...mapActions(useDisciplineStore, ['loadDisciplinesForMenu']),
    async toggleTaskTracking(): Promise<void> {
      if (!this.isTaskTracked) {
        await this.addTrackingTask(this.careerId)
      } else {
        await this.removeTrackingTask(this.careerId)
      }
      this.isTaskTracked = !this.isTaskTracked

      if (this.isTutorial) {
        const tasksIndexes = [0, 1]

        tasksIndexes.forEach((index: number): void => {
          setTasksActiveStates({ state: true, index })
        })
      }

      this.loadDisciplinesForMenu(MECHANIC_CAREER, true)
      await this.loadTaskTrackerQuests()
    },
  },
})
</script>

<style lang="scss" scoped>
footer {
  width: 99.5%;
  height: 6rem;
  display: flex;
  align-items: self-end;
  justify-content: center;
  margin-top: 1.25rem;

  .quest-locked-text {
    width: 100%;
    height: 5.5rem;
    justify-content: center;
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    @if $isWsm {
      background: linear-gradient(
        90deg,
        rgba(103, 36, 51, 0) 0%,
        rgba(103, 36, 51, 1) 20%,
        rgba(103, 36, 51, 1) 50%,
        rgba(103, 36, 51, 1) 80%,
        rgba(103, 36, 51, 0) 100%
      );
    }
    @if $isSsm {
      background: linear-gradient(
        90deg,
        rgba(132, 30, 17, 0) 0%,
        rgba(132, 30, 17, 0.2) 20%,
        rgba(132, 30, 17, 1) 50%,
        rgba(132, 30, 17, 0.2) 80%,
        rgba(132, 30, 17, 0) 100%
      );
    }
  }
}
</style>
