<template>
  <div
    v-if="!isRecalculating"
    class="warehouse-league-table flex flex-col justify-center items-center space-y-1"
  >
    <div class="warehouse-league-table-top-banner flex justify-between items-center w-full h-full">
      <div class="warehouse-league-table-top-banner-left flexing">
        <app-icon icon-name="info-rounded" class="mr-4" />
        <p
          class="warehouse-league-table-top-banner-left-text text-30 text-texts-standard-default text-left"
        >
          {{ $te('clubLeagueInstruction') }}
        </p>
      </div>

      <div class="warehouse-league-table-top-banner-right flexing">
        <p v-if="isEnded" class="text-32 text-texts-standard-default uppercase font-bold mr-8">
          {{ $te('leagueEnded') }}
        </p>
        <template v-else>
          <p class="text-32 text-texts-standard-default uppercase font-bold mr-8">
            <shrink-text
              :text="$te('timeLeftRound') + ':'"
              :font-size="32"
              :animated-overflow="true"
            />
          </p>
          <app-timer :time="getRoundRemainingTime" />
        </template>
      </div>
    </div>

    <table-component
      :columns="headerColumns"
      :items="formattedLeagueData"
      :scroll-size="{ mobileY: 34, webY: 34 }"
      :show-logged-user="true"
      :logged-user="loggedUserClub"
      :pagination="false"
    />
  </div>

  <div v-if="isRecalculating" class="flexing w-full h-full">
    <p
      class="text-50 text-texts-standard-default font-bold"
      v-html="
        $replacePlaceholder(
          $replaceHtmlPlaceholders(
            $replacePlaceholder($te('updateInProgress'), '{time}', '{b}{time}{/b}'),
            'b',
            'text-texts-standard-important',
          ),
          '{time}',
          getFormattedTimeFromHMS(config?.date_round_start),
        )
      "
    />
  </div>
</template>

<script lang="ts">
import TableComponent from '@/components/Table/TableComponent.vue'
import ShrinkText from '@/components/ShrinkText.vue'
import { clubTableKeys } from '@/components/Table/tableKeys'
import { getFormattedTimeFromHMS } from '@/helpers'
import { defineComponent } from 'vue'

import type Reward from '@/interfaces/Reward'
import type {
  ClubInfo,
  ClubStats,
} from '@/interfaces/responses/events/league/LeagueStateApiResponse'
import { useClubStore } from '@/store/pinia/clubs/clubStore'
import { useLeagueEventStore } from '@/store/pinia/events/leagueStore'
import { mapState } from 'pinia'
import type { Nullable } from '@/interfaces/utils'

interface LeagueTableHeader {
  label: string
  disableSort?: boolean
  key: string
  align?: string
  headerAlign?: string
  coloredPos?: boolean
  width: number
  minWidth?: string
}

interface LeagueTableRow {
  pos: number
  clubId: string
  name: string
  logoId: number
  backgroundId: number
  commodityPoints: {
    name: string
    icon: string
    value: number
  }
  rewards: Reward[]
  progress: {
    advancing: boolean
    staying: boolean
    descending: boolean
  }
}

interface ComponentData {
  clubTableKeys: typeof clubTableKeys
  headerColumns: LeagueTableHeader[]
}

export default defineComponent({
  name: 'EventsWarehouseLeagueTable',
  components: {
    TableComponent,
    ShrinkText,
  },
  data(): ComponentData {
    return {
      clubTableKeys,
      headerColumns: [
        {
          label: this.$t('rankingsGroup.pos'),
          disableSort: true,
          key: clubTableKeys.pos,
          align: 'center',
          coloredPos: true,
          width: 7.5,
        },
        {
          label: this.$t('club.club'),
          disableSort: true,
          key: clubTableKeys.clubNameComponent,
          align: 'left',
          headerAlign: 'center',
          width: 30,
        },
        {
          label: this.$te('frostEnergy'),
          disableSort: true,
          key: clubTableKeys.commodityPoints,
          align: 'center',
          width: 10,
          minWidth: this.$isWsm ? '7.5rem' : '10rem',
        },
        {
          label: this.$t('common.rewards'),
          disableSort: true,
          key: clubTableKeys.rewards,
          align: 'center',
          width: 45,
        },
        {
          label: '',
          disableSort: true,
          key: clubTableKeys.progress,
          align: 'center',
          width: 7.5,
        },
      ],
    }
  },
  computed: {
    ...mapState(useLeagueEventStore, {
      getRoundRemainingTime: 'getRoundRemainingTime',
      state: 'getState',
      config: 'getConfig',
      isRecalculating: 'getIsRecalculating',
      isEnded: 'getIsLeagueEnded',
      getCommodityPointsConfig: 'getCommodityPointsConfig',
    }),
    ...mapState(useClubStore, {
      userClub: 'getClubInfo',
    }),
    loggedUserClub(): Nullable<LeagueTableRow> {
      const userClubStats = this.state?.results.clubs.find(
        (item: ClubStats): boolean => item.clubs_id === this.userClub.id,
      )

      if (!userClubStats) return null

      return {
        pos: userClubStats.position,
        clubId: userClubStats?.clubs_id,
        name: this.userClub.name,
        logoId: this.userClub.logoId,
        backgroundId: this.userClub.logoBgId,
        commodityPoints: {
          name: this.getCommodityPointsConfig?.name,
          icon: this.getCommodityPointsConfig?.icon,
          value: userClubStats.points,
        },
        rewards: userClubStats.rewards,
        progress: userClubStats.advances,
      }
    },
    formattedLeagueData(): LeagueTableRow[] {
      return this.state.results.clubs.map((club: ClubStats): LeagueTableRow => {
        const selectedClubConfig = this.state.clubs.find(
          (item: ClubInfo): boolean => item.clubs_id === club.clubs_id,
        )

        return {
          pos: club?.position,
          clubId: club?.clubs_id,
          name: selectedClubConfig?.name,
          logoId: selectedClubConfig?.logo_id,
          backgroundId: selectedClubConfig?.logo_background_id,
          commodityPoints: {
            name: this.getCommodityPointsConfig?.name,
            icon: this.getCommodityPointsConfig?.icon,
            value: club?.points,
          },
          rewards: club?.rewards,
          progress: club?.advances,
        }
      })
    },
  },
  methods: {
    getFormattedTimeFromHMS,
  },
})
</script>

<style lang="scss" scoped>
.warehouse-league-table {
  width: 97%;
  margin: 0 auto;

  &-top-banner {
    padding: 0.7rem 0 0.7rem 1rem;

    @if $isWsm {
      background-color: rgba(35, 109, 155, 0.5);
    }

    @if $isSsm {
      background-color: rgba(54, 76, 133, 0.9);
    }

    &-left {
      &-text {
        width: 75%;
        margin-left: 0;
        margin-right: auto;
        line-height: 1.2;
      }
    }
  }
}
</style>
