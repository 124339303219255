<template>
  <div v-if="showNews && !isTutorial" id="layout-news" class="layout-news">
    <header>
      <h2 class="text-texts-standard-additional text-40 uppercase font-bold">
        {{ $t('common.newsSingular') }}
      </h2>
      <app-control-icon control="close" class="close-icon absolute right-0" @click="hideNews()" />
    </header>
    <main class="flexing">
      <div
        class="layout-news-image"
        :style="newsData.image ? { backgroundImage: 'url(' + newsData.image + ')' } : {}"
      />
      <div class="layout-news-content">
        <div class="layout-news-content-title">
          <h2 class="text-texts-standard-default text-34 uppercase">
            <shrink-text :text="newsData.title" :font-size="34" class="pt-1" />
          </h2>
        </div>
        <div class="layout-news-content-text">
          <p class="text-texts-standard-additional text-30" v-html="newsData.text" />
        </div>
      </div>
      <div class="layout-news-content-rightside">
        <div class="layout-news-content-rightside-info">
          <span class="layout-news-content-rightside-info-date">
            <p class="text-texts-standard-additional text-30">
              {{ newsData.date }}
            </p>
          </span>
          <p class="text-texts-standard-additional text-30">
            {{ newsData.time }}
          </p>
        </div>
        <div class="layout-news-content-rightside-arrow cursor-pointer" @click="openNews()" />
      </div>
    </main>
  </div>
</template>

<script lang="ts">
import ShrinkText from '../ShrinkText.vue'
import { pathImages } from '@/globalVariables'
import { useNewsStore } from '@/store/pinia/newsStore'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { UserNewsDetail } from '@/interfaces/News'

interface ComponentData {
  pathImages: typeof pathImages
  newsData: UserNewsDetail
}

export default defineComponent({
  name: 'LayoutNews',
  components: { ShrinkText },
  data(): ComponentData {
    return {
      pathImages,
      newsData: {} as UserNewsDetail,
    }
  },
  computed: {
    ...mapState(useTutorialStore, {
      isTutorial: 'getIsTutorial',
    }),
    ...mapState(useNewsStore, {
      getUnreadNewsCount: 'getUnreadNewsCount',
      hideNewsOnMap: 'getHideNewsOnMap',
      getReloadNews: 'getReloadNews',
    }),
    showNews(): boolean {
      return !this.hideNewsOnMap && !!Object.keys(this.newsData).length
    },
  },
  watch: {
    getReloadNews(value: boolean): void {
      if (value) {
        this.getNewsData()
      }
    },
  },

  async created(): Promise<void> {
    this.getNewsData()
  },
  methods: {
    ...mapActions(useNewsStore, [
      'setNewsDetail',
      'setHideNewsOnMap',
      'getLatestUnreadMessage',
      'setReloadNews',
    ]),
    async getNewsData(): Promise<void> {
      // are there any news at all?
      if (!this.getUnreadNewsCount) {
        return
      }
      // show the newest message, max 3 days old
      this.newsData = await this.getLatestUnreadMessage()
      this.setReloadNews(false)
    },
    openNews(): void {
      this.hideNews()
      this.setNewsDetail(this.newsData)
      this.$router.push({
        name: this.$getWebView('NewsMessage'),
        params: { news_id: this.newsData.id },
      })
    },
    hideNews(): void {
      this.setHideNewsOnMap(true)
    },
  },
})
</script>

<style lang="scss" scoped>
.layout-news {
  width: 85.125rem;
  height: 16.813rem;
  position: absolute;
  bottom: 7.5rem;
  left: 50%;
  transform: translateX(-50%) scale(0.7);
  z-index: 1;
  user-select: text;
  @if $isWsm {
    border: solid 0.063rem #6c9ec4;
    background-color: rgba(21, 78, 121, 0.9);
  }
  @if $isSsm {
    border: solid 0.063rem #7477a4;
    background-color: rgba(#525479, 0.9);
  }

  /* &:active {
    transform: translateX(-50%) scale(.95);
  }*/

  header {
    width: 100%;
    height: 4.438rem;
    //background-image: linear-gradient(to left, rgba(21, 78, 121, 0.97), rgba(21, 78, 121, 0.47), #061123);
    background: url($path-news + 'BG_NOT_HEADER.avif') no-repeat;
    background-size: contain;
    display: flex;
    align-items: center;
    padding-left: 1.375rem;
    position: relative;
    @if $isWsm {
      border-bottom: solid 0.063rem #6c9ec4;
    }
    @if $isSsm {
      border-bottom: solid 0.063rem #7477a4;
      background: linear-gradient(to right, #373e5d, #232a40);
    }
  }

  main {
    height: 12.175rem;

    .layout-news-image {
      display: flex;
      flex-shrink: 0;
      width: 22rem;
      height: 100%;
      margin-right: auto;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
    }

    .layout-news-content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      height: 100%;
      padding: 0.75rem;
      overflow: hidden;

      &-title {
        height: 4.75rem;
        width: 34.688rem;
        position: relative;
        display: flex;
        align-items: center;
        padding-left: 1.5rem;
        @if $isWsm {
          background-image: linear-gradient(
            to right,
            rgba(13, 32, 55, 0.5),
            rgba(21, 78, 121, 0.5),
            transparent
          );
          border-left: 0.125rem solid rgba(255, 255, 255, 0.5);
        }
        @if $isSsm {
          background-image: linear-gradient(to right, #1d1f2c, rgba(41, 48, 73, 0.5), transparent);
          border-left: 0.125rem solid rgba(255, 255, 255, 0.5);
        }

        &:after,
        &:before {
          content: '';
          width: 100%;
          height: 0.125rem;
          position: absolute;
          left: 0;
          background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0.5),
            rgba(255, 255, 255, 0.5) 0%,
            rgba(251, 227, 110, 0) 90%
          );
        }

        &:after {
          top: 0;
        }

        &:before {
          bottom: 0;
        }
      }

      &-text {
        display: flex;
        flex-grow: 1;
        width: 100%;
        margin: 1rem;
        margin-bottom: 0;
        text-align: left;
        line-height: 1.25;

        p {
          @include line-clamp(2);
        }
      }
    }

    .layout-news-content-rightside {
      position: relative;
      height: 100%;
      padding: 1.75rem;
      padding-left: 0;

      &-info {
        display: flex;

        &-date {
          position: relative;
          margin-right: 2.25rem;

          &:before {
            content: '';
            position: absolute;
            height: 2.5rem;
            width: 0.25rem;
            background-color: rgba(255, 255, 255, 0.3);
            right: -1.125rem;
          }
        }
      }

      &-arrow {
        width: 3.188rem;
        height: 4.688rem;
        background-image: url($path-news + 'BTN_ARROW.avif');
        background-size: contain;
        position: absolute;
        right: 2.5rem;
        bottom: 1.875rem;
        cursor: pointer;
      }
    }
  }
}
</style>
