<template>
  <div
    class="app-page-wrapper rankings-disciplines absolute"
    data-cy="rankings-disciplines-wrapper"
  >
    <ranking-menu :current="2" />
    <section class="w-full h-full safe-area">
      <main class="ranking-disciplines-wrapper m-auto">
        <header class="ranking-disciplines-wrapper-header w-full">
          <discipline-menu
            id-discipline="arena-main-menu"
            emit-click-event
            :selected-default="selectedDiscipline"
            @on-click-discipline="selectDiscipline"
          />
        </header>
        <ranking-sub-menu :discipline="$t('discipline.discipline_' + selectedDiscipline)">
          <app-select
            :options="formatedCountries"
            :default="selectedCountry"
            class="selectbox"
            @select="selectCountry"
          />
        </ranking-sub-menu>
        <div class="ranking-disciplines-wrapper-table">
          <ranking-table-head ranking-type="disciplines" :hide-rewards="true" />
          <app-scrollbar
            width="100%"
            :height="$isMobile() ? '30rem' : '29rem'"
            scroll="y"
            slide="y"
          >
            <ranking-row-table
              :rows="rankings"
              table="discipline"
              width="116.125rem"
              :hide-rewards="true"
            />
          </app-scrollbar>
          <ranking-player
            v-if="myRanking"
            :player-data="myRanking"
            ranking-type="disciplines"
            :hide-rewards="true"
          />
        </div>
      </main>
    </section>
  </div>
</template>

<script lang="ts">
import DisciplineMenu from '@/components/DisciplineMenu.vue'
import RankingMenu from '@/components/Rankings/RankingMenu.vue'
import RankingPlayer from '@/components/Rankings/RankingPlayer.vue'
import RankingRowTable from '@/components/Rankings/RankingRowTable.vue'
import RankingSubMenu from '@/components/Rankings/RankingSubMenu.vue'
import RankingTableHead from '@/components/Rankings/RankingTableHead.vue'
import { arenaRankingNationalEndpoint, pathImages } from '@/globalVariables'
import { useCountriesStore } from '@/store/pinia/countriesStore'
import { useUserStore } from '@/store/pinia/userStore'
import { useDisciplineStore } from '@/store/pinia/disciplinesStore'

import { mapState } from 'pinia'
import { defineComponent } from 'vue'

import type Reward from '@/interfaces/Reward'
import type { Nullable } from '@/interfaces/utils'
import type { RankingUser } from '@/interfaces/RankingsInterfaces'
import type { DisciplineMenuData } from '@/interfaces/DisciplineMenu'
import type { RankingsApiResponse } from '@/interfaces/responses/rankings/RankingsApiResponse'

interface ComponentData {
  pathImages: typeof pathImages
  selectedDiscipline: number
  selectedCountry: Nullable<string>
  myRanking: RankingsApiResponse['ranking']['player']
  rankings: Nullable<RankingUser[]>
  rewards: Reward[]
}

export default defineComponent({
  name: 'RankingDisciplinesCountry',
  components: {
    RankingMenu,
    RankingRowTable,
    DisciplineMenu,
    RankingSubMenu,
    RankingTableHead,
    RankingPlayer,
  },
  data(): ComponentData {
    return {
      pathImages,
      selectedDiscipline: 1,
      selectedCountry: null,
      myRanking: {} as RankingsApiResponse['ranking']['player'],
      rankings: null,
      rewards: [],
    }
  },
  computed: {
    ...mapState(useCountriesStore, {
      formatedCountries: 'getFormattedCountries',
    }),
    ...mapState(useUserStore, {
      defaultCountry: 'getCountry',
    }),
    ...mapState(useDisciplineStore, {
      firstUnlockedDisciplineId: 'getFirstUnlockedDisciplineId',
    }),
  },
  watch: {
    async selectedDiscipline(): Promise<void> {
      await this.getRankings()
    },
    async selectedCountry(): Promise<void> {
      await this.getRankings()
    },
  },
  created(): void {
    if (this.$route.params.id) {
      this.selectedDiscipline = Number(this.$route.params.id)
    } else {
      this.selectedDiscipline = this.firstUnlockedDisciplineId
    }
    this.selectedCountry = this.defaultCountry
  },
  methods: {
    async getRankings(): Promise<void> {
      const response = await this.$axios.get<{}, RankingsApiResponse>(
        arenaRankingNationalEndpoint +
          '/' +
          (this.selectedCountry || this.defaultCountry) +
          '/discipline/' +
          this.selectedDiscipline,
      )
      this.rewards = response?.rewards ?? []
      this.myRanking = response?.ranking?.player

      this.rankings =
        Object.keys(response?.ranking).reduce(
          (finalArray: RankingUser[], userId: string): RankingUser[] => {
            if (userId !== 'player') {
              finalArray.push({
                id: userId,
                name: response.ranking[userId].username,
                level: response.ranking[userId].LEVEL,
                points: response.ranking[userId].RANKING_POINTS,
                clubId: response.ranking[userId].club_id,
                clubLogoId: response.ranking[userId].club_logo_id,
                clubLogoBgId: response.ranking[userId].club_background_logo_id,
                eventBadges: response.ranking[userId].event_badges ?? null,
                ...response.ranking[userId],
              })
            }
            return finalArray
          },
          [],
        ) ?? []
    },
    getRewardsByRank(rank: number): Reward[] {
      return (
        this.rewards.find(
          (rewardObj: Reward) => rank >= rewardObj.rank_min && rank <= rewardObj.rank_max,
        )?.rewards || []
      )
    },
    selectDiscipline(disciplineData: DisciplineMenuData): void {
      if (this.selectedDiscipline === disciplineData.id) return
      this.selectedDiscipline = disciplineData.id
    },
    selectCountry(country: Nullable<string>): void {
      if (this.selectedCountry === country) return
      this.selectedCountry = country
    },
  },
})
</script>

<style lang="scss" scoped>
.ranking-disciplines-wrapper {
  width: 116.25rem;
  height: 48.0625rem;
  margin-top: 1.1875rem;

  &-header {
    height: 5.625rem;
    margin-bottom: 1.25rem;
    @if $isWsm {
      background: linear-gradient(to right, transparent 0, #144a73, transparent 100%);
    }
    @if $isSsm {
      background: linear-gradient(
        to right,
        transparent 0,
        rgba(86, 98, 127, 0.9),
        transparent 100%
      );
    }
  }

  &-table {
    margin-top: 1.25rem;
  }

  .ranking-discipline-global-scroll {
    max-height: 27.25rem;
  }
}
</style>
