<template>
  <div class="ranking-club app-page-wrapper absolute">
    <ranking-menu :current="1" />
    <section class="w-full h-full safe-area">
      <main class="ranking-club-wrapper m-auto flex">
        <aside class="leftSide">
          <ranking-box-position
            v-if="!isClubRankingsLoading && topRankings"
            :ranking-position="mappedTopRankings"
          />
        </aside>
        <aside class="rightSide">
          <header class="rightSide-header relative w-full flex justify-end">
            <tippy theme="WSM" placement="left" max-width="35rem">
              <app-icon icon-name="info-70" />
              <template #content>
                <!-- eslint-disable -->
                <p class="tooltip-content-bottom w-full"
                  v-html="$replacePlaceholder($t('rankingsGroup.rankingRefreshTime'), '%s', `<b class='text-texts-standard-important'>30</b>`)" />
                <!-- eslint-enable -->
              </template>
            </tippy>
          </header>
          <ranking-table-head :is-club="true" :ranking-type="'clubLeaderboard'" />
          <app-scrollbar
            class="ranking-discipline-global-scroll"
            width="100%"
            height="34rem"
            scroll="y"
            slide="y"
          >
            <ranking-row-table
              :rows="!isClubRankingsLoading ? clubRankings : null"
              table="clubLeaderboard"
              width="81.1875rem"
            />
          </app-scrollbar>
          <footer
            v-if="showPlayerClub && !isClubRankingsLoading"
            class="player-position flex items-center text-texts-standard-dark text-32"
          >
            <p
              class="player-position-position font-bold flexing h-full text-36 text-texts-standard-default"
            >
              {{ playerClubRanking?.position }}
            </p>

            <div class="player-position-player text-texts-standard-dark flex items-center h-full">
              <app-club-name
                :club-id="playerClubRanking.clubId"
                :name="playerClubRanking?.name"
                :club-logo-id="playerClubRanking.clubLogoId"
                :clubs-background-id="playerClubRanking.clubLogoBgId"
                :is-table-me-row="true"
              />
            </div>

            <p class="player-position-level h-full flexing">
              {{ playerClubRanking?.members }}
            </p>
            <div class="player-position-points h-full flexing">
              <p>{{ playerClubRanking?.points }}</p>
              <app-main-icon icon-name="ranking_points" :icon-size="48" />
            </div>
          </footer>
        </aside>
      </main>
    </section>
  </div>
</template>

<script lang="ts">
import AppClubName from '@/components/GlobalComponents/AppClubName.vue'
import RankingBoxPosition from '@/components/Rankings/RankingBoxPosition.vue'
import RankingMenu from '@/components/Rankings/RankingMenu.vue'
import RankingRowTable from '@/components/Rankings/RankingRowTable.vue'
import RankingTableHead from '@/components/Rankings/RankingTableHead.vue'
import { useRankingStore } from '@/store/pinia/rankingStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

import type { ClubFormattedRanks } from '@/types/rankingTypes'

interface ClubTopRankings {
  clubId: string
  club: string
  points: number
  clubLogoBgId: number
  clubLogoId: number
}

export default defineComponent({
  components: {
    RankingMenu,
    RankingBoxPosition,
    RankingRowTable,
    RankingTableHead,
    AppClubName,
  },
  computed: {
    ...mapState(useRankingStore, {
      clubRankings: 'getClubRankings',
      playerClubRanking: 'getPlayerClubRanking',
      topRankings: 'getTopClubRankings',
      isClubRankingsLoading: 'isClubRankingsLoading',
    }),
    showPlayerClub(): number {
      return Object.keys(this.playerClubRanking).length
    },
    mappedTopRankings(): ClubTopRankings[] {
      return this.topRankings?.map((item: ClubFormattedRanks): ClubTopRankings => {
        return {
          clubId: item.clubId,
          club: item.name,
          points: item.points,
          clubLogoBgId: item.clubLogoBgId,
          clubLogoId: item.clubLogoId,
        }
      })
    },
  },
  created(): void {
    this.loadClubRankings()
  },
  methods: {
    ...mapActions(useRankingStore, ['loadClubRankings']),
  },
})
</script>

<style lang="scss" scoped>
.ranking-club-wrapper {
  width: 116.8125rem;
  height: 50rem;

  .leftSide {
    width: 33.125rem;
    padding-top: 1.9375rem;
  }

  .rightSide {
    margin-left: 1.875rem;
    height: 100%;
    width: 81.1875rem;

    &-header {
      height: 4.375rem;
      margin-top: 1.1875rem;
      margin-bottom: 1.25rem;

      .selectbox-country {
        left: 0%;
        transform: translate(-50%) $skewX-value !important;
      }

      .selectbox-season {
        right: 14%;
      }
    }

    &-table-header {
      height: 2.9375rem;
      background: #09172a;

      .pos {
        width: 6.4375rem;
      }

      .name {
        width: 29rem;
      }

      .level {
        width: 8.125rem;
      }

      .club {
        width: 20.75rem;
      }

      .points {
        width: 16.9375rem;
      }
    }

    .table-row {
      width: 81.1875rem;
      height: 4rem;
      margin-bottom: 0.375rem;

      &:nth-child(1) {
        margin-top: 0.6875rem;
      }

      &-position {
        width: 6.4375rem;
        position: relative;
        background: #154c76;
        clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 0% 100%);
      }

      &-player {
        padding-left: 1.0625rem;
        width: 29rem;

        .flag {
          width: 3.0625rem;
          min-width: 3.0625rem;
          height: 2rem;
          background: url($path-images + 'flags/70x49/FLAG_SVK.avif') center no-repeat;
          background-size: contain;
          margin-right: 1.0625rem;
          margin-left: 0.5625rem;
        }
      }

      &-level {
        width: 8.125rem;
      }

      &-club {
        width: 20.75rem;
      }

      &-points {
        width: 16.9375rem;
      }
    }

    .player-position {
      width: 100%;
      height: 4rem;
      background: #d9ecff;
      border: solid 0.0625rem #6c9ec4;

      &-position {
        width: 6.4375rem;
        position: relative;
        background: #102636;
        clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 0% 100%);
      }

      &-player {
        padding-left: 1.0625rem;
        width: 44rem;

        .flag {
          width: 3.0625rem;
          min-width: 3.0625rem;
          height: 2rem;
          background: url($path-images + 'flags/70x49/FLAG_SVK.avif') center no-repeat;
          background-size: contain;
          margin-right: 1.0625rem;
          margin-left: 0.5625rem;
        }
      }

      &-level {
        width: 9.125rem;
      }

      &-club {
        width: 20.75rem;
      }

      &-points {
        justify-content: flex-end;
        width: 14.9375rem;
      }
    }

    .table-row:nth-child(1) > .table-row-position {
      @if $isWsm {
        background-image: linear-gradient(to top, #ffef84, #f6c717);
      }
      @if $isSsm {
        background-image: linear-gradient(to top, #daab36, #ffdf91);
      }
    }

    .table-row:nth-child(2) > .table-row-position {
      @if $isWsm {
        background-image: linear-gradient(to top, #a4a4a4, #fff);
      }
      @if $isSsm {
        background-image: linear-gradient(to top, #c3c3c3, #f5f0f0);
      }
    }

    .table-row:nth-child(3) > .table-row-position {
      @if $isWsm {
        background-image: linear-gradient(to top, #e77447, #ffb99e);
      }
      @if $isSsm {
        background-image: linear-gradient(to top, #f58d57, #ffb28a);
      }
    }
  }

  .ranking-global-scrollbar {
    width: 82.5rem;
    height: 35.7rem;
  }
}

.tooltip-content {
  height: 10.9375rem;

  &-top {
    height: 4.4375rem;
    line-height: 4.4375rem;
  }

  &-bottom {
    padding-top: 0.85rem;
    height: 6.375rem;
  }

  .center-strip {
    width: 20.5625rem;
    height: 0.125rem;
    background: linear-gradient(to right, #a4a4a400, #a4a4a4, #a4a4a4, #a4a4a400);
  }
}
</style>
