<template>
  <div
    v-if="(isMainMap || isClubMap) && (!isTutorial || isFocusOnClubCentrum || isClickOnDailyTasks)"
    class="main-footer fixed"
    :class="[
      { 'display-none': actualStageData?.type === FOCUS, 'w-full': !$isMobile() },
      isMainMap ? 'justify-end' : 'justify-between',
      tutorialClass,
    ]"
  >
    <map-home-button v-if="!isMainMap" :disabled="isTutorial" />
    <div class="main-footer-box flexing">
      <div v-if="isMainMap || (isClubMap && isMyClub($router))" class="flexing">
        <map-buttons
          v-for="(btn, index) in menuObject"
          :id="Number(index)"
          :key="index"
          :is-club-map="isClubMap"
          :text="$t(btn.text)"
          :image="btn.image"
          :strip="true"
          :route="btn.route"
          :route-query="btn.query"
          :theme="MapButtonThemes.Theme1"
          :is-disabled="btn.disabled"
          :sound="btn.sound ? btn.sound : ''"
          :lock-tooltip="btn.lockedTooltip"
          :lock-icon="btn.lockIcon"
          :online-players="isClubMap ? btn.onlinePlayers : null"
          :notification="btn.notification"
          :timer="btn.timer"
          @timer-ended="btn.timerEnded"
        />
      </div>
    </div>
    <div v-if="isClubMap && isMyClub($router)" class="placeholder-div" />
    <app-button
      v-if="isMainMap"
      btn-id="layout-premium-btn"
      class="layout-premium-btn"
      btn-type="credit"
      :btn-text="$t('map.premiumName')"
      btn-size="sm"
      :locked="false"
      :disabled="isTutorial"
      @click="premiumClick"
    />
    <map-announcement v-if="isClubMap && isMyClub($router)" />
    <mechanics-menu :show-mechanics="showMenu" @hide-mechanic="showMenu = false" />
  </div>
</template>

<script lang="ts">
import MapHomeButton from '@/components/Button/MapHomeButton.vue'
import MapAnnouncement from '@/components/Layout/MapAnnouncement.vue'
import MechanicsMenu from '@/components/Layout/MechanicsMenu.vue'
import {
  CLUB,
  FOCUS,
  MECHANIC_CAREER,
  MECHANIC_CLUB,
  MECHANIC_DAILY_QUESTS,
  MECHANIC_GAME_PASS,
} from '@/globalVariables'
import { isMyClub, playSound } from '@/helpers'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import { useUserStore } from '@/store/pinia/userStore'
import { useGamePassStore } from '@/store/pinia/gamePassStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import MapButtons from './MapButtons.vue'
import { MapButtonThemes } from '@/enums/MapButtonThemes'
import type { MenuObject } from '@/interfaces/MenuObject'
import { usePhaserGameIntegrationStore } from '@/store/pinia/map-new/phaserGameIntegrationStore'
import type { BuildingMainMapResponseInterface } from '@/map-phaser-new/interfaces'

interface ComponentData {
  FOCUS: typeof FOCUS
  showNotificationModal: boolean
  showMenu: boolean
  MapButtonThemes: typeof MapButtonThemes
}

export default defineComponent({
  name: 'FooterComponent',
  components: {
    MapButtons,
    MechanicsMenu,
    MapHomeButton,
    MapAnnouncement,
  },
  data(): ComponentData {
    return {
      FOCUS,
      showNotificationModal: false,
      showMenu: false,
      MapButtonThemes,
    }
  },
  computed: {
    ...mapState(useUserStore, {
      hasClub: 'getHasClub',
    }),
    ...mapState(useTutorialStore, {
      actualStageData: 'getActualStage',
      isTutorial: 'getIsTutorial',
      tutorialId: 'getTutorialId',
    }),
    ...mapState(usePhaserGameIntegrationStore, {
      buildings: 'getMainMapBuildings',
    }),
    ...mapState(useResponseTaskStore, {
      hasMechanic: 'hasMechanic',
      getMechanicUnlock: 'getMechanicUnlock',
    }),
    ...mapState(useGamePassStore, [
      'remainingTime',
      'getNotification',
      'isGamePassUnlocked',
      'isGamePassInitialized',
    ]),
    clubUnlockValue(): string | null {
      return (
        this.buildings.find((building: BuildingMainMapResponseInterface) => building.name === CLUB)
          ?.unlock.value ?? null
      )
    },
    menuObject(): Record<number, MenuObject> {
      if (this.isMainMap) {
        return {
          1: {
            text: 'map.reports',
            route: this.$getWebView('Reports'),
            image: 'news',
            disabled: !!(this.isTutorial && this.tutorialId === '1'),
            sound: 'report_open_menu',
          },
          2: {
            text: 'rankingsGroup.rankings',
            route: this.$getWebView('RankingGlobal'),
            id: 1,
            image: 'ranking',
            disabled: !!(this.isTutorial && this.tutorialId === '1'),
            sound: 'building-rankings',
          },
          3: {
            text: 'rankingsGroup.club',
            route: this.hasClub ? this.$getWebView('ClubsMap') : this.$getWebView('ClubsAvailable'),
            image: 'club',
            disabled: !this.hasMechanic(MECHANIC_CLUB),
            query: { homepage: 'true' },
            lockedTooltip: !this.hasMechanic(MECHANIC_CLUB)
              ? this.$replacePlaceholder(
                  this.$t('common.mainCareerTaskRequired'),
                  '%s',
                  this.clubUnlockValue ?? this.getMechanicUnlock(MECHANIC_CLUB),
                )
              : '',
            lockIcon: 'lock-sm',
          },
          4: {
            text: 'career.quests',
            route: this.getQuestsRoutes(),
            image: 'task',
            sound: 'task_open',
            disabled:
              !this.hasMechanic(MECHANIC_DAILY_QUESTS) && !this.hasMechanic(MECHANIC_CAREER),
          },
          5: {
            text: 'grandPrize.header',
            route: this.$getWebView('GrandPrizeWarehouse'),
            image: 'grand_prize',
            disabled: false,
            sound: 'grand_prize_open_menu',
          },
          6: {
            text: '',
            route: this.$getWebView('PremiumGamePass'),
            image: 'game-pass',
            disabled: !this.isGamePassUnlocked || !this.isGamePassInitialized,
            sound: '',
            lockedTooltip: !this.isGamePassInitialized
              ? this.$t('common.comingSoon')
              : this.$replacePlaceholder(
                  this.$replaceHtmlPlaceholders(
                    this.$replacePlaceholder(this.$t('career.reachLevel'), '%s', '{b} %s {/b}'),
                    'b',
                    'text-texts-standard-important',
                  ),
                  '%s',
                  this.getMechanicUnlock(MECHANIC_GAME_PASS),
                ),
            notification: this.getNotification,
            timer: this.remainingTime * 1000,
            timerEnded: async (): Promise<void[]> =>
              await Promise.all([this.loadGamePassConfig(true), this.loadGAmePassState(true)]),
          },
        }
      }

      if (this.isClubMap) {
        return {
          1: {
            text: 'club.clubCentrum',
            route: this.$getWebView('ClubsBuildings'),
            image: 'center',
            disabled: false,
            sound: 'report_open_menu',
          },
          2: {
            text: 'club.managementMenu', // 'rankingsGroup.club'
            route: this.$getWebView('ClubsMembers'),
            id: 1,
            image: 'managment',
            disabled: this.isFocusOnClubCentrum,
            sound: 'building-rankings',
            onlinePlayers: 0,
          },
          3: {
            text: 'club.clubShop',
            route: 'ClubsMap',
            image: 'shop',
            disabled: true,
            lockedTooltip: 'Coming soon',
          },
        }
      }

      return []
    },
    isClickOnGrandPrizeMenu(): boolean {
      return this.actualStageData?.name === 'clickOnGrandPrizeMenu'
    },
    isClickOnDailyTasks(): boolean {
      return this.actualStageData?.name === 'clickOnDailyTasks'
    },
    isFocusOnClubCentrum(): boolean {
      return this.actualStageData?.name === 'focusOnClubCentrum'
    },
    tutorialClass(): string {
      return this.isClickOnGrandPrizeMenu || this.isClickOnDailyTasks || this.isFocusOnClubCentrum
        ? 'main-footer-tutorial'
        : ''
    },
    isClubMap(): boolean {
      return this.$route.path.includes('clubs')
    },
    isMainMap(): boolean {
      return !this.isClubMap
    },
    isTutorial(): boolean {
      return this.$route.path.includes('tutorial')
    },
  },
  async created(): Promise<void> {
    await this.loadNotifications()
  },
  mounted(): void {
    const currentRoutePath = this.$route.path
    const mainMapArtifacts = ['locked-tooltip', 'notification-icon', 'championship-label']

    switch (currentRoutePath) {
      case '/':
        mainMapArtifacts.map((artifact: string): void => {
          document.querySelectorAll('.' + artifact).forEach((item: Element): void => {
            item.classList.remove(artifact + '--hidden')
          })
        })
        document.querySelectorAll('.locked-tooltip-clubs').forEach((tooltip: Element): void => {
          tooltip.classList.add('locked-tooltip--hidden')
        })
        break
      case '/clubs':
        mainMapArtifacts.map((artifact: string): void => {
          document.querySelectorAll('.' + artifact).forEach((item: Element): void => {
            item.classList.add(artifact + '--hidden')
          })
        })
        document.querySelectorAll('.locked-tooltip-clubs').forEach((tooltip: Element): void => {
          tooltip.classList.remove('locked-tooltip--hidden')
        })
        break
      default:
        break
    }
  },
  methods: {
    ...mapActions(useResponseTaskStore, {
      loadNotifications: 'loadNotifications',
    }),
    ...mapActions(useGamePassStore, {
      loadGamePassConfig: 'loadConfig',
      loadGAmePassState: 'loadState',
    }),
    isMyClub,
    premiumClick(): void {
      playSound('grand_prize_open_menu')
      this.$router.push({ name: this.$getWebView('PremiumGems') })
    },
    playClick(): void {
      this.$router.push({ name: this.$getWebView('ArenaView') })
    },
    hideNofiticationModal(): void {
      this.showNotificationModal = false
    },
    getQuestsRoutes(): string {
      const careerOpen = this.hasMechanic(MECHANIC_CAREER)
      const dailyQuestsOpen = this.hasMechanic(MECHANIC_DAILY_QUESTS)
      if (careerOpen && !dailyQuestsOpen) return this.$getWebView('TasksCareer')
      return this.$getWebView('DailyTask')
    },
  },
})
</script>

<style lang="scss" scoped>
.main-footer {
  height: 6.313rem;
  bottom: 0.75rem;
  display: flex;
  align-items: center;
  padding-left: 2rem;
  padding-right: 2rem;

  &-tutorial {
    z-index: 5;
  }

  .footer-notification-modal {
    position: absolute;
    right: -10rem;
    bottom: 7rem;
  }
}

.layout-premium-btn {
  right: 2rem;
}

.display-none {
  display: none;
}

.placeholder-div {
  width: 23.125rem;
}
</style>
