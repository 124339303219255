<template>
  <div id="mail-new-view" class="app-page-wrapper mail absolute">
    <menu-component menu-type="mail" :title="$t('post.newMessage')" />
    <section class="w-full h-full safe-area">
      <section class="mail-new-wrapper flex flex-col text-texts-standard-default text-50">
        <div class="mail-new-message text-texts-standard-additional text-left w-full">
          <p class="new-mail-label text-30 uppercase flex justify-between">
            <span>{{ $t('post.recipient') }}</span>
            <span v-if="!isAddresseeOk()" class="text-texts-standard-danger normal-case">{{
              `*${$t('post.required')}`
            }}</span>
          </p>
          <div
            class="new-mail-input-wrapper w-full relative"
            :class="{ 'input-ok': isAddresseeOk() }"
          >
            <input
              v-model="addressee"
              name="addressee-input"
              :placeholder="$t('post.inputRecipientName')"
              class="new-mail-input text-36 flex items-center w-full italic font-bold"
            />
          </div>
          <p class="new-mail-label text-30 uppercase flex justify-between">
            <span>{{ $t('post.subject') }}</span>
            <span v-if="!isSubjectOk()" class="normal-case text-texts-standard-additional">{{
              `*${$t('post.optional')}`
            }}</span>
          </p>
          <div
            class="new-mail-input-wrapper w-full relative"
            :class="{ 'input-ok': isSubjectOk() }"
          >
            <input
              v-model="subject"
              name="subject-input"
              :placeholder="$t('post.inputSubject')"
              class="new-mail-input text-36 flex items-center w-full italic font-bold"
            />
          </div>
          <p class="new-mail-label text-30 uppercase flex justify-between">
            <span>{{ $t('post.message') }}</span>
            <span v-if="!isMessageOk()" class="text-texts-standard-danger normal-case">{{
              `*${$t('post.required')}`
            }}</span>
          </p>
          <textarea
            v-model="message"
            name="message-input"
            :placeholder="$t('post.inputMessage')"
            class="new-message-textarea w-full italic text-32"
          />
        </div>
        <footer class="flex justify-end mail-new-footer">
          <app-button
            btn-id="btn-mail-send"
            btn-size="md"
            btn-type="secondary"
            :btn-text="$t('post.send')"
            class="mail-send-btn"
            :disabled="!isMailOk()"
            @click="sendMail()"
          />
        </footer>
      </section>
    </section>
  </div>
  <transition name="sendmessage">
    <div v-show="sendInfo" class="no-mail-wrapper flexing">
      <div class="no-mail flexing text-texts-standard-default text-50 uppercase">
        <p>{{ $t('post.messageSent') }}</p>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import {
  FORWARD,
  MAX_USERNAME_LENGTH,
  MIN_USERNAME_LENGTH,
  REPLY,
  SEND,
  mailDetailEndpoint,
  mailForwardEndpoint,
  mailReplyEndpoint,
  mailSendEndpoint,
} from '@/globalVariables'
import { getFormattedDateFromYMD, getFormattedTimeFromHMS } from '@/helpers'
import { defineComponent } from 'vue'

import type { MailMessage } from '@/interfaces/MailMessage'

enum MessageType {
  Send = SEND,
  Forward = FORWARD,
  Reply = REPLY,
}

type MessageReplyOptions = Exclude<MessageType, MessageType.Send>

interface ComponentData {
  subject: string
  addressee: string
  message: string
  messageReplyOptions: MessageReplyOptions[]
  messageType: MessageType
  messageId: string
  sendInfo: boolean
  MIN_USERNAME_LENGTH: typeof MIN_USERNAME_LENGTH
  MAX_USERNAME_LENGTH: typeof MAX_USERNAME_LENGTH
}

export default defineComponent({
  name: 'MailNew',
  data(): ComponentData {
    return {
      subject: '',
      addressee: '',
      message: '',
      messageReplyOptions: [MessageType.Forward, MessageType.Reply],
      messageType: MessageType.Send,
      messageId: '',
      sendInfo: false,
      MIN_USERNAME_LENGTH,
      MAX_USERNAME_LENGTH,
    }
  },
  async created(): Promise<void> {
    if (this.$route.params?.recipient) {
      this.addressee = this.$route.params.recipient.toString()
    }
    if (this.messageReplyOptions.includes(this.$route.query.type as MessageReplyOptions)) {
      this.messageId = this.$route.query.id.toString()
      const message: MailMessage = await this.$axios.get<{}, MailMessage>(
        `${mailDetailEndpoint}${this.messageId}`,
      )

      if (this.$route.query.type === MessageType.Forward) {
        this.messageType = MessageType.Forward
        this.subject = `Fwd: ${message.subject}`
      }
      if (this.$route.query.type === MessageType.Reply) {
        this.messageType = MessageType.Reply
        this.subject = `Re: ${message.subject}`
        this.addressee = message.sender.username
      }
      this.message = `\n\n > ${message.text} \n\n ${
        message.sender.username
      } (${getFormattedDateFromYMD(message.sent_at)} ${getFormattedTimeFromHMS(message.sent_at)}) `
    }
  },
  methods: {
    isSubjectOk(): boolean {
      return this.subject.length > 0
    },
    isAddresseeOk(): boolean {
      return (
        this.addressee.length >= MIN_USERNAME_LENGTH && this.addressee.length <= MAX_USERNAME_LENGTH
      )
    },
    isMessageOk(): boolean {
      return this.message.length > 1
    },
    isMailOk(): boolean {
      return this.isAddresseeOk() && this.isMessageOk()
    },
    async sendMail(): Promise<void> {
      if (this.isMailOk) {
        let endpoint = ''
        switch (this.messageType) {
          case MessageType.Send:
            endpoint = mailSendEndpoint
            break
          case MessageType.Reply:
            endpoint = mailReplyEndpoint
            break
          case MessageType.Forward:
            endpoint = mailForwardEndpoint
            break
        }
        await this.$axios.post<
          {},
          MailMessage,
          { receiver_username: string; subject: string; text: string; message_id: string }
        >(endpoint, {
          receiver_username: this.addressee,
          subject: this.subject,
          text: this.message,
          message_id: this.messageType === MessageType.Send ? '' : this.messageId,
        })

        this.sendInfo = true
        setTimeout((): void => {
          this.sendInfo = false
          this.$router.push({ name: this.$getWebView('MailReceived') })
        }, 2000)
      }
    },
  },
})
</script>
<style lang="scss" scoped>
@import '@/assets/styles/views/mail/mail.scss';
</style>
