import type { BubbleNotificationConfigInterface } from '@/map-phaser-new/interfaces'
import { CLUB, CAREER, TRAINING_HALL, SHOP, LABORATORY } from '@/map-phaser-new/constants'

export const bubbleNotification: BubbleNotificationConfigInterface = {
  buildings: [CAREER, CLUB, SHOP, TRAINING_HALL, LABORATORY],
  textureKey: 'bubbleNotification',
  depth: 8,
  origin: 0.5,
  scale: 0.7,
  xCorrectionValue: 2,
  yCorrectionValue: -28,
  animationYIndent: '+15',
  animationYEase: 'Back',
  yoyo: true,
  yoyoHold: 3000,
  duration: 850,
  repeat: -1,
}
