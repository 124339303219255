<template>
  <div
    id="career-task-normal"
    class="career-task-normal"
    data-cy="career-task-normal"
    :style="{ background: setBackground(quest) }"
  >
    <div
      class="career-task-avatar"
      :class="{ 'career-task-normal-locked': quest.isLocked }"
      :style="careerBossStyle"
    />
    <app-icon v-if="quest.isLocked" class="career-task-normal-locked-icon" icon-name="lock-xxl" />
    <arrow-animation
      position="left"
      border="box"
      :tutorial="isTutorialStep"
      :add-class="`quest-arrow flexing ${$isMobile() ? 'quest-arrow-mobile' : ''}`"
    >
      <main
        class="flexing"
        :style="{ right: isTutorialStep ? '' : $isMobile() ? '25rem' : '14.438rem' }"
      >
        <aside>
          <div class="flex items-center text-texts-standard-default text-36">
            <p>
              {{ taskText.beforeIcon }}

              <app-main-icon
                v-if="quest.parameters.text_icon"
                :icon-size="56"
                :icon-name="quest.parameters.text_icon"
                class="inline-block quest-text-icon"
              />
              {{ taskText.afterIcon }}
            </p>
          </div>
        </aside>
        <div class="career-task-progressbar">
          <app-progress-bar
            class="progressbar"
            :bar-width="31.375"
            :bar-height="1.625"
            :text-size="2.25"
            :actual="quest.stats.currentValue"
            :goal="quest.parameters.target_value"
            :discipline-changed="isDisciplineChanged"
          />
          <div class="icon-arrow" />
          <div v-if="quest.isDone" class="career-task-progressbar__icon icon icon-done-md" />
        </div>
        <div class="career-task-progress" :class="{ 'locked-opacity': quest.isLocked }">
          <p class="text-texts-standard-default text-30">
            <span class="text-texts-standard-important">
              {{ $filters.$formatNumber(quest.stats?.currentValue ?? 0) }}
            </span>
            /
            {{ $filters.$formatNumber(quest.parameters.target_value) }}
          </p>
        </div>
      </main>
    </arrow-animation>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'pinia'
import { getTextWithIcon, getDisciplineBossSlug } from '@/helpers'
import { pathFigures, pathImages } from '@/globalVariables'
import ArrowAnimation from '@/components/ArrowAnimation.vue'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import { useUserStore } from '@/store/pinia/userStore'

import type { PropType } from 'vue'
import type { CareerQuest } from '@/interfaces/Quest'
import type { Nullable } from '@/interfaces/utils'

interface ComponentData {
  taskText: {
    beforeIcon: string
    afterIcon: string
  }
  isDisciplineChanged: boolean
}

export default defineComponent({
  name: 'CareerTaskNormal',
  components: {
    ArrowAnimation,
  },
  props: {
    quest: {
      type: Object as PropType<Nullable<CareerQuest>>,
      default: () => null,
    },
    disciplineId: {
      type: Number,
      default: 0,
    },
  },
  data(): ComponentData {
    return {
      taskText: {
        beforeIcon: '',
        afterIcon: '',
      },
      isDisciplineChanged: false,
    }
  },
  computed: {
    ...mapState(useTutorialStore, {
      actualStage: 'getActualStage',
    }),
    ...mapState(useUserStore, {
      firstDisciplineId: 'getFirstDisciplineId',
    }),
    isTutorialStep(): boolean {
      return this.actualStage?.name === 'openCareerWindowNarrativeCareer'
    },
    careerBossStyle(): string {
      const firstDisciplineId = this.$isSsm ? this.firstDisciplineId : 0

      let slug = getDisciplineBossSlug(this.disciplineId || firstDisciplineId)

      // V kariere WSM nemame Annie, ale Anne...
      if (this.$isWsm) slug = slug.replace('Annie', 'Anne')

      return `background-image: url(${pathFigures}/${slug}-sm.avif);`
    },
  },
  watch: {
    quest(): void {
      this.initTexts()
    },
    $route(): void {
      this.isDisciplineChanged = true
    },
  },
  created(): void {
    this.initTexts()
  },
  methods: {
    async initTexts(): Promise<void> {
      this.taskText = getTextWithIcon(this.quest)
    },
    setBackground(quest: CareerQuest): string {
      const imageName = quest.parameters.unlock_building

      if (!imageName) return ''
      return `url(${pathImages}career/buildingBg/${imageName}.jpeg) center no-repeat`
    },
  },
})
</script>

<style lang="scss" scoped>
.career-task-normal {
  width: 100%;
  height: 25.4rem;
  position: relative;
  background-size: 100% 150% !important;

  &-locked {
    filter: blur(3px);
    -webkit-filter: blur(3px);

    &-icon {
      position: absolute;
      top: 30%;
      left: 9%;
    }
  }

  .career-task-avatar {
    @if $isWsm {
      background: url($path-figures + 'Anne-Collins-sm.avif') center no-repeat;
      width: 20.813rem;
    }

    @if $isSsm {
      @include background(null);
      width: 23.188rem;
    }

    height: 25rem;
    position: absolute;
    bottom: 0;
    left: 5.063rem;
    background-size: contain;
  }

  .quest-arrow {
    right: 14.438rem;

    &-mobile {
      right: 25rem;
    }
  }

  main,
  .quest-arrow {
    width: 45.625rem;
    height: 100%;
    position: absolute;
    flex-direction: column;

    aside {
      min-height: 4rem;
      margin: 1rem 0;
    }

    .career-task-progressbar {
      position: relative;
      display: flex;
      align-items: center;

      &__icon {
        position: absolute;
        right: -4.5rem;
      }
    }

    .icon-arrow {
      width: 5rem;
      height: 5rem;
      margin: 0 1rem;
      position: absolute;
      right: 0;
    }

    .career-task-progress {
      width: 28rem;
      margin: 1.688rem;
    }
  }

  .locked-opacity {
    opacity: 0.6;
  }

  .quest-text-icon {
    margin-top: 0;
    margin-left: 0.2rem;
    margin-right: 0.2rem;
    margin-bottom: -0.7rem;
  }
}
</style>
