import type { ImageLoadData } from '@/map-phaser-new/interfaces'

export const mainMapImageLoadData: () => ImageLoadData[] = () => [
  {
    name: 'map',
    compressed: true,
    data: {
      astc: {
        type: 'KTX',
        textureURL: 'map/compressed/mainMap-astc12x12.ktx',
      },
      etc: {
        type: 'KTX',
        textureURL: 'map/compressed/mainMap-etc2RGBA.ktx',
      },
      img: {
        textureURL: 'map/mainMap.avif?v2',
      },
      place: 'map/mainMap.avif?v2',
    },
  },
  {
    name: 'main-overlay-cars',
    compressed: true,
    data: {
      astc: {
        type: 'KTX',
        textureURL: 'map/compressed/main-overlay-cars-astc12x12.ktx',
      },
      etc: {
        type: 'KTX',
        textureURL: 'map/compressed/main-overlay-cars-etc2RGBA.ktx',
      },
      img: {
        textureURL: 'map/main-overlay-cars.avif?v8',
      },
      place: 'map/main-overlay-cars.avif?v8',
    },
  },
  {
    name: 'main-overlay-ships',
    compressed: true,
    data: {
      astc: {
        type: 'KTX',
        textureURL: 'map/compressed/main-overlay-ships-astc12x12.ktx',
      },
      etc: {
        type: 'KTX',
        textureURL: 'map/compressed/main-overlay-ships-etc2RGBA.ktx',
      },
      img: {
        textureURL: 'map/main-overlay-ships.avif?v2',
      },
      place: 'map/main-overlay-ships.avif?v2',
    },
  },
  {
    name: 'building_new_label',
    compressed: false,
    data: {
      place: 'map/building_new_label.avif',
    },
  },
  {
    name: 'building_championship_bg',
    compressed: false,
    data: {
      place: 'map/building_championship_bg.avif',
    },
  },
  {
    name: 'lock',
    compressed: false,
    data: {
      place: 'icons/icon-lock-lg.avif?v2',
    },
  },
  {
    name: 'exclamationMarkNotification',
    compressed: false,
    data: {
      place: 'map/info_bubble.avif',
    },
  },
  {
    name: 'bubbleNotification',
    compressed: false,
    data: {
      place: 'map/bubble_notification.avif',
    },
  },
  {
    name: 'careerArrow',
    compressed: false,
    data: {
      place: 'map/career-map-arrow-yellow.avif',
    },
  },
  {
    name: 'link_account_image',
    compressed: false,
    data: {
      place: 'map/link-account-image-v1.avif',
    },
  },
  {
    name: 'link_account_image_outline',
    compressed: false,
    data: {
      place: 'map/link-account-image-outline-v1.avif',
    },
  },
  {
    name: 'board_games_event_osra_image',
    compressed: false,
    data: {
      place: 'map/board-games-event-osra.avif',
    },
  },
  {
    name: 'board_games_event_osra_image_outline',
    compressed: false,
    data: {
      place: 'map/board-games-event-osra-outline.avif',
    },
  },
  {
    name: 'piggy_bank_osra_image',
    compressed: false,
    data: {
      place: 'map/piggy-bank-osra.avif',
    },
  },
  {
    name: 'piggy_bank_osra_image_outline',
    compressed: false,
    data: {
      place: 'map/piggy-bank-osra-outline.avif',
    },
  },
  {
    name: 'building_tooltip_top',
    compressed: false,
    data: {
      place: 'map/building_tooltip_top.avif',
    },
  },
  {
    name: 'building_tooltip_left',
    compressed: false,
    data: {
      place: 'map/building_tooltip_left.avif',
    },
  },
  {
    name: 'building_tooltip_right',
    compressed: false,
    data: {
      place: 'map/building_tooltip_right.avif',
    },
  },
]
