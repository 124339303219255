export const CLUB_CHAMPIONSHIP = 'club_championship'
export const PREMIUM = 'premium'
export const CAREER = 'career'
export const CLUB = 'club'
export const TRAINING_HALL = 'training_hall'
export const SHOP = 'shop'
export const LABORATORY = 'laboratory'
export const CLUBARENA = 'clubArena'
export const ARENA = 'arena'
export const EVENT_OSRA = 'event_osra'
export const EVENT_SHIP = 'event_ship'
